import GeographicExposureDistribution from 'components/advisor/proposal/securities/geographic-exposure-distribution';
import { GEOGRAPHIC_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/geographic-exposure/utils';
import EditBox from 'components/advisor/utils/edit-box';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { IPSPropTypes } from '../types';

const IPSGeographicExposure = ({ ipsCharts, ips: { proposal }, ...rest }) => {
  const { ipsProvider } = useContext(AdvisorContext);

  const breakdownCustomSecurities = rest.customActions?.breakdownCustomSecurities?.value ?? true;
  const distributionProps = { target: proposal.target, targetName: proposal.target_label };
  const hasRecommended = !!proposal.recommended;

  if (hasRecommended) {
    distributionProps.recommendedName = proposal.recommended_label;
    distributionProps.recommended = proposal.recommended;
  }

  const onChartReady = useCallback(
    (id, data) => {
      ipsProvider.saveChartImage({ [id]: data });
    },
    [ipsCharts[GEOGRAPHIC_EXPOSURE_CHART_ID]]
  );

  const setCollapsedRegions = data => {
    ipsProvider.setCollapsedRegions(data);
  };

  return (
    <EditBox {...rest}>
      <GeographicExposureDistribution
        breakdownCustomSecurities={breakdownCustomSecurities}
        onChartReady={onChartReady}
        setCollapsedRegions={setCollapsedRegions}
        {...distributionProps}
      />
    </EditBox>
  );
};

IPSGeographicExposure.propTypes = {
  ipsCharts: PropTypes.object.isRequired,
  ips: PropTypes.shape(IPSPropTypes).isRequired
};

export default IPSGeographicExposure;
