import { FormGroup } from 'components/form';
import { SECURITY_TARGET_TYPE } from 'containers/security/constants';
import PropTypes from 'prop-types';
import React from 'react';

const mapNonePositionToOther = (position, classification, type, valueSecurity) => {
  if (type === SECURITY_TARGET_TYPE) return valueSecurity.value === 0 ? 99 : valueSecurity.value;
  return position[classification].value === 0 ? 99 : position[classification].value;
};

const SelectClassification = ({
  classifications,
  classificationType,
  disabled,
  position,
  type,
  valueSecurity
}) => {
  const subtypeDisabled =
    type === SECURITY_TARGET_TYPE
      ? classificationType === 'subtype' && !(position.type || position.type === 0)
      : classificationType === 'subtype' && !(position.type.value || position.type.value === 0);

  const sectorDisabled =
    type === SECURITY_TARGET_TYPE
      ? classificationType === 'sector' &&
        !((position.type || position.type === 0) && (position.subtype || position.subtype === 0))
      : classificationType === 'sector' &&
        !(
          (position.type.value || position.type.value === 0) &&
          (position.subtype.value || position.subtype.value === 0)
        );

  const isDisabled = disabled || subtypeDisabled || sectorDisabled;

  const onChangeHandler = event => {
    if (type === SECURITY_TARGET_TYPE) valueSecurity.onChange(event.target.value);
    else {
      position[classificationType].onChange(event.target.value);
      if (classificationType === 'type') position.subtype.onChange('');
      if (['type', 'subtype'].includes(classificationType)) position.sector.onChange('');
    }
  };

  return (
    <FormGroup {...position[classificationType]}>
      <div className="c-select-wrap">
        <select
          className="c-select form-control"
          disabled={isDisabled}
          style={{ minWidth: 121 }}
          onChange={onChangeHandler}
          name={position[classificationType].name}
          onBlur={position[classificationType].onBlur}
          onDragStart={position[classificationType].onDragStart}
          onDrop={position[classificationType].onDrop}
          onFocus={position[classificationType].onFocus}
          value={mapNonePositionToOther(position, classificationType, type, valueSecurity)}
        >
          <option value="" disabled>
            Select
          </option>
          {classifications[classificationType]
            .filter(t => t.value !== 0)
            .map(item => (
              <option value={item.value} key={item.value}>
                {item.name === 'None' ? 'Other' : item.name}
              </option>
            ))}
        </select>
      </div>
      {position[classificationType].touched && position[classificationType].error && (
        <span className="text-danger error">{position[classificationType].error}</span>
      )}
    </FormGroup>
  );
};

SelectClassification.propTypes = {
  classifications: PropTypes.object.isRequired,
  classificationType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  position: PropTypes.object.isRequired,
  type: PropTypes.string,
  valueSecurity: PropTypes.object
};

SelectClassification.defaultProps = {
  disabled: false,
  type: '',
  valueSecurity: {}
};

export default React.memo(SelectClassification);
