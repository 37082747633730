/* eslint-disable no-underscore-dangle */
import CollapsibleTable from 'components/collapsible-table';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getPositionsTaxonomy } from 'utils/utils';
import { processTaxonomy } from 'components/advisor/risk-analysis/risk-analysis-target/positions-analysis/utils';
import './styles.scss';

const PositionsAnalysisTable = ({
  className,
  collapsibleProps,
  expandAll,
  expandRegions,
  headingLabels,
  hiddenVal,
  positions,
  showHeadingRow,
  taxonomies
}) => {
  const portfolio = { positions };

  const portfolioTaxonomy = getPositionsTaxonomy(portfolio, taxonomies, false, false);
  processTaxonomy(portfolioTaxonomy);

  return (
    <div className={`positions-analysis ${className}`}>
      <div className="portfolio-breakdown">
        <div className="collapsible-container">
          <CollapsibleTable
            bottomNameLabel="Total portfolio value"
            data={portfolioTaxonomy}
            expandRegions={expandRegions}
            expandAll={expandAll}
            hiddenVal={hiddenVal}
            hideLeaves
            showBottomSection={false}
            showHeadingRow={showHeadingRow}
            expandAssetClassification={false}
            expandAssetDetails={false}
            headingLabels={headingLabels || { name: '', value: 'Value', percent: '% of Portfolio' }}
            showPercent
            {...collapsibleProps}
          />
        </div>
      </div>
    </div>
  );
};

PositionsAnalysisTable.propTypes = {
  className: PropTypes.string,
  collapsibleProps: PropTypes.object,
  expandAll: PropTypes.bool,
  expandRegions: PropTypes.bool,
  headingLabels: PropTypes.object,
  hiddenVal: PropTypes.bool,
  positions: PropTypes.object.isRequired,
  showHeadingRow: PropTypes.bool,
  taxonomies: PropTypes.array
};

PositionsAnalysisTable.defaultProps = {
  className: '',
  collapsibleProps: {},
  expandAll: false,
  expandRegions: false,
  headingLabels: null,
  hiddenVal: true,
  showHeadingRow: false,
  taxonomies: []
};

PositionsAnalysisTable.contextTypes = {
  user: PropTypes.object.isRequired
};

export default connect(state => ({
  taxonomies: state.market.taxonomies
}))(PositionsAnalysisTable);
