import {
  ALLOCATIONS_TYPE,
  GEOGRAPHIC_EXPOSURE_TYPE,
  INVESTMENT_STYLE_TYPE,
  SECTOR_EXPOSURE_TYPE,
  TOP_HOLDINGS_TYPE
} from './constants';

export const DEFAULT_BREAKDOWN_CUSTOM_SECURITIES_STATE = [
  ALLOCATIONS_TYPE,
  GEOGRAPHIC_EXPOSURE_TYPE,
  INVESTMENT_STYLE_TYPE,
  SECTOR_EXPOSURE_TYPE,
  TOP_HOLDINGS_TYPE
];

/**
 * Allows to define the array that will contain the sections that will show
 * the breakdown of the custom securities per each entity ID.
 * @param {number} id entity ID.
 * @param {object} breakdownCustomSecurities previous `breakdownCustomSecurities` state.
 * @returns new `breakdownCustomSecurities` state.
 */
export const setBreakdownCustomSecuritiesState = (id, breakdownCustomSecurities) => {
  if (breakdownCustomSecurities[id]) return breakdownCustomSecurities;
  return { ...breakdownCustomSecurities, [id]: [...DEFAULT_BREAKDOWN_CUSTOM_SECURITIES_STATE] };
};

/**
 * Allows to determine if the portfolio has custom securities or not.
 * @param {object} portfolio the target portfolio.
 * @returns whether or not there is at least one custom security.
 */
export const hasCustomSecurities = portfolio =>
  portfolio && portfolio?.positions && portfolio.positions.some(position => position.is_custom);
