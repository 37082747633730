import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import { AdvisorPropTypes } from 'app/types';
import PropTypes from 'prop-types';
import React from 'react';
import CustomSection from 'reports/base/sections/custom';
import { PORTRAIT_ORIENTATION, SIZE_A4 } from '../../constants';
import StratifiBrand from '../brand/stratifi';
import { DEFAULT_PRIMARY_COLOR, getImageForReport } from '../utils';
import styles from './styles';

const BasePage = ({
  advisor,
  children,
  footer,
  initialPageNumber,
  orientation,
  size,
  withCustomBranding,
  withPageNumber
}) => {
  const brandPrimaryColor = withCustomBranding
    ? advisor.brand_primary_color || advisor.company?.brand_primary_color || DEFAULT_PRIMARY_COLOR
    : DEFAULT_PRIMARY_COLOR;
  const logo = advisor.logo || advisor.company?.logo;

  return (
    <Page size={size} orientation={orientation} style={styles.page}>
      <View style={[styles.headerLine, { borderTopColor: brandPrimaryColor }]} fixed />
      {children}
      <View style={styles.footer} fixed>
        {!logo || !withCustomBranding ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link src="https://www.stratifi.com/">
            <StratifiBrand width={120} style={styles.footerImage} />
          </Link>
        ) : (
          <Image style={styles.footerImage} src={getImageForReport(logo)} />
        )}
        <CustomSection
          body={footer?.body}
          sectionStyles={[styles.footerLine, { borderTopColor: brandPrimaryColor }]}
          bodyStyles={styles.footerBody}
        />
        {withPageNumber && (
          <Text
            style={styles.pageNumber}
            render={({ pageNumber }) => initialPageNumber + pageNumber}
          />
        )}
      </View>
    </Page>
  );
};

BasePage.propTypes = {
  advisor: PropTypes.shape(AdvisorPropTypes).isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  footer: PropTypes.shape({
    body: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired
  }),
  initialPageNumber: PropTypes.number,
  orientation: PropTypes.string,
  size: PropTypes.string,
  withCustomBranding: PropTypes.bool,
  withPageNumber: PropTypes.bool
};

BasePage.defaultProps = {
  footer: null,
  initialPageNumber: 0,
  orientation: PORTRAIT_ORIENTATION,
  size: SIZE_A4,
  withCustomBranding: false,
  withPageNumber: true
};

export default BasePage;
