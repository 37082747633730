/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';

export const DefaultToast = ({ account, creating }) => (
  <div className="notification-toast">
    Account <span className="font-weight-bolder">{account.display_name}</span> was{' '}
    {creating ? 'created' : 'edited'} successfully.
  </div>
);

DefaultToast.propTypes = {
  account: PropTypes.object.isRequired,
  creating: PropTypes.bool.isRequired
};

export const ScanIqToast = ({ account }) => (
  <div className="notification-toast">
    <p className="mb-0">
      The account <span className="font-weight-bolder">{account.display_name}</span> has been
      created.
    </p>
    <Link
      className="btn btn-primary btn-small"
      to={`/advisor/${account.investor.is_prospect ? 'prospects' : 'investors'}/${
        account.investor.id
      }/account/${account.id}`}
    >
      Open Account
    </Link>
  </div>
);

ScanIqToast.propTypes = {
  account: PropTypes.object.isRequired
};
