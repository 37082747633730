import {
  CONCENTRATED_FACTOR_CHART_ID,
  CORRELATION_FACTOR_CHART_ID,
  TAIL_FACTOR_CHART_ID,
  VOLATILITY_FACTOR_CHART_ID
} from 'components/advisor/risk-analysis/risk-analysis-target-score/constants';
import { PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID } from 'components/advisor/risk-analysis/risk-analysis-target/positions-analysis/constants';
import { SECURITY_TYPE_CONCENTRATION_CHART_ID } from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration/constants';
import {
  SECURITY_REGIONS,
  SECURITY_SECTORS,
  hasSecurityDetails
} from 'components/advisor/risk-analysis/securities/common/utils';
import { GEOGRAPHIC_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/geographic-exposure/utils';
import { SECTOR_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/sector-exposure/utils';
import {
  REGION_EXPOSURE,
  SECTOR_EXPOSURE,
  sectionIsLocked
} from 'components/advisor/section-locked/utils';
import {
  GEOGRAPHIC_EXPOSURE_TYPE,
  SECTOR_EXPOSURE_TYPE
} from 'components/form/breakdown-custom-securities-toggle-title/constants';
import _ from 'lodash';

const ACCOUNT_REPORT_CHARTS = [
  CONCENTRATED_FACTOR_CHART_ID,
  CORRELATION_FACTOR_CHART_ID,
  TAIL_FACTOR_CHART_ID,
  VOLATILITY_FACTOR_CHART_ID,
  PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID,
  SECURITY_TYPE_CONCENTRATION_CHART_ID
];

const INVESTOR_REPORT_CHARTS = [
  PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID,
  SECURITY_TYPE_CONCENTRATION_CHART_ID,
  SECTOR_EXPOSURE_CHART_ID,
  GEOGRAPHIC_EXPOSURE_CHART_ID
];

export const getFullyLoadedCharts = (
  portfolio,
  accounts,
  accountSavedCharts,
  investorSavedCharts,
  user,
  portfolioBreakdownCustomSecurities = []
) => {
  const byAccountWithoutPrismScore = account => chart =>
    account.prism_score_summary ||
    ![
      CONCENTRATED_FACTOR_CHART_ID,
      CORRELATION_FACTOR_CHART_ID,
      TAIL_FACTOR_CHART_ID,
      VOLATILITY_FACTOR_CHART_ID
    ].includes(chart);

  const byAccountWithoutPositions = account => chart =>
    !_.isEmpty(account.positions) ||
    ![PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID, SECURITY_TYPE_CONCENTRATION_CHART_ID].includes(chart);

  const accountCharts = {};
  accounts.forEach(account => {
    const charts = [...ACCOUNT_REPORT_CHARTS]
      .filter(byAccountWithoutPrismScore(account))
      .filter(byAccountWithoutPositions(account));
    accountCharts[account.id] = charts.map(chart => `${chart}-${account.id}`);
  });

  const fullyLoadedAccountCharts = Object.keys(accountCharts).every(accountId =>
    accountCharts[accountId].every(chart =>
      Object.prototype.hasOwnProperty.call(accountSavedCharts, chart)
    )
  );
  const fullyLoadedInvestorCharts = INVESTOR_REPORT_CHARTS.filter(chart => {
    // check if the `SECTOR_EXPOSURE_CHART_ID` chart should be included in the list of charts
    // according to whether the section is displayed or not.
    if (chart !== SECTOR_EXPOSURE_CHART_ID) return true;
    if (sectionIsLocked(SECTOR_EXPOSURE, user)) return false;
    return hasSecurityDetails(
      portfolio,
      SECURITY_SECTORS,
      portfolioBreakdownCustomSecurities.includes(SECTOR_EXPOSURE_TYPE)
    );
  })
    .filter(chart => {
      // check if the `GEOGRAPHIC_EXPOSURE_CHART_ID` chart should be included in the list of charts
      // according to whether the section is displayed or not.
      if (chart !== GEOGRAPHIC_EXPOSURE_CHART_ID) return true;
      if (sectionIsLocked(REGION_EXPOSURE, user)) return false;
      return hasSecurityDetails(
        portfolio,
        SECURITY_REGIONS,
        portfolioBreakdownCustomSecurities.includes(GEOGRAPHIC_EXPOSURE_TYPE)
      );
    })
    .every(chart => Object.prototype.hasOwnProperty.call(investorSavedCharts, chart));

  return { fullyLoadedAccountCharts, fullyLoadedInvestorCharts };
};

export const getAccountCharts = (accountId, accountSavedCharts) =>
  ACCOUNT_REPORT_CHARTS.reduce(
    (acc, chartId) => ({
      ...acc,
      [`${chartId}-${accountId}`]: accountSavedCharts[`${chartId}-${accountId}`]
    }),
    {}
  );
