import LoadingPrismDataMessage from 'components/advisor/dashboard/loading-prism-data-message';
import { MODEL_TARGET_TYPE } from 'components/advisor/risk-analysis/constants';
import RiskAnalysisPositions from 'components/advisor/risk-analysis/risk-analysis-target/risk-analysis-positions';
import SecurityTypeConcentration from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration';
import { SECURITY_TYPE_CONCENTRATION_CHART_ID } from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration/constants';
import { DEFAULT_PORTFOLIO_TOTAL_ASSETS } from 'components/advisor/risk-analysis/securities/common/utils';
import GeographicExposure from 'components/advisor/risk-analysis/securities/geographic-exposure';
import InvestmentStyle from 'components/advisor/risk-analysis/securities/investment-style';
import SectorExposure from 'components/advisor/risk-analysis/securities/sector-exposure';
import TopHoldings from 'components/advisor/risk-analysis/securities/top-holdings';
import ScrollCardsIndex from 'components/advisor/scroll-cards-index';
import ScrollToTop from 'components/advisor/scroll-cards-index/scroll-to-top';
import Disclosure from 'components/disclosure';
import BreakdownCustomSecuritiesToggleTitle from 'components/form/breakdown-custom-securities-toggle-title';
import {
  ALLOCATIONS_TYPE,
  GEOGRAPHIC_EXPOSURE_TYPE,
  INVESTMENT_STYLE_TYPE,
  MODEL_ENTITY_TYPE,
  SECTOR_EXPOSURE_TYPE,
  TOP_HOLDINGS_TYPE
} from 'components/form/breakdown-custom-securities-toggle-title/constants';
import SpinnerLoader from 'components/performance-spinner';
import Link from 'components/utils/link';
import PrismRatingUpsideDownside from 'components/utils/prism-rating-upside-downside';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import './styles.scss';

const AdvisorModelsDetailsOverview = ({
  breakdownCustomSecurities,
  calculating,
  fetching,
  params,
  portfolio,
  riskAnalysis,
  showEditModal
}) => {
  const {
    user: {
      advisor: { company }
    }
  } = useContext(AdvisorContext);

  if (!portfolio || _.isEmpty(portfolio || fetching)) return <SpinnerLoader spinnerLoading />;

  // adds by default a value of 100 in case of a model portfolio
  portfolio.value = DEFAULT_PORTFOLIO_TOTAL_ASSETS;

  const score = portfolio?.prism_score_summary?.overall;
  const portfolioBreakdownCustomSecurities = breakdownCustomSecurities[portfolio.id];
  const prismOverall = score ? score.toFixed(1) : null;
  const isStrategy = portfolio?.is_strategy;

  return (
    <section className="risk-analysis">
      <div className="risk-score-result-container">
        {portfolio && !_.isEmpty(riskAnalysis) && (
          <div className="risk-analysis-body">
            <div className="risk-analysis-body-container">
              <ScrollCardsIndex isStrategy={isStrategy} score={prismOverall} />

              {(calculating || showEditModal) && (
                <div className="calculating-portfolio-risk">
                  <LoadingPrismDataMessage
                    message={`${calculating ? 'Calculating' : 'Preparing'} Portfolio Risk...`}
                    inset
                  />
                </div>
              )}

              {!calculating && !showEditModal && (
                <div className="result-container prism-container" id="portfolio-risk">
                  {portfolio.prism_score_summary && (
                    <div>
                      <div className="result-heading heading-model">
                        <div className="result-heading name-container">
                          Portfolio Risk <span className="sub-text">Powered By</span> PRISM Rating
                          &trade;
                        </div>
                        <span className="view-report">
                          <Link
                            to={`/advisor/models/${params.id}/risk-analysis`}
                            className="btn btn-outline-secondary btn-prism"
                          >
                            View Report
                          </Link>
                        </span>
                      </div>
                      <PrismRatingUpsideDownside
                        prismSummary={portfolio.prism_score_summary}
                        targetSummary={portfolio.target_score_summary}
                        value={portfolio.value}
                        market={{
                          up: company.market_upside_performance,
                          down: company.market_downside_performance
                        }}
                      />
                      <ScrollToTop />
                    </div>
                  )}
                </div>
              )}

              {portfolio && (
                <div className="result-container prism-container" id="allocation">
                  <div className="result-heading">
                    <BreakdownCustomSecuritiesToggleTitle
                      portfolio={portfolio}
                      portfolioType={MODEL_ENTITY_TYPE}
                      sectionType={ALLOCATIONS_TYPE}
                    />
                  </div>
                  <div className="risk-analysis-positions-container">
                    <RiskAnalysisPositions
                      breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
                        ALLOCATIONS_TYPE
                      )}
                      portfolio={portfolio}
                      type={MODEL_TARGET_TYPE}
                    />
                  </div>
                  <ScrollToTop />
                </div>
              )}

              {!portfolio && (
                <h3 className="text-sm-center placeholder-text">
                  The PRISM score is not ready for this portfolio.
                </h3>
              )}

              <div className="result-container prism-container">
                <div className="result-heading">
                  <BreakdownCustomSecuritiesToggleTitle
                    isNewFeature
                    portfolio={portfolio}
                    portfolioType={MODEL_ENTITY_TYPE}
                    sectionType={INVESTMENT_STYLE_TYPE}
                  />
                </div>
                <div className="investment-style-container">
                  <InvestmentStyle
                    breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
                      INVESTMENT_STYLE_TYPE
                    )}
                    portfolio={portfolio}
                    hiddenVal
                  />
                </div>
                <ScrollToTop />
              </div>

              <div className="result-container prism-container">
                <div className="result-heading" id="sector-exposure--header">
                  <BreakdownCustomSecuritiesToggleTitle
                    isNewFeature
                    portfolio={portfolio}
                    portfolioType={MODEL_ENTITY_TYPE}
                    sectionType={SECTOR_EXPOSURE_TYPE}
                  />
                </div>
                <div className="sector-exposure-container">
                  <SectorExposure
                    breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
                      SECTOR_EXPOSURE_TYPE
                    )}
                    portfolio={portfolio}
                    hiddenVal
                  />
                </div>
                <ScrollToTop />
              </div>

              <div className="result-container prism-container">
                <div className="result-heading">
                  <BreakdownCustomSecuritiesToggleTitle
                    isNewFeature
                    portfolio={portfolio}
                    portfolioType={MODEL_ENTITY_TYPE}
                    sectionType={TOP_HOLDINGS_TYPE}
                  />
                </div>
                <div className="sector-exposure-container">
                  <TopHoldings
                    breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
                      TOP_HOLDINGS_TYPE
                    )}
                    portfolio={portfolio}
                    hiddenVal
                  />
                </div>
                <ScrollToTop />
              </div>

              <div className="result-container prism-container">
                <div className="result-heading" id="geographic-exposure--header">
                  <BreakdownCustomSecuritiesToggleTitle
                    isNewFeature
                    portfolio={portfolio}
                    portfolioType={MODEL_ENTITY_TYPE}
                    sectionType={GEOGRAPHIC_EXPOSURE_TYPE}
                  />
                </div>
                <div className="geographic-exposure-container">
                  <GeographicExposure
                    breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
                      GEOGRAPHIC_EXPOSURE_TYPE
                    )}
                    portfolio={portfolio}
                    hiddenVal
                  />
                </div>
                <ScrollToTop />
              </div>

              {(calculating || showEditModal) && (
                <div className="calculating-portfolio-risk">
                  <LoadingPrismDataMessage
                    message={`${calculating ? 'Calculating' : 'Preparing'} Portfolio Risk...`}
                    inset
                  />
                </div>
              )}

              {!calculating && !showEditModal && !_.isEmpty(portfolio.positions) && (
                <div
                  className="result-container prism-container"
                  id="security-type-concentration-scroll"
                >
                  <div className="result-heading">Security Type Concentration</div>
                  <div className="security-type-concentration-container">
                    <SecurityTypeConcentration
                      id={`${SECURITY_TYPE_CONCENTRATION_CHART_ID}-${portfolio.id}`}
                      positions={portfolio.positions}
                      totalAssets={portfolio.value}
                    />
                  </div>
                  <ScrollToTop />
                </div>
              )}

              <Disclosure />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

AdvisorModelsDetailsOverview.defaultProps = {
  fetching: false,
  portfolio: {},
  riskAnalysis: {}
};

AdvisorModelsDetailsOverview.propTypes = {
  breakdownCustomSecurities: PropTypes.object.isRequired,
  calculating: PropTypes.bool.isRequired,
  fetching: PropTypes.bool,
  params: PropTypes.object.isRequired,
  portfolio: PropTypes.object,
  riskAnalysis: PropTypes.object,
  showEditModal: PropTypes.bool.isRequired
};
const AdvisorModelsDetailWithRouter = withRouter(AdvisorModelsDetailsOverview);

export default connect(state => ({
  breakdownCustomSecurities: state.models.breakdownCustomSecurities,
  portfolio: state.models.view,
  riskAnalysis: state.models.riskAnalysis
}))(AdvisorModelsDetailWithRouter);
