import cn from 'classnames';
import ModelEdit, { modes } from 'components/advisor/models/edit';
import LabelEdit from 'components/label-edit';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import QuestionMark from 'components/utils/question-mark';
import { CLICK_EDIT_MODEL_PORTFOLIO_BUTTON } from 'constants/actstream';
import { modelDetailTabs as tabs } from 'constants/tabs';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { modelsWithPrismScoresInProgressSelector } from 'selectors/models';
import {
  MODEL_PORTFOLIO_CREATION_ERROR_MESAGGE,
  MODEL_PORTFOLIO_CREATION_MESAGGE,
  STRATEGY_MESSAGE
} from '../constants';
import { accessControlLink, overviewLink, performanceLink, prismLink, strategyLink } from './links';
import './styles.scss';

const DEFAULT_TAB = tabs.OVERVIEW;
const FAILED_TO_FETCH = 'Failed to fetch';
const GETTING_PRISM_SCORE = 'Calculating PRISM Score...';
const REGULAR_COPY_REGEX = /\(copy\)/i;
const TOOLTIP_TEXT = 'Model name';

const AdvisorModelsDetail = ({
  children,
  company,
  location,
  marketStore,
  model,
  params,
  prismScoresInProgress
}) => {
  const { accountProvider, actionProvider, modelProvider, routerActions, user, authProvider } =
    useContext(AdvisorContext);

  const [calculating, setCalculating] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [modelName, setModelName] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [tab, setTab] = useState(DEFAULT_TAB);

  const editModalRef = useRef(null);
  const labelEditRef = useRef(null);

  const {
    securities: { positions }
  } = marketStore;

  const userIsComplianceOrAbove = authProvider.hasCompliancePermissionsOrAbove(user);
  const userIsOwner = model?.advisor === user?.advisor?.id;
  const allowAdvisorsSharingModels = company.allow_advisors_sharing_models;

  const isModelCopied = location?.query?.copy === 'true';
  const isStrategy = model?.is_strategy;
  const modelID = params?.id;
  const calculatingPrismScore = calculating || prismScoresInProgress.includes(Number(modelID));
  const reportBuilderEnabled = company.report_builder_enabled;
  const score = model?.prism_score_summary?.overall;
  const prismOverall = score ? score.toFixed(1) : null;

  const fetchModelPortfolio = () => {
    setFetching(true);
    modelProvider
      .get(modelID)
      .then(({ data: portfolio }) => {
        if (portfolio && !_.isEmpty(portfolio)) {
          const portfolioRisk = portfolio.prism_score?.output
            ? { ...portfolio.prism_score.output, portfolio }
            : { portfolio };
          modelProvider.riskAnalysisPrecomputed(portfolioRisk);
        }
      })
      .finally(() => {
        setFetching(false);
      });
  };

  const setCurrentTab = () => {
    const matchingTab = Object.values(tabs).find(value => location.pathname.includes(value));
    if (matchingTab && tab !== matchingTab) setTab(matchingTab);
  };

  useEffect(
    () => () => {
      modelProvider.clearRiskAnalysis();
      modelProvider.clearModel();
      accountProvider.clearAccount();
    },
    []
  );

  useEffect(() => setModelName(model?.name), [model?.name]);

  useEffect(() => {
    fetchModelPortfolio();
    setCurrentTab();
  }, [modelID]);

  useEffect(() => {
    setCurrentTab();
  }, [location.pathname]);

  useEffect(() => {
    if (
      isModelCopied &&
      labelEditRef.current &&
      REGULAR_COPY_REGEX.test(model?.name) &&
      !calculatingPrismScore
    )
      labelEditRef?.current?.edit();
  }, [isModelCopied, labelEditRef, model?.name, calculatingPrismScore]);

  const showEditModalHandler = () => {
    actionProvider.storeAction({ verb: CLICK_EDIT_MODEL_PORTFOLIO_BUTTON });
    setShowEditModal(true);
  };

  const hideEditModalHandler = () => {
    setShowEditModal(false);
    if (editModalRef.current) editModalRef.current.hide();
  };

  const onCalculateRiskHandler = (id, name) => {
    const creating = !model?.prism_score_summary;
    const notificationOptions = { toastId: id };

    setCalculating(true);
    hideEditModalHandler();

    toast.info(`${creating ? 'Calculating' : 'Updating'} PRISM score.`, notificationOptions);

    return modelProvider
      .updatePrism(id)
      .then(response => {
        if (response.message === FAILED_TO_FETCH) throw new Error(response.message);
        toast.success(
          `PRISM score successfully ${creating ? 'calculated' : 'updated'}.`,
          notificationOptions
        );
        fetchModelPortfolio();
      })
      .catch(() => {
        toast.error(
          "We couldn't calculate the PRISM rating, please try it later or contact support for more information.",
          notificationOptions
        );
      })
      .finally(() => {
        setCalculating(false);
      });
  };

  const onChangeModelNameHandler = e => {
    setModelName(e?.target?.value);
  };

  const onSaveModelNameHandler = async () => {
    if (modelName) await modelProvider.updateModelName(model, { name: modelName });
  };

  const onCreateCopyHandler = async () =>
    modelProvider
      .copy(model.id)
      .then(({ data }) => {
        toast.info(MODEL_PORTFOLIO_CREATION_MESAGGE(model.name), { autoClose: 2000 });
        routerActions.push(`/advisor/models/${data.id}/overview?copy=true`);
      })
      .catch(() => {
        toast.info(MODEL_PORTFOLIO_CREATION_ERROR_MESAGGE, { autoClose: 4000 });
      });

  const isSubscribed = model?.subscription_status === 'accepted';
  const canEditModel = !isSubscribed && (userIsOwner || userIsComplianceOrAbove) && model?.name;
  const isViewOnly = !canEditModel;

  return (
    <div className="model-details-view">
      <div className="model-details-view__holder">
        <div className="model-details-view__model-name">
          <LabelEdit
            ref={labelEditRef}
            label={model?.name}
            inputValue={modelName}
            disable={calculatingPrismScore}
            onSave={onSaveModelNameHandler}
            inputOnChange={onChangeModelNameHandler}
            toolTipText={calculatingPrismScore ? GETTING_PRISM_SCORE : TOOLTIP_TEXT}
          />
          <div className="model-details-view__buttons">
            <button
              type="button"
              style={{ marginRight: 8 }}
              onClick={showEditModalHandler}
              className="btn btn-secondary"
            >
              {isViewOnly ? 'View Positions' : 'Edit'}
            </button>
            {model?.name && (
              <button
                type="button"
                style={{ marginRight: 8 }}
                onClick={onCreateCopyHandler}
                className="btn btn-outline-secondary font-weight-normal"
              >
                Make a copy
              </button>
            )}
          </div>
        </div>

        {isStrategy && (
          <div className="model-details-view__strategy-info">
            <span className="model-details-view__strategy-info-label">Strategy </span>
            <QuestionMark tooltipMessage={STRATEGY_MESSAGE} />
          </div>
        )}

        <ul className="model-details-view__tabs tabs">
          <li className={cn({ active: tab === tabs.OVERVIEW })}>{overviewLink({ modelID })}</li>
          <li className={cn({ active: tab === tabs.PRISM })}>
            {prismLink({ isStrategy, prismOverall, modelID })}
          </li>
          <li className={cn({ active: tab === tabs.PERFORMANCE })}>
            {performanceLink({ reportBuilderEnabled, modelID })}
          </li>
          <li className={cn({ active: tab === tabs.STRATEGY })}>{strategyLink({ modelID })}</li>
          {((userIsOwner && allowAdvisorsSharingModels) || userIsComplianceOrAbove) && (
            <li className={cn({ active: tab === tabs.ACCESS_CONTROL })}>
              {accessControlLink({ modelID })}
            </li>
          )}
        </ul>

        {/* `React.cloneElement` is used to clone the children and inject required props */}
        {React.cloneElement(children, {
          calculating: calculatingPrismScore,
          fetching,
          showEditModal
        })}
      </div>

      <Modal
        className="modal-lg"
        id="modelEditModal"
        onHidden={hideEditModalHandler}
        ref={editModalRef}
        show={showEditModal}
      >
        <ModalHeader />
        <ModalBody>
          <ModelEdit
            calculateRisk={onCalculateRiskHandler}
            forceCalculateRiskOnUpdate
            hideEditModal={hideEditModalHandler}
            isViewOnly={isViewOnly}
            marketStore={marketStore}
            mode={modes.UPDATE}
            model={model}
            positions={positions}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

AdvisorModelsDetail.propTypes = {
  children: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  marketStore: PropTypes.object.isRequired,
  model: PropTypes.object,
  params: PropTypes.object.isRequired,
  prismScoresInProgress: PropTypes.array.isRequired
};

AdvisorModelsDetail.defaultProps = {
  model: null
};

AdvisorModelsDetail.contextTypes = {
  accountProvider: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired
};

const AdvisorModelsDetailWithRouter = withRouter(AdvisorModelsDetail);

export default connect(state => ({
  company: state.auth.user.advisor.company,
  marketStore: state.market,
  model: state.models.view,
  prismScoresInProgress: modelsWithPrismScoresInProgressSelector(state)
}))(AdvisorModelsDetailWithRouter);
