import { setBreakdownCustomSecuritiesState } from 'components/form/breakdown-custom-securities-toggle-title/utils';
import {
  ACCOUNT_CLEAR,
  ACCOUNT_CLEAR_ES,
  ACCOUNT_COUNT_EXCLUDED,
  ACCOUNT_CREATE,
  ACCOUNT_DELETE,
  ACCOUNT_EDIT,
  ACCOUNT_GET,
  ACCOUNT_GET_DRIFT_LEVELS_DISTRIBUTION,
  ACCOUNT_LIST,
  ACCOUNT_LIST_ES,
  ACCOUNT_NOTE_LIST,
  ACCOUNT_PRISM_SCORE_COMPLETED,
  ACCOUNT_PRISM_SCORE_FAILED,
  ACCOUNT_PRISM_SCORE_IN_PROGRESS,
  ACCOUNT_SAVE_CHART_IMAGE,
  ACCOUNT_SET_BREAKDOWN_CUSTOM_SECURITIES,
  ACCOUNT_UPDATE,
  ACCOUNT_UPDATE_SELECTED_IDS,
  CHANGE_CLASS_NAME,
  DROP_NO_PERFORMANCE,
  GET_ACCOUNTS_BY_RISK_LEVEL,
  GET_ACCOUNT_PRISM_DISTRIBUTION,
  GET_ACCOUNT_TARGET_RATING,
  GET_PRISM_TARGET_HISTORY,
  SET_NO_PERFORMANCE
} from 'constants/actions';
import config from '../config';

const {
  prism: { levels }
} = config;

const initialState = {
  breakdownCustomSecurities: {},
  byRiskLevel: { [levels.LOW]: [], [levels.NEUTRAL]: [], [levels.HIGH]: [] },
  byRiskLevelParams: {},
  charts: {},
  esList: [],
  notes: {},
  prismScoresInProgress: [],
  targetRating: null,
  totalExcludedAccounts: 0
};

// eslint-disable-next-line default-param-last
export default function accounts(state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_LIST:
      return { ...state, list: action.data.data, listMeta: action.data.meta };

    case ACCOUNT_LIST_ES:
      return { ...state, esList: action.data };

    case ACCOUNT_GET:
      return {
        ...state,
        breakdownCustomSecurities: setBreakdownCustomSecuritiesState(
          action.data.id,
          state.breakdownCustomSecurities
        ),
        view: action.data
      };

    case ACCOUNT_CREATE:
      return { ...state, view: action.data };

    case ACCOUNT_UPDATE:
      return { ...state, view: state.view ? action.data : null };

    case ACCOUNT_DELETE:
      // not implemented
      return state;

    case ACCOUNT_EDIT:
      return { ...state, edit: action.data };

    case ACCOUNT_CLEAR:
      return { ...state, view: null };

    case ACCOUNT_CLEAR_ES:
      return { ...state, esList: [] };

    case ACCOUNT_COUNT_EXCLUDED:
      return { ...state, totalExcludedAccounts: action.data };

    case ACCOUNT_PRISM_SCORE_IN_PROGRESS:
      return { ...state, prismScoresInProgress: [...state.prismScoresInProgress, action.id] };

    case ACCOUNT_PRISM_SCORE_COMPLETED:
    case ACCOUNT_PRISM_SCORE_FAILED:
      return {
        ...state,
        prismScoresInProgress: state.prismScoresInProgress.filter(id => id !== action.id)
      };

    case SET_NO_PERFORMANCE:
      return { ...state, noPerfFlag: true };

    case DROP_NO_PERFORMANCE:
      return { ...state, noPerfFlag: false };

    case GET_ACCOUNT_PRISM_DISTRIBUTION:
      return { ...state, prismDistribution: action.data };

    case GET_ACCOUNT_TARGET_RATING:
      return { ...state, targetRating: action.data };

    case GET_PRISM_TARGET_HISTORY:
      return { ...state, historical: { ...state.historical, [action.id]: action.data } };

    case GET_ACCOUNTS_BY_RISK_LEVEL:
      return { ...state, byRiskLevel: { ...action.data }, byRiskLevelParams: { ...action.params } };

    case ACCOUNT_UPDATE_SELECTED_IDS:
      return { ...state, selectedAccounts: action.data.selected_ids };

    case CHANGE_CLASS_NAME:
      return { ...state, buttonClass: action.data.buttonClass };

    case ACCOUNT_GET_DRIFT_LEVELS_DISTRIBUTION:
      return { ...state, driftLevelDistribution: action.data };

    case ACCOUNT_NOTE_LIST:
      return { ...state, notes: { ...state.notes, [action.target]: action.data } };

    case ACCOUNT_SAVE_CHART_IMAGE:
      return { ...state, charts: { ...state.charts, ...action.data } };

    case ACCOUNT_SET_BREAKDOWN_CUSTOM_SECURITIES:
      return {
        ...state,
        breakdownCustomSecurities: { ...state.breakdownCustomSecurities, [action.id]: action.data }
      };

    default:
      return state || null;
  }
}
