/* eslint-disable import/prefer-default-export */
import { MODEL_TARGET_TYPE } from '../constants';

export const accountOrModelMergeProps = (stateProps, dispatchProps, ownProps) => {
  const { portfolio, type } = ownProps;
  const { accountsBreakdownCustomSecurities, modelsBreakdownCustomSecurities } = stateProps;

  const getBreakdownCustomSecurities = entityType => {
    if (entityType === MODEL_TARGET_TYPE) return modelsBreakdownCustomSecurities;
    return accountsBreakdownCustomSecurities;
  };
  const breakdownCustomSecurities = getBreakdownCustomSecurities(type);
  const portfolioBreakdownCustomSecurities = breakdownCustomSecurities[portfolio.id] || [];

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    portfolioBreakdownCustomSecurities
  };
};
