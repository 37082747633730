import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';
import ExtraDataSection from 'reports/base/sections/extra';
import { formatMoney, formatPercentage } from 'utils/utils';
import { MetadataPropTypes, ProposalPropTypes } from '../../types';
import getTableThresholdValues from '../../utils';
import { getProcessedPositions } from '../utils';
import styles from './styles';

const renderPositions = (id, positions, threshold) =>
  positions.length ? (
    <>
      <View key={`${id}-header`} style={[styles.row, styles.rowPosition]}>
        <View style={[styles.cell, styles.cellPosition]}>
          <Text>Asset</Text>
        </View>
        <View style={[styles.cell, styles.cellPosition]}>
          <Text style={{ textAlign: 'center' }}>Weight</Text>
        </View>
        <View style={[styles.cell, styles.cellPosition]}>
          <Text style={{ textAlign: 'right' }}>Expense (%)</Text>
        </View>
        <View style={[styles.cell, styles.cellPosition, { marginRight: 0 }]}>
          <Text style={{ textAlign: 'right' }}>Expense ($)</Text>
        </View>
      </View>
      {positions.map((position, idx) => (
        <View
          key={`${id}-${position.ticker}`}
          style={[styles.row, { paddingTop: 3 }]}
          wrap={false}
          break={threshold && idx === positions.length - threshold}
        >
          <View style={[styles.cell, styles.cellPosition]}>
            <Text>{position.ticker_name || position.ticker}</Text>
          </View>
          <View style={[styles.cell, styles.cellPosition]}>
            <Text style={{ textAlign: 'center' }}>{formatPercentage(position.weight)}</Text>
          </View>
          <View style={[styles.cell, styles.cellPosition]}>
            <Text style={{ textAlign: 'right' }}>
              {formatPercentage(position.expense_ratio, 1)}
            </Text>
          </View>
          <View style={[styles.cell, styles.cellPosition, { marginRight: 0 }]}>
            <Text style={{ textAlign: 'right' }}>{formatMoney(position.expense_value)}</Text>
          </View>
        </View>
      ))}
    </>
  ) : (
    <Text>The positions don&apos;t have fees or the fees are not available.</Text>
  );

const ProposalCostAnalysis = ({ breakSection, metadata, orientation, proposal }) => {
  const { body, title } = metadata;

  const expandAssets = metadata.customActions?.expandAssets?.value ?? true;
  const breakdownCustomSecurities =
    metadata.customActions?.breakdownCustomSecurities?.value ?? true;

  const hasRecommended = !!proposal.recommended;
  const targetPositions = getProcessedPositions(proposal, breakdownCustomSecurities, 'target');
  const recommendedPositions = getProcessedPositions(
    proposal,
    breakdownCustomSecurities,
    'recommended'
  );

  const { targetThresholdValue, recommendedThresholdValue } = getTableThresholdValues(
    body,
    orientation,
    targetPositions,
    recommendedPositions
  );

  const { ratio: targetRatio, value: targetValue } = targetPositions.reduce(
    ({ ratio, value }, position) => ({
      ratio: ratio + position.weighted_expense_ratio,
      value: value + position.expense_value
    }),
    { ratio: 0, value: 0 }
  );

  const { ratio: recommendedRatio, value: recommendedValue } = recommendedPositions.reduce(
    ({ ratio, value }, position) => ({
      ratio: ratio + position.weighted_expense_ratio,
      value: value + position.expense_value
    }),
    { ratio: 0, value: 0 }
  );

  return (
    <BaseSection
      body={body}
      breakSection={breakSection}
      style={{ marginBottom: 0 }}
      title={title}
      wrap
    >
      <ExtraDataSection proposal={proposal} />
      <View style={styles.table}>
        <View style={styles.row}>
          <View style={[styles.cell, styles.targetCell]}>
            <Text style={styles.headerText}>{proposal.target_label || 'Current Portfolio'}</Text>
          </View>
          {hasRecommended && (
            <View style={[styles.cell, styles.recommendedCell, { marginRight: 0 }]}>
              <Text style={styles.headerText}>{proposal.recommended_label}</Text>
            </View>
          )}
        </View>

        <View style={styles.row}>
          <View style={[styles.cell, styles.cellSummary]}>
            <Text>Average Expense Ratio</Text>
            <Text>{formatPercentage(targetRatio, 1)}</Text>
          </View>
          {hasRecommended && (
            <View style={[styles.cell, styles.cellSummary, { marginRight: 0 }]}>
              <Text>Average Expense Ratio</Text>
              <Text>{formatPercentage(recommendedRatio, 1)}</Text>
            </View>
          )}
        </View>

        <View style={styles.row}>
          <View style={[styles.cell, styles.cellSummary]}>
            <Text>Total Expense</Text>
            <Text>{formatMoney(targetValue)}</Text>
          </View>
          {hasRecommended && (
            <View style={[styles.cell, styles.cellSummary, { marginRight: 0 }]}>
              <Text>Total Expense</Text>
              <Text>{formatMoney(recommendedValue)}</Text>
            </View>
          )}
        </View>

        {expandAssets && (
          <View style={[styles.row, { marginBottom: 0, fontSize: 9 }]}>
            <View style={[styles.cell, { justifyContent: 'flex-start', paddingBottom: 0 }]}>
              {renderPositions('target', targetPositions, targetThresholdValue)}
            </View>
            {hasRecommended && (
              <View
                style={[
                  styles.cell,
                  { justifyContent: 'flex-start', marginRight: 0, paddingBottom: 0 }
                ]}
              >
                {renderPositions('recommended', recommendedPositions, recommendedThresholdValue)}
              </View>
            )}
          </View>
        )}
      </View>
    </BaseSection>
  );
};

ProposalCostAnalysis.propTypes = {
  breakSection: PropTypes.bool,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  orientation: PropTypes.string.isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalType: PropTypes.string.isRequired
};

ProposalCostAnalysis.defaultProps = {
  breakSection: false
};

export default ProposalCostAnalysis;
