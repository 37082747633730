import PropTypes from 'prop-types';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import './styles.scss';

const DisclaimerNote = ({ onlyEquity, totalAssets, totalSecurities }) => {
  if (Math.floor(totalSecurities) === Math.floor(totalAssets)) return null;
  return (
    <div className="disclaimer-note">
      <p>
        For this portfolio, data from our external providers covers{' '}
        <FormattedNumber value={totalSecurities / totalAssets} format="percent" /> of the portfolio.
        The remaining <FormattedNumber value={1 - totalSecurities / totalAssets} format="percent" />{' '}
        is either not reported or unavailable from our providers.{' '}
        {onlyEquity && '(Includes only equity stocks and equity-based funds)'}
      </p>
    </div>
  );
};

DisclaimerNote.propTypes = {
  onlyEquity: PropTypes.bool,
  totalAssets: PropTypes.number.isRequired,
  totalSecurities: PropTypes.number.isRequired
};

DisclaimerNote.defaultProps = {
  onlyEquity: false
};

export default DisclaimerNote;
