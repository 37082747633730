import TopHoldingsDistribution from 'components/advisor/proposal/securities/top-holdings-distribution';
import EditBox from 'components/advisor/utils/edit-box';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { IPSPropTypes } from '../types';

const IPSTopHoldings = ({ ips: { proposal }, ...rest }) => {
  const { ipsProvider } = useContext(AdvisorContext);

  const breakdownCustomSecurities = rest.customActions?.breakdownCustomSecurities?.value ?? true;
  const distributionProps = { target: proposal.target, targetName: proposal.target_label };
  const hasRecommended = !!proposal.recommended;

  if (hasRecommended) {
    distributionProps.recommendedName = proposal.recommended_label;
    distributionProps.recommended = proposal.recommended;
  }

  const setCollapsedTopHoldings = data => {
    ipsProvider.setCollapsedTopHoldings(data);
  };

  return (
    <EditBox {...rest}>
      <TopHoldingsDistribution
        breakdownCustomSecurities={breakdownCustomSecurities}
        setCollapsedTopHoldings={setCollapsedTopHoldings}
        {...distributionProps}
      />
    </EditBox>
  );
};

IPSTopHoldings.propTypes = {
  ips: PropTypes.shape(IPSPropTypes).isRequired
};

export default IPSTopHoldings;
