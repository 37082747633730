import Disclosure from 'components/disclosure';
import SpinnerLoader from 'components/performance-spinner';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import ScanFileIncon from 'assets/img/icons/dropzone/file-scan-iq.svg';
import { getIconComponent } from '../../utils';
import ScanFileSteps from '../steps';
import DynamicScrollCardIndex from './dynamic-scroll-card-index';
import ScanLoadingCard from '../loading-card';
import DefaultTableSection from './default-table-section';
import './styles.scss';

const ScanDetails = ({ params, tasks }) => {
  const { aiAssistantProvider, routerActions, marketProvider } = useContext(AdvisorContext);

  const [loadingRequest, setLoadingRequest] = useState(true);
  const [fileStatusIsCompleted, setFileStatusIsCompleted] = useState(false);
  const [scanResponseFileData, setScanResponseFileData] = useState({});
  const [scanDocument, setScanDocument] = useState(null);
  const [updatingFile, setUpdatingFile] = useState(true);

  const scanFileID = params.id;

  const handleGetScannedFile = () => {
    setLoadingRequest(true);
    aiAssistantProvider
      .getScannedFile(scanFileID)
      .then(response => {
        if (response.error || !response.data || response.data.status === 'failed') {
          toast.error(
            'The document that you are trying to view does not exists or is not available.'
          );
          return routerActions.push(`/advisor/ai-assistant`);
        }
        setScanDocument(response.data);
        if (response.data.status === 'completed')
          return aiAssistantProvider.getResultFile(response.data.result_file).then(data => {
            toast.dismiss(response.data.task_id);
            setScanResponseFileData(data);
            setFileStatusIsCompleted(true);
            setLoadingRequest(false);
          });
        return setFileStatusIsCompleted(false);
      })
      .finally(() => setLoadingRequest(false));
  };

  const formatDate = dateString => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });
  };

  useEffect(() => {
    setLoadingRequest(true);
    marketProvider.getClassifications();
    if (scanFileID) handleGetScannedFile();
  }, []);

  useEffect(() => {
    if (
      scanDocument?.task_id &&
      tasks[scanDocument?.task_id] &&
      (tasks[scanDocument?.task_id].scan_iq_extracted ||
        tasks[scanDocument?.task_id].scan_iq_update)
    )
      setUpdatingFile(true);

    if (
      scanDocument?.task_id &&
      tasks[scanDocument?.task_id] &&
      tasks[scanDocument?.task_id]?.scan_iq_failed
    ) {
      toast.error(
        'The file scan could not be completed. Please try again later or upload a different file.'
      );
      routerActions.push(`/advisor/ai-assistant`);
    }

    if (
      scanDocument?.task_id &&
      tasks[scanDocument?.task_id] &&
      tasks[scanDocument?.task_id].scan_iq_completed
    )
      handleGetScannedFile();
  }, [tasks]);

  useEffect(() => {
    if (!loadingRequest) {
      const allEmptyOrNull = Object.values(scanResponseFileData).every(
        value => _.isEmpty(value) || value === null
      );

      if (allEmptyOrNull) {
        toast.error(
          'The document that you are trying to view does not exists or is not available.'
        );
        routerActions.push(`/advisor/ai-assistant`);
      }
    }
  }, [JSON.stringify(scanResponseFileData)]);

  const handleDynamicRendering = (key, data, name, extraData = {}) => (
    <div id={key} key={key}>
      <h3 className="scan-details__section-title">
        {extraData.icon && <span className="icon-wrapper">{getIconComponent(extraData.icon)}</span>}
        {name}
      </h3>
      <DefaultTableSection extraData={extraData} jsonData={data} scannedDataKey={key} />
    </div>
  );

  const renderDynamicSections = () =>
    scanDocument.extraction_functions
      .sort((a, b) => a.order - b.order)
      .filter(({ slug }) => !!scanResponseFileData[slug])
      .map(extractionFunction => {
        const key = extractionFunction.slug;
        const extraData = extractionFunction?.extra_data || {};
        return handleDynamicRendering(
          key,
          scanResponseFileData[key],
          extractionFunction.name,
          extraData
        );
      });

  if (loadingRequest) return <SpinnerLoader spinnerLoading />;

  return (
    <div id="scan-details">
      <div className="scan-title__container">
        <h2>Scan IQ</h2>
        <div className="btn-header__container">
          <button
            className="btn btn-outline-secondary btn-redirect-scan-iq"
            type="button"
            onClick={() => routerActions.push(`/advisor/ai-assistant/scan-iq/history`)}
          >
            <img
              src="/img/scan-ai/list-scan-history.svg"
              alt="List"
              className="scan-iq__list-icon"
            />
            View Scan History
          </button>
          <button
            className="btn btn-primary btn-redirect-scan-iq"
            type="button"
            onClick={() => routerActions.push(`/advisor/ai-assistant/scan-iq`)}
          >
            Scan another file
          </button>
        </div>
      </div>

      <div className="card scan-basic-information">
        <img className="scan-icon-file" src={ScanFileIncon} alt="scan-file-icon" />
        <div>
          <h4 className="scan-file-name">{scanDocument.original_filename}</h4>
          <p>Scan Date: {formatDate(scanDocument.created)}</p>
          <p>
            Scanned by: {scanDocument.created_by.user.first_name}{' '}
            {scanDocument.created_by.user.last_name}
          </p>
        </div>
      </div>

      {/* Render the index section */}
      <div className="scan-index-section">
        <h3>What do you want to know now?</h3>
        <DynamicScrollCardIndex
          extractionFunctions={scanDocument.extraction_functions}
          jsonData={scanResponseFileData}
        />
      </div>

      {fileStatusIsCompleted ? (
        <div>{fileStatusIsCompleted && <div>{renderDynamicSections()}</div>}</div>
      ) : (
        <div className="card scan-iq-wrapper ">
          <div className="update-file-container loading-wrapper">
            <div className="security-returns-btn-container space-btn">
              <ScanLoadingCard
                tasks={tasks[scanDocument.task_id]}
                extractedFile={scanDocument.extract_status}
                updatingFile={updatingFile}
                extractedFunctions={scanDocument.extraction_functions}
              />
            </div>
          </div>
          <ScanFileSteps />
        </div>
      )}
      <Disclosure />
    </div>
  );
};

ScanDetails.propTypes = {
  params: PropTypes.object.isRequired,
  tasks: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  tasks: state.aiAssistant.tasks,
  scanUpdate: state.aiAssistant.scanUpdate,
  fields: ['file'],
  initialValues: {
    file: ''
  }
});

const ScanDetailsWithForm = reduxForm({
  form: 'editSecurity',
  enableReinitialize: true
})(ScanDetails);

export default connect(mapStateToProps)(ScanDetailsWithForm);
