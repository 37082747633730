import SectionLocked from 'components/advisor/section-locked';
import { INVESTMENT_STYLE, SECTION_LOCKED_MAP } from 'components/advisor/section-locked/utils';
import MorningstarStyleBoxChart from 'components/charts/morningstar-style-box-chart';
import CollapsibleTable from 'components/collapsible-table';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import DisclaimerNote from '../common/disclaimer-note';
import {
  PORTFOLIO_WITHOUT_SECURITY_DETAILS,
  SECURITY_STYLES,
  getSecurityDetails,
  hasSecurityDetails,
  processSecurityDetails
} from '../common/utils';

import './styles.scss';

const InvestmentStyle = ({ breakdownCustomSecurities, hiddenVal, portfolio }) => {
  const {
    user: {
      advisor: {
        company: {
          expand_asset_details: expandAssetDetails,
          investment_style_enabled: investmentStyleEnabled
        }
      }
    }
  } = useContext(AdvisorContext);

  const { name, portfolioRiskId } = SECTION_LOCKED_MAP[INVESTMENT_STYLE];

  if (!investmentStyleEnabled)
    return (
      <div id="investment-style">
        <SectionLocked id={portfolioRiskId} name={name} />
      </div>
    );

  if (!hasSecurityDetails(portfolio, SECURITY_STYLES, breakdownCustomSecurities))
    return (
      <div id="investment-style">
        <p>{PORTFOLIO_WITHOUT_SECURITY_DETAILS}</p>
      </div>
    );

  const totalAssets = portfolio.value ?? 0;
  const securityStyles = getSecurityDetails(
    portfolio,
    SECURITY_STYLES,
    totalAssets,
    breakdownCustomSecurities
  );

  const { data, total: totalSecurities } = processSecurityDetails(
    securityStyles,
    SECURITY_STYLES,
    totalAssets,
    null,
    true
  );

  return (
    <div id="investment-style">
      <div className="chart-container">
        <MorningstarStyleBoxChart data={data.chart} />
      </div>
      <div className="collapsible-container">
        <CollapsibleTable
          bottomNameLabel="Total Styles"
          data={data.summary}
          expandAll
          expandAssetClassification={expandAssetDetails}
          headingLabels={{ name: '', value: 'Value', percent: '% of Portfolio' }}
          hiddenVal={hiddenVal}
          showBottomSection
          showHeadingRow
          showPercent
        />
        <DisclaimerNote onlyEquity totalAssets={totalAssets} totalSecurities={totalSecurities} />
      </div>
    </div>
  );
};

InvestmentStyle.propTypes = {
  breakdownCustomSecurities: PropTypes.bool,
  hiddenVal: PropTypes.bool,
  portfolio: PropTypes.object.isRequired
};

InvestmentStyle.defaultProps = {
  breakdownCustomSecurities: false,
  hiddenVal: false
};

export default InvestmentStyle;
