/* eslint-disable import/prefer-default-export */
import {
  SECURITY_REGIONS,
  SECURITY_SECTORS,
  SECURITY_STYLES,
  SECURITY_UNDERLYING_MODEL,
  hasSecurityDetails
} from 'components/advisor/risk-analysis/securities/common/utils';
import GeographicExposure from 'components/advisor/risk-analysis/securities/geographic-exposure';
import InvestmentStyle from 'components/advisor/risk-analysis/securities/investment-style';
import SectorExposure from 'components/advisor/risk-analysis/securities/sector-exposure';
import TopHoldings from 'components/advisor/risk-analysis/securities/top-holdings';
import ScrollToTop from 'components/advisor/scroll-cards-index/scroll-to-top';
import NewTag from 'containers/advisor/new-tag';
import PropTypes from 'prop-types';
import React from 'react';

const SECURITY_DETAIL_COMPONENT = {
  [SECURITY_STYLES]: InvestmentStyle,
  [SECURITY_SECTORS]: SectorExposure,
  [SECURITY_UNDERLYING_MODEL]: TopHoldings,
  [SECURITY_REGIONS]: GeographicExposure
};

export const UnderlyingHoldingSection = ({ id, portfolio, title, type }) => {
  if (!hasSecurityDetails(portfolio, type)) return null;

  const SecurityComponent = SECURITY_DETAIL_COMPONENT[type];

  return (
    <div className="underlying-holding-section box-container" id={id}>
      <div className="box-heading">
        <span>
          {title} <NewTag />
        </span>
      </div>
      <div className="box-content">
        <SecurityComponent hiddenVal portfolio={portfolio} />
      </div>
      <ScrollToTop />
    </div>
  );
};

UnderlyingHoldingSection.defaultProps = {
  id: undefined
};

UnderlyingHoldingSection.propTypes = {
  id: PropTypes.string,
  portfolio: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};
