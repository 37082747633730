import React from 'react';
import './styles.scss';

export const NEW_FEATURED_SECTIONS = [
  'investmentStyle',
  'sectorExposure',
  'topHoldings',
  'regionExposure'
];

const NewTag = () => <div className="new-tag">NEW</div>;

export default NewTag;
