import cn from 'classnames';
import DateValue from 'components/advisor/proposal/body/commons/date-value-label';
import EditBoxText from 'components/advisor/utils/edit-box-text';
import PropTypes from 'prop-types';
import React from 'react';
import { formatMoney, formatPercentage } from 'utils/utils';
import useProposalMetadata from '../hooks';
import { MetadataPropTypes, ProposalPropTypes } from '../types';
import './styles.scss';
import { getProcessedPositions } from './utils';

const CostsTable = ({ positions = [], idx }) => {
  const key = positions.reduce((x, y) => x + y.ticker, '');
  return (
    <div className="details" key={key || idx}>
      {positions.length ? (
        <table>
          <thead>
            <tr>
              <th className="ticker">Asset</th>
              <th className="ratio">Weight</th>
              <th className="ratio">Expense (%)</th>
              <th className="value">Expense ($)</th>
            </tr>
          </thead>
          <tbody>
            {positions.map(position => (
              <tr key={position.ticker}>
                <td className="ticker">{position.ticker_name || position.ticker}</td>
                <td className="ticker">{formatPercentage(position.weight)}</td>
                <td className="ratio">{formatPercentage(position.expense_ratio, 1)}</td>
                <td className="value">{formatMoney(position.expense_value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        "The positions don't have fees or the fees are not available."
      )}
    </div>
  );
};

CostsTable.propTypes = {
  positions: PropTypes.arrayOf(PropTypes.object).isRequired,
  idx: PropTypes.number.isRequired
};

const ProposalCostAnalysis = ({
  actionsDisabled,
  metadata,
  propertyKey,
  proposal,
  setMetadata
}) => {
  const proposalMetadata = useProposalMetadata({
    actionsDisabled,
    metadata,
    propertyKey,
    setMetadata
  });

  const expandAssets = proposalMetadata.customActions?.expandAssets?.value ?? true;
  const breakdownCustomSecurities =
    proposalMetadata.customActions?.breakdownCustomSecurities?.value ?? true;

  const hasRecommended = !!proposal.recommended;
  const targetPositions = getProcessedPositions(proposal, breakdownCustomSecurities, 'target');
  const recommendedPositions = getProcessedPositions(
    proposal,
    breakdownCustomSecurities,
    'recommended'
  );

  const { ratio: targetRatio, value: targetValue } = targetPositions.reduce(
    ({ ratio, value }, position) => ({
      ratio: ratio + position.weighted_expense_ratio,
      value: value + position.expense_value
    }),
    { ratio: 0, value: 0 }
  );

  const { ratio: recommendedRatio, value: recommendedValue } = recommendedPositions.reduce(
    ({ ratio, value }, position) => ({
      ratio: ratio + position.weighted_expense_ratio,
      value: value + position.expense_value
    }),
    { ratio: 0, value: 0 }
  );

  return (
    <EditBoxText {...proposalMetadata}>
      <div id="cost-analysis">
        <DateValue proposal={proposal} />
        <div className={cn('fancy-grid', { 'no-recommended': !hasRecommended })}>
          <div className="header">{proposal.target_label || 'Current Portfolio'}</div>
          {hasRecommended && <div className="header">{proposal.recommended_label}</div>}

          <div>
            <div className="summary">
              <span>Average Expense Ratio</span>
              <span>{formatPercentage(targetRatio, 1)}</span>
            </div>
          </div>
          {hasRecommended && (
            <div>
              <div className="summary">
                <span>Average Expense Ratio</span>
                <span>{formatPercentage(recommendedRatio, 1)}</span>
              </div>
            </div>
          )}

          <div>
            <div className="summary">
              <span>Total Expense</span>
              <span>{formatMoney(targetValue)}</span>
            </div>
          </div>
          {hasRecommended && (
            <div>
              <div className="summary">
                <span>Total Expense</span>
                <span>{formatMoney(recommendedValue)}</span>
              </div>
            </div>
          )}

          {expandAssets && <CostsTable positions={targetPositions} idx={0} />}
          {expandAssets && hasRecommended && (
            <CostsTable positions={recommendedPositions} idx={1} />
          )}
        </div>
      </div>
    </EditBoxText>
  );
};

ProposalCostAnalysis.propTypes = {
  actionsDisabled: PropTypes.bool.isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalType: PropTypes.string.isRequired,
  setMetadata: PropTypes.func.isRequired
};

export default ProposalCostAnalysis;
