import SpinnerLoader from 'components/performance-spinner';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import stringSimilarity from 'string-similarity';
import {
  DEFINING_FIELDS_STEP,
  POSITIONS_SUMMARY_STEP,
  PROCESSING_POSITIONS_STEP
} from './constants';
import './style.scss';

class CSVWizard extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { mappedFields: {} };
  }

  componentDidMount() {
    const { currentStep, fields, processFields } = this.props;

    const mappedFields = {};
    fields.forEach(f => {
      mappedFields[f] = this.getRow(f);
    });
    this.setState({ mappedFields });

    // if the component is initialized in the processing step, execute the flow responsible
    // for initiating that step
    if (currentStep === PROCESSING_POSITIONS_STEP) processFields(mappedFields);
  }

  selectRow = (field, value) => {
    const { mappedFields } = this.state;
    this.setState({ mappedFields: { ...mappedFields, [field]: value } });
  };

  getRow = v => {
    const { rows } = this.props;
    const index = _.findIndex(rows, r => stringSimilarity.compareTwoStrings(r, v) >= 0.8);
    if (index >= 0) return rows[index];
    return '';
  };

  disabledSubmit = () => {
    const { mappedFields } = this.state;
    let disabled = false;
    _.forOwn(mappedFields, (value, _) => {
      if (!value) disabled = true;
    });
    return disabled;
  };

  stepSubmit = () => {
    const { mappedFields } = this.state;
    const { processFields, currentStep, endWizard } = this.props;
    if (currentStep === DEFINING_FIELDS_STEP) processFields(mappedFields);
    if (currentStep === POSITIONS_SUMMARY_STEP) endWizard();
  };

  totalWeight = () => {
    const { positions } = this.props;
    if (positions && positions.length) {
      const weightTotal = positions.reduce((total, pos) => total + pos.value, 0);
      return parseFloat(weightTotal.toFixed(2));
    }
    return 0;
  };

  render() {
    const { mappedFields } = this.state;
    const { currentStep, fields, isAccountSource, positions, rows } = this.props;

    const [positionsWithSecurity, positionsWithoutSecurity] = _.partition(positions, 'security');

    return (
      <div id="CSVWizard">
        {currentStep === DEFINING_FIELDS_STEP && (
          <div className={`step${currentStep}`}>
            <h3>Select your spreadsheet data to StratiFi fields.</h3>
            {fields.map(f => (
              <div key={`${f}-key`}>
                <label htmlFor={`${f}_id`}>{f}:</label>
                <div className="c-select-wrap">
                  <select
                    className="c-select"
                    id={`${f}_id`}
                    onChange={event => this.selectRow(f, event.target.value)}
                  >
                    <option value="">Select</option>
                    {rows.map(r => (
                      <option
                        disabled={mappedFields[_.find(fields, x => x !== f)] === r}
                        key={`${r}-key`}
                        selected={mappedFields[f] === r}
                        value={r}
                      >
                        {r}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ))}
          </div>
        )}

        {currentStep === PROCESSING_POSITIONS_STEP && (
          <div className={`step${currentStep}`}>
            <SpinnerLoader spinnerLoading />
            <h4 className="processing">Processing Securities</h4>
          </div>
        )}

        {currentStep === 3 && (
          <div className={`step${currentStep}`}>
            <h4>Summary Report</h4>
            <div className="report">
              {!!positionsWithSecurity.length && (
                <span className="report-row success">
                  <span className="icon-checkmark-circle" />
                  {positionsWithSecurity.length} Securities uploaded successfully.
                </span>
              )}
              {!!positionsWithoutSecurity.length && (
                <span className="report-row failed">
                  <span className="icon-remove" />
                  {positionsWithoutSecurity.length} Securities were not found in our system.
                  <ul>
                    <li>
                      {positionsWithoutSecurity.map(({ ticker }, index) =>
                        index + 1 === positionsWithoutSecurity.length ? `${ticker}` : `${ticker}, `
                      )}
                    </li>
                  </ul>
                </span>
              )}
              {this.totalWeight() !== 100 && !isAccountSource && (
                <span className="report-row failed">
                  <span className="icon-remove" />
                  Total is other than 100%
                </span>
              )}
            </div>
          </div>
        )}

        {currentStep !== PROCESSING_POSITIONS_STEP && (
          <div className="actions">
            <button
              className="btn btn-primary"
              onClick={() => this.stepSubmit()}
              disabled={this.disabledSubmit()}
              tabIndex="0"
              type="button"
              onKeyDown={() => {}}
            >
              {currentStep === DEFINING_FIELDS_STEP
                ? 'Continue'
                : `View the ${isAccountSource ? 'account' : 'model portfolio'}`}
            </button>
          </div>
        )}
      </div>
    );
  }
}

CSVWizard.defaultProps = {
  isAccountSource: false
};

CSVWizard.propTypes = {
  currentStep: PropTypes.number.isRequired,
  endWizard: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  isAccountSource: PropTypes.bool,
  positions: PropTypes.arrayOf(
    PropTypes.shape({ ticker: PropTypes.string.isRequired, value: PropTypes.number.isRequired })
  ).isRequired,
  processFields: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default CSVWizard;
