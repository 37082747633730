/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CustomizeOrderModal from '../customize-order-modal';
import './styles.scss';

const HIDE_REPORT_OPTIONS = 'hideReportOptions';

const ReportOptionsToggleable = ({
  canOrder,
  canUpdate,
  handleSaveTemplate,
  handleSaveTemplateOrder,
  templateContent,
  templateSettings
}) => {
  const [hideReportOptions, setHideReportOptions] = useState(
    window.localStorage && window.localStorage.getItem(HIDE_REPORT_OPTIONS) === 'true'
  );
  const [loading, setLoading] = useState(false);

  const toggleReportOptions = () => {
    setHideReportOptions(prevHideReportOptions => {
      if (window.localStorage)
        window.localStorage.setItem(HIDE_REPORT_OPTIONS, !prevHideReportOptions);
      return !prevHideReportOptions;
    });
  };

  const toggleUnderlyingAssets = value => () => {
    setLoading(true);
    const updatedTemplateContent = _.cloneDeep(templateContent);
    Object.entries(templateContent)
      .filter(([key, content]) => !!content?.customActions?.breakdownCustomSecurities)
      .forEach(([key, content]) => {
        updatedTemplateContent[key].customActions.breakdownCustomSecurities.value = value;
      });
    return handleSaveTemplate(null, updatedTemplateContent).finally(() => {
      setLoading(false);
    });
  };

  return (
    <div className="accordion" id="report-options-toggleable">
      <div className="card">
        <div
          aria-controls="report-options-content"
          aria-expanded="false"
          className={cn('card-header', { collapsed: hideReportOptions })}
          data-target="#report-options-content"
          id="report-options-header"
          onClick={toggleReportOptions}
        >
          <p className="font-weight-bold mb-0">Report Options</p>
          <i className="fs-icon-arrow-blue" />
        </div>

        <div
          aria-labelledby="report-options-header"
          className={cn('card-body', 'collapse', { show: !hideReportOptions })}
          data-parent="#report-options-toggleable"
          id="report-options-content"
        >
          <div className="card-body__content">
            {canOrder && (
              <CustomizeOrderModal
                buttonDisabled={loading}
                handleSaveTemplate={handleSaveTemplateOrder}
                templateContent={templateContent}
                templateSettings={templateSettings}
              />
            )}
            {canUpdate && (
              <div className="content__bulk-actions">
                <p>Do you want to display the custom securities or their underlying assets?</p>
                <button
                  className="btn btn-link btn-small p-0"
                  disabled={loading}
                  onClick={toggleUnderlyingAssets(false)}
                  type="button"
                >
                  Display custom securities
                </button>
                <button
                  className="btn btn-link btn-small p-0 ml-3"
                  disabled={loading}
                  onClick={toggleUnderlyingAssets(true)}
                  type="button"
                >
                  Display underlying assets
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ReportOptionsToggleable.defaultProps = {
  canOrder: false,
  canUpdate: false,
  handleSaveTemplateOrder: null,
  templateSettings: {}
};

ReportOptionsToggleable.propTypes = {
  canOrder: PropTypes.bool,
  canUpdate: PropTypes.bool,
  handleSaveTemplate: PropTypes.func.isRequired,
  handleSaveTemplateOrder: PropTypes.func,
  templateContent: PropTypes.object.isRequired,
  templateSettings: PropTypes.object
};

export default ReportOptionsToggleable;
