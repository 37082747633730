import ArrowRight from 'assets/img/arrow-right.svg';
import SwapIcon from 'assets/img/swap-horizontal.svg';
import ProspectInvestorAsyncSelect from 'components/advisor/prospect-investor-async-select';
import LoadingButton from 'components/loading-button';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import './styles.scss';

const MergeModal = ({ sourceInvestorProp, targetInvestorProp, onClose, location }) => {
  const [submitting, setSubmitting] = useState(false);
  const [sourceInvestor, setSourceInvestor] = useState(sourceInvestorProp);
  const [targetInvestor, setTargetInvestor] = useState(targetInvestorProp);

  if (!targetInvestor) return null;

  const { investorProvider, prospectProvider, routerActions } = useContext(AdvisorContext);

  const onClickSwap = () => {
    setSourceInvestor(targetInvestor);
    setTargetInvestor(sourceInvestor);
  };

  const onClickChangeSourceInvestor = () => {
    setSourceInvestor(null);
  };

  const onClickMergeInvestors = () => {
    setSubmitting(true);
    const isProspectSection = targetInvestor.is_prospect;
    const provider = isProspectSection ? prospectProvider : investorProvider;

    investorProvider
      .mergeInvestors(sourceInvestor.id, targetInvestor.id)
      .then(() => {
        const targetBasePath = `advisor/${isProspectSection ? 'prospects' : 'investors'}/${
          targetInvestor.id
        }`;
        if (location.pathname.startsWith(targetBasePath))
          provider.getAccounts(targetInvestor.id).then(() => {
            provider.get(targetInvestor.id);
          });
        else routerActions.push(`${targetBasePath}/overview`);

        toast.success('🎊 The 2 investors were merged.');
        onClose();
      })
      .catch(() => {
        toast.error('An error occurred while merging the investors.');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  // Determine if source is a prospect and target is a client
  const isClientToProspectClientMerge =
    sourceInvestor && !sourceInvestor?.is_prospect && targetInvestor?.is_prospect;

  return (
    <Modal id="merge-advisor-modal" className="modal-lg" onHidden={onClose} show>
      <ModalHeader onClose={onClose} />
      <ModalBody className="merge-modal-body">
        <h4>Merge Clients</h4>
        <div>Are you sure to merge the clients listed below?</div>
        <div className="merge-modal-content">
          <div className="investor-container">
            <div className="investor-details">
              {!sourceInvestor && (
                <ProspectInvestorAsyncSelect
                  filteredInvestorId={targetInvestor.id}
                  onChange={setSourceInvestor}
                />
              )}
              {sourceInvestor && (
                <>
                  <div className="investor-name">{sourceInvestor.full_name}</div>
                  <ul className="investor-info">
                    <li>
                      Total Accounts: <span>{sourceInvestor.accounts}</span>
                    </li>
                    <li>
                      Household: <span>{sourceInvestor.household}</span>
                    </li>
                    <li>
                      Origin: <span>{sourceInvestor.origin}</span>
                    </li>
                  </ul>
                  <button
                    type="button"
                    className="change-source-btn"
                    onClick={onClickChangeSourceInvestor}
                  >
                    Change
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="swap-container">
            <span className="arrow-right">
              <img src={ArrowRight} alt="arrow-right" />
            </span>
            <button
              type="button"
              disabled={!sourceInvestor}
              onClick={onClickSwap}
              className="swap-button"
            >
              <div>swap</div>
              <div>
                <img src={SwapIcon} alt="swap-icon" />
              </div>
            </button>
          </div>
          <div className="investor-container">
            <div className="investor-details">
              <div className="investor-name">{targetInvestor.full_name}</div>
              <ul className="investor-info">
                <li>
                  Total Accounts: <span>{targetInvestor.accounts}</span>
                </li>
                <li>
                  Household: <span>{targetInvestor.household ?? '-'}</span>
                </li>
                <li>
                  Origin: <span>{targetInvestor.origin ?? '-'}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          {sourceInvestor ? sourceInvestor.full_name : '________________'} will be merged into{' '}
          {targetInvestor.full_name}
        </div>
        <div>
          If you agree, the accounts, questionnaires, goals, proposals, and IPS will be moved into
          the resulting record.
        </div>
        {/* Conditional Warning Message */}
        {isClientToProspectClientMerge && (
          <div className="alert alert-warning" role="alert">
            You are merging a client record into a prospect record. The result will be a prospect.
          </div>
        )}
        <div className="warning-text">This action cannot be undone!</div>
        <div className="merge-modal-actions">
          <button onClick={onClose} type="button" className="btn btn-outline-primary action-button">
            Cancel
          </button>
          <LoadingButton
            className="btn btn-primary merge-button"
            disabled={!sourceInvestor || submitting}
            loading={submitting}
            onClick={onClickMergeInvestors}
          >
            Merge
          </LoadingButton>
        </div>
      </ModalBody>
    </Modal>
  );
};

MergeModal.propTypes = {
  location: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  sourceInvestorProp: PropTypes.object,
  targetInvestorProp: PropTypes.object.isRequired
};

MergeModal.defaultProps = {
  sourceInvestorProp: null
};

export default withRouter(MergeModal);
