import {
  AI_ASSISTANT_SCAN_VERB_SAVE,
  AI_ASSISTANT_SCAN_FUNCTION_UPDATE,
  AI_ASSISTANT_SCAN_CLEAR_SCAN,
  AI_ASSISTANT_SCAN_HISTORY_LIST
} from 'constants/actions';

const initialState = {
  tasks: {},
  list: [],
  listMeta: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AI_ASSISTANT_SCAN_VERB_SAVE: {
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            [action.verb]: true
          }
        }
      };
    }

    case AI_ASSISTANT_SCAN_HISTORY_LIST:
      return {
        ...state,
        list: action.data,
        listMeta: action.meta
      };

    case AI_ASSISTANT_SCAN_FUNCTION_UPDATE: {
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            [action.data]: 'complete'
          }
        }
      };
    }

    case AI_ASSISTANT_SCAN_CLEAR_SCAN: {
      return {
        ...state,
        tasks: {}
      };
    }

    default:
      return state;
  }
};
