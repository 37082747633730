/* eslint-disable react/no-array-index-key */
import cn from 'classnames';
import { ExpandCollapseButton, renderFormattedValue } from 'containers/advisor/ai-assistant/utils';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { MAX_COLLAPSED_TABLE_ROWS, MAX_ROWS_THRESHOLD } from './constants';
import './styles.scss';
import TableWithHeader from './table-with-header';

const formatKey = key => key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());

const validElement = element =>
  element !== null && element !== 0 && element !== 'None' && element !== '';
const validValue = ([, value]) => validElement(value);

// Function to render values as a list
const renderList = (value, extraData) => {
  const customFormatting = extraData?.custom_formatting || {};

  if (typeof value === 'object' && value !== null) {
    if (Array.isArray(value)) {
      const filteredArray = value.filter(validElement);
      if (filteredArray.length === 0) return null;

      const isTable = filteredArray.every(
        item => typeof item === 'object' && item !== null && !Array.isArray(item)
      );

      if (isTable) {
        // Extract headers from the first item in the array
        const headers = Object.keys(filteredArray[0]).filter(key =>
          filteredArray.some(item => validElement(item[key]))
        );

        return (
          <div className="card ai-assitant-default-card">
            <table className="scan-default__table defautl-table__list">
              <thead>
                <tr>
                  {headers.map(key => (
                    <th key={key}>{formatKey(key)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredArray.map((item, rowIndex) => (
                  <tr key={rowIndex}>
                    {headers.map(key => {
                      const { formattedValue } = renderFormattedValue(
                        key,
                        item[key],
                        customFormatting
                      );
                      return <td key={key}>{validElement(item[key]) ? formattedValue : '-'}</td>;
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      }

      // Render as a list for non-table arrays
      return (
        <ul className="scan-iq__default-list">
          {filteredArray.map((item, index) => (
            <li key={index}>{renderList(item, customFormatting)}</li>
          ))}
        </ul>
      );
    }

    // Filter out any key-value pairs where value doesn't pass `validValue`
    const filteredEntries = Object.entries(value).filter(validValue);
    if (filteredEntries.length === 0) return null;

    return (
      <div className="card ai-assitant-default-card">
        <ul className="scan-iq-grid-list scan-iq__default-list">
          {filteredEntries.map(([key, val]) => {
            const { formattedValue } = renderFormattedValue(key, val, customFormatting);
            return (
              <li key={key}>
                <span className="scan-iq__default-table__span">{formatKey(key)}:</span>{' '}
                {typeof val === 'object' ? renderList(val, customFormatting) : formattedValue}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return String(value);
};

// Recursive function to handle nested objects and arrays as tables
const renderTable = (value, extraData, scannedDataKey, marketStore) => {
  const customFormatting = extraData?.custom_formatting || {};

  if (typeof value === 'object' && value !== null) {
    if (Array.isArray(value)) {
      const hasObjectWithNestedArray = value.some(
        item =>
          typeof item === 'object' &&
          item !== null &&
          Object.values(item).some(val => Array.isArray(val))
      );

      if (hasObjectWithNestedArray)
        // Use `<TableWithHeader />` for arrays with nested arrays
        return (
          <>
            {value.map((item, index) => (
              <TableWithHeader
                extraData={extraData}
                item={item}
                key={index}
                marketStore={marketStore}
                scannedDataKey={scannedDataKey}
              />
            ))}
          </>
        );

      const [isExpanded, setIsExpanded] = useState(false);

      // Headers for the table
      const headers = Object.keys(value[0] || {}).filter(key =>
        value.some(item => validElement(item[key]))
      );

      // Check if there are 10 or more rows
      const hasTenOrMoreRows = value.length >= MAX_ROWS_THRESHOLD;
      const rowsToDisplay = isExpanded ? value : value.slice(0, MAX_COLLAPSED_TABLE_ROWS);

      return (
        <div className="card ai-assitant-default-card">
          <table border="1" cellPadding="7" cellSpacing="0" className="scan-default__table">
            <thead>
              <tr>
                {headers.map(key => {
                  const { align } = renderFormattedValue(key, null, customFormatting);
                  return (
                    <th
                      key={key}
                      className={cn({
                        'align-right': align === 'right',
                        'align-left': align === 'left',
                        'align-center': align === 'center'
                      })}
                    >
                      {formatKey(key)}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {rowsToDisplay.map((item, index) => (
                <tr key={index}>
                  {headers.map(key => {
                    const { formattedValue, align } = renderFormattedValue(
                      key,
                      item[key],
                      customFormatting
                    );
                    return (
                      <td
                        key={key}
                        className={cn({
                          'align-right': align === 'right',
                          'align-left': align === 'left',
                          'align-center': align === 'center'
                        })}
                      >
                        {validElement(item[key]) ? formattedValue : '-'}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
          <ExpandCollapseButton
            hasTenOrMoreRows={hasTenOrMoreRows}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          />
        </div>
      );
    }

    // Render non-array objects here if needed
    return <div>{String(value)}</div>;
  }

  return String(value);
};

const DefaultTableSection = ({ extraData, jsonData, marketStore, scannedDataKey }) => {
  if (Array.isArray(jsonData))
    // if the `jsonData` received is an array, a default table or a table with a header will be rendered
    return renderTable(jsonData, extraData, scannedDataKey, marketStore);
  // otherwise, if it's a simple object, a list will be rendered.
  return renderList(jsonData, extraData);
};

DefaultTableSection.defaultProps = {
  extraData: {}
};

DefaultTableSection.propTypes = {
  extraData: PropTypes.object,
  jsonData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  marketStore: PropTypes.object.isRequired,
  scannedDataKey: PropTypes.string.isRequired
};

export default connect(state => ({
  marketStore: state.market
}))(DefaultTableSection);
