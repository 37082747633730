import {
  DEFINING_FIELDS_STEP,
  INITIAL_WIZARD_STEP,
  POSITIONS_SUMMARY_STEP,
  PROCESSING_POSITIONS_STEP
} from 'components/utils/csv-wizard/constants';
import { propTypesCheck } from 'hocs/backend-validation';
import _ from 'lodash';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { LOAD_CSV_CONFIG, normalizeCsv } from 'utils/papa_parse_config';
import { renameProp } from 'utils/utils';

export const modes = { CREATE: 'create', UPDATE: 'update' };

class ModelEditGeneral extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      copy: false,
      csvPropsData: false,
      defaultFields: ['ticker', 'weight'],
      formChanged: false,
      wizardCSV: {
        show: false,
        step: INITIAL_WIZARD_STEP,
        success: false,
        headerRows: [],
        data: [],
        tickerLabel: null,
        weightLabel: null,
        loadedCSV: false
      }
    };
    this.nameInput = null;
  }

  componentDidMount() {
    const { modelProvider } = this.context;
    const { model: data, fields, registerCSVLoadedCallback, initializeForm, mode } = this.props;
    const callback = registerCSVLoadedCallback || this.defaultCSVCallback;

    callback(this.onCSVLoaded);
    initializeForm(modelProvider.form(data)); // set initial values
    if (mode === modes.CREATE) fields.positions.addField({}); // add one input line for initial state
  }

  onFormChange() {
    this.setState({ formChanged: true });
  }

  defaultCSVCallback = callback => {
    this.onCSVLoaded = callback;
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  processCSVFields = mappedFields => {
    const { marketProvider } = this.context;
    const {
      wizardCSV,
      defaultFields,
      wizardCSV: { data }
    } = this.state;

    this.setState({ wizardCSV: { ...wizardCSV, step: PROCESSING_POSITIONS_STEP, mappedFields } });

    const tickersData = data.map(row => [
      row[mappedFields[defaultFields[0]]],
      row[mappedFields[defaultFields[1]]]
    ]);

    const finalStep = () =>
      this.setState({ wizardCSV: { ...wizardCSV, step: POSITIONS_SUMMARY_STEP } });
    marketProvider.addCSVDataToFormPositions(tickersData).then(finalStep);
  };

  onCSVLoaded = e => {
    const { errorsProvider } = this.context;
    const result = normalizeCsv(e.target.result);
    const { data, errors } = Papa.parse(result, LOAD_CSV_CONFIG);
    if (data.length)
      this.setState({
        wizardCSV: {
          data,
          headerRows: Object.keys(data[0]),
          show: true,
          step: DEFINING_FIELDS_STEP
        }
      });

    if (errors && errors.length) errors.forEach(e => errorsProvider.registerError(e.message));
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  saveNameInputRef = input => {
    this.nameInput = input;
  };

  clearCSVData() {
    this.setState({
      wizardCSV: { show: false, step: INITIAL_WIZARD_STEP, headerRows: [], data: [] }
    });
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  onCSVModalClose = () => {
    const { wizardCSV } = this.state;
    if (wizardCSV.step === POSITIONS_SUMMARY_STEP) this.endCSVWizard();
    else this.clearCSVData();
  };

  endCSVWizard = () => {
    const { fields, positions } = this.props;
    // Delete empty row
    if (fields.positions.length === 1 && !fields.positions[0].ticker.value)
      fields.positions.removeField(0);
    this.onFormChange();

    // Prevent duplications.
    const noDuplicationsTickers = _.uniqBy(positions, 'ticker');

    const tickerNames = positions.map(pos => pos.ticker);
    fields.positions.forEach((pos, i) => {
      if (tickerNames.includes(pos.ticker.value)) fields.positions.removeField(i);
    });

    noDuplicationsTickers.forEach(item => {
      fields.positions.addField({ ...renameProp('id', 'security', item) });
    });
    this.clearCSVData();
  };
}

ModelEditGeneral.propTypes = {
  error: PropTypes.string,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  marketStore: PropTypes.object.isRequired,
  mode: PropTypes.oneOf([modes.CREATE, modes.UPDATE]),
  model: PropTypes.object,
  onCSVInputChange: PropTypes.func,
  onSuccess: PropTypes.func,
  positions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      sector: PropTypes.number,
      subtype: PropTypes.number,
      ticker_name: PropTypes.string,
      ticker: PropTypes.string.isRequired,
      type: PropTypes.number,
      value: PropTypes.number
    })
  ),
  reCalculatePrism: PropTypes.func,
  registerCSVLoadedCallback: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  ...propTypesCheck
};

ModelEditGeneral.contextTypes = {
  accountProvider: PropTypes.object,
  actionProvider: PropTypes.object,
  companyProvider: PropTypes.object,
  config: PropTypes.object.isRequired,
  errorsProvider: PropTypes.object.isRequired,
  investorProvider: PropTypes.object,
  marketProvider: PropTypes.object.isRequired,
  modelProvider: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  prospectProvider: PropTypes.object,
  routerActions: PropTypes.object
};

ModelEditGeneral.defaultProps = {
  error: null,
  mode: null,
  model: null,
  onCSVInputChange: null,
  onSuccess: null,
  positions: null,
  reCalculatePrism: null,
  registerCSVLoadedCallback: null
};

export default ModelEditGeneral;
