import { toast } from 'react-toastify';
import {
  AI_ASSISTANT_SCAN_VERB_SAVE,
  AI_ASSISTANT_SCAN_FUNCTION_UPDATE,
  AI_ASSISTANT_SCAN_CLEAR_SCAN,
  AI_ASSISTANT_SCAN_HISTORY_LIST
} from 'constants/actions';
import config from '../config';
import DataProvider from './data';

class AIAssistantProvider extends DataProvider {
  scanFile(formData) {
    return this.provider.postFormData(`${config.apiBase}documents/`, formData);
  }

  list(params = {}) {
    return this.provider.get(`${config.apiBase}documents/`, params).then(({ data, error }) => {
      if (!error)
        this.dispatch(AI_ASSISTANT_SCAN_HISTORY_LIST, {
          data: data.results || [],
          meta: {
            params,
            count: data.count,
            totalPages: data.total_pages
          }
        });
      else
        this.dispatch(AI_ASSISTANT_SCAN_HISTORY_LIST, {
          data: { data: [], meta: { params, count: 0, totalPages: 1 } }
        });
      return data;
    });
  }

  getScannedFile(id) {
    return this.provider.get(`${config.apiBase}documents/${id}/`);
  }

  // eslint-disable-next-line class-methods-use-this
  async getResultFile(url) {
    const result = await fetch(url)
      .then(response => {
        if (!response.ok) throw new Error('Network response failed traing to get the scanned file');
        return response.json();
      })
      .catch(error => {
        toast.error(error.message);
      });
    return result;
  }

  saveScanFileVerb(verb, taskId) {
    return this.dispatch(AI_ASSISTANT_SCAN_VERB_SAVE, {
      taskId,
      verb
    });
  }

  saveScanFunctionUpdate(data, taskId) {
    return this.dispatch(AI_ASSISTANT_SCAN_FUNCTION_UPDATE, {
      taskId,
      data
    });
  }

  clearScanFileData() {
    return this.dispatch(AI_ASSISTANT_SCAN_CLEAR_SCAN);
  }
}

export default AIAssistantProvider;
