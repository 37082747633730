import cn from 'classnames';
import Disclosure from 'components/disclosure';
import DropzoneComponent from 'components/dropzone/dropzone-component';
import SpinnerLoader from 'components/performance-spinner';
import MinusIcon from 'components/svg-icons/minus-icon';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';
import SecurityReturnsParams from '../../returns-params';
import { POST_CREATE_STEP, RETURNS_FILE_WAS_IMPORTED } from '../../constants';
import './styles.scss';

const DEFAULT_DATE_COLUMN = 0;
const DEFAULT_VALUE_COLUMN = 1;
const DEFAULT_DATE_COLUMN_NAME = 'DATE';
const DEFAULT_VALUE_COLUMN_NAME = 'RETURN';

const validate = values => {
  const errors = {
    file: '',
    frequency: '',
    type: '',
    format: '',
    date_column: '',
    value_column: '',
    date_column_name: '',
    value_column_name: ''
  };

  errors.frequency = validation.required(values.frequency);
  errors.type = validation.required(values.type);
  if (values.type === 'returns') errors.format = validation.required(values.format);

  return errors;
};

const maxFiles = 1;
const SECURITY_PORTFOLIOS_CSV_TEMPLATE_URL = '/csv/default_asset_returns.csv';

const SecurityReturns = ({
  currentSecurityCreateReturns,
  errors,
  fields,
  initializeForm,
  values,
  security,
  handleSubmit,
  params,
  updateStep
}) => {
  const { customSecurityProvider } = useContext(AdvisorContext);

  const [loadingRequest, setLoadingRequest] = useState(false);
  const [fileCSV, setFileCSV] = useState([]);

  const securityID = params.id;

  const initializeFormFields = () => {
    const values = {
      file: securityID ? '' : currentSecurityCreateReturns.file || '',
      frequency: securityID ? '' : currentSecurityCreateReturns.frequency || '',
      type: securityID ? '' : currentSecurityCreateReturns.type || '',
      format: securityID ? 'decimal' : currentSecurityCreateReturns.format || 'decimal',
      interpolation: securityID ? '' : currentSecurityCreateReturns.interpolation || '',
      file_has_header: securityID ? false : currentSecurityCreateReturns.file_has_header || false,
      file_columns: securityID ? [] : currentSecurityCreateReturns.file_columns || [],
      date_column: securityID
        ? DEFAULT_DATE_COLUMN
        : currentSecurityCreateReturns.date_column || DEFAULT_DATE_COLUMN,
      value_column: securityID
        ? DEFAULT_VALUE_COLUMN
        : currentSecurityCreateReturns.value_column || DEFAULT_VALUE_COLUMN,
      date_column_name: securityID
        ? DEFAULT_DATE_COLUMN_NAME
        : currentSecurityCreateReturns.date_column_name || DEFAULT_DATE_COLUMN_NAME,
      value_column_name: securityID
        ? DEFAULT_VALUE_COLUMN_NAME
        : currentSecurityCreateReturns.value_column_name || DEFAULT_VALUE_COLUMN_NAME
    };
    initializeForm(values);
  };

  const initializeFormStorage = parsedValues => {
    const values = {
      frequency: parsedValues.frequency || '',
      type: parsedValues.type || '',
      format: parsedValues.format || 'decimal',
      interpolation: parsedValues.interpolation || '',
      file_has_header: parsedValues.file_has_header || false,
      file_columns: parsedValues.file_columns || [],
      date_column: parsedValues.date_column || DEFAULT_DATE_COLUMN,
      value_column: parsedValues.value_column || DEFAULT_VALUE_COLUMN,
      date_column_name: parsedValues.date_column_name || DEFAULT_DATE_COLUMN_NAME,
      value_column_name: parsedValues.value_column_name || DEFAULT_VALUE_COLUMN_NAME
    };
    return initializeForm(values);
  };

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    const storedValues = localStorage.getItem('securityReturnsFields');

    if (storedValues) {
      const parsedValues = JSON.parse(storedValues);
      initializeFormStorage(parsedValues);
    }
    return () => {
      customSecurityProvider.clearReturns();
    };
  }, []);

  useEffect(() => {
    if (!securityID && !_.isEmpty(currentSecurityCreateReturns)) {
      const storedValues = localStorage.getItem('securityReturnsFields');
      if (storedValues) {
        const parsedValues = JSON.parse(storedValues);
        initializeFormStorage(parsedValues);
      } else initializeFormFields();

      setFileCSV(currentSecurityCreateReturns.file);
    }
  }, [JSON.stringify(currentSecurityCreateReturns)]);

  useEffect(() => {
    const storedValues = localStorage.getItem('securityReturnsFields');
    if (storedValues) {
      const parsedValues = JSON.parse(storedValues);
      initializeFormStorage(parsedValues);
    } else initializeFormFields();
  }, [JSON.stringify(security)]);

  useEffect(() => {
    if (fileCSV.length === 0) {
      fields.file_columns.onChange([]);
      fields.date_column.onChange(DEFAULT_DATE_COLUMN); // Reset to default value 0
      fields.value_column.onChange(DEFAULT_VALUE_COLUMN); // Reset to default value 1
      fields.date_column_name.onChange('');
      fields.value_column_name.onChange('');
      return () => {
        customSecurityProvider.clearReturns();
      };
    }

    const file = fileCSV[0];
    fields.file.onChange(file);

    // Read the file
    const reader = new FileReader();

    reader.onload = e => {
      if (e.target && e.target.result) {
        const fileContent = e.target.result;
        const lines = fileContent.split('\n');

        if (lines.length > 0) {
          const columns = lines[0].split(',');

          fields.file_columns.onChange(columns);

          // Retrieve stored column names
          const storedValues = JSON.parse(localStorage.getItem('securityReturnsFields') || '{}');
          const storedDateColumnName = storedValues.date_column_name || '';
          const storedValueColumnName = storedValues.value_column_name || '';

          // Check if stored column names match any columns in the uploaded file
          const dateColumnIndex = columns.indexOf(storedDateColumnName);
          const valueColumnIndex = columns.indexOf(storedValueColumnName);

          if (dateColumnIndex !== -1) {
            fields.date_column.onChange(dateColumnIndex);
            fields.date_column_name.onChange(columns[dateColumnIndex]);
          } else {
            fields.date_column.onChange(DEFAULT_DATE_COLUMN); // Default to first column if no match
            fields.date_column_name.onChange('');
          }

          if (valueColumnIndex !== -1) {
            fields.value_column.onChange(valueColumnIndex);
            fields.value_column_name.onChange(columns[valueColumnIndex]);
          } else {
            fields.value_column.onChange(1); // Default to second column if no match
            fields.value_column_name.onChange('');
          }
        }
      }
    };

    reader.onerror = () => {
      console.error('There was an error reading the file');
      // Handle error here if needed
    };

    reader.readAsText(file);
  }, [fileCSV]);

  const onChangeChoice = field => event => {
    if (field.name === 'file_has_header') return field.onChange(!field.value);
    return field.onChange(event.value);
  };

  const onRemoveFile = file => () => {
    const currentFiles = [...fileCSV];
    currentFiles.splice(currentFiles.indexOf(file), 1);
    setFileCSV(currentFiles);

    if (currentFiles.length === 0) {
      fields.file_columns.onChange([]);
      fields.date_column.onChange(DEFAULT_DATE_COLUMN); // Reset to default value 0
      fields.value_column.onChange(DEFAULT_VALUE_COLUMN); // Reset to default value 1
      fields.date_column_name.onChange(DEFAULT_DATE_COLUMN_NAME);
      fields.value_column_name.onChange(DEFAULT_VALUE_COLUMN_NAME);
    }
  };

  const onClickRemoveAllFile = () => {
    setFileCSV([]);

    // Reset the Select fields to default values when all files are removed
    fields.file_columns.onChange([]);
    fields.date_column.onChange(DEFAULT_DATE_COLUMN); // Reset to default value 0
    fields.value_column.onChange(DEFAULT_VALUE_COLUMN); // Reset to default value 1
    fields.date_column_name.onChange(DEFAULT_DATE_COLUMN_NAME);
    fields.value_column_name.onChange(DEFAULT_VALUE_COLUMN_NAME);
  };

  const handleUpload = () => {
    setLoadingRequest(true);
    const cleanedValues = _.omitBy(values, (value, key) => {
      if (key === 'format' && values.type === 'prices') return true; // if type is equal to price, format is not sent
      if (key === 'interpolation' && values.frequency === 'daily') return true; // if frequency is equal to daily, interpolation is not sent
      return false;
    });
    const formData = new FormData();
    formData.append('file', fileCSV[0]);
    Object.entries(cleanedValues).forEach(([key, value]) => {
      formData.append(key, value);
    });

    customSecurityProvider
      .uploadSecurityReturnsFileCSV(security.id, formData)
      .then(response => {
        if (response.error)
          toast.error('An error occurred while uploading the returns information.');
        else {
          if (!securityID) updateStep(POST_CREATE_STEP, RETURNS_FILE_WAS_IMPORTED);
          toast.success('🎊 The security returns was uploaded successfully.');
        }
      })
      .finally(() => {
        const fieldsToSave = [
          'frequency',
          'type',
          'format',
          'interpolation',
          'file_has_header',
          'date_column_name',
          'value_column_name'
        ];
        const savedValues = _.pick(values, fieldsToSave);
        setFileCSV([]);
        localStorage.setItem('securityReturnsFields', JSON.stringify(savedValues));
        setLoadingRequest(false);
      });
  };

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length <= 0) return toast.error('Please add one file in the dropzone.');
      fields.file.onChange([...acceptedFiles]);
      return setFileCSV([...acceptedFiles]);
    },
    [fileCSV]
  );

  return (
    <form autoComplete="off" onSubmit={handleSubmit(handleUpload)}>
      <section id="security-returns-container">
        <div className="risk-score-result-container">
          {loadingRequest ? (
            <SpinnerLoader spinnerLoading />
          ) : (
            <div className="content">
              <p>Upload a CSV file with historical returns or prices of the custom security.</p>
              <div className="returns-wrapper">
                <div className="security-margin-wrapper">
                  <div className="security-returns-btn-container space-btn">
                    <div>
                      <h5>File</h5>
                      <DropzoneComponent
                        accept="text/csv"
                        files={fileCSV}
                        maxFiles={maxFiles}
                        setFiles={setFileCSV}
                        uploadMessage="Click to Upload"
                        onDrop={onDrop}
                      />
                      <div className="security-dropzone-wrapper">
                        <p className="security-dropzone-wrapper__info">
                          <span className="fs-icon-exclamation-circle warning-icon" /> To update
                          your data correctly, please use{' '}
                          <a
                            className="download-csv-link"
                            href={SECURITY_PORTFOLIOS_CSV_TEMPLATE_URL}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            our CSV template
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="security-returns-btn-container space-btn">
                  <SecurityReturnsParams fields={fields} onChangeFields={onChangeChoice} />
                  <div
                    className={cn('security-returns-submit', {
                      'security-returns-submit--create': !securityID
                    })}
                  >
                    {!securityID && (
                      <button
                        type="button"
                        className="btn btn-link security-returns-link__button"
                        onClick={() => updateStep(POST_CREATE_STEP)}
                      >
                        I&apos;ll do this latter
                      </button>
                    )}
                    {!_.isEmpty(fileCSV) && (
                      <button
                        type="button"
                        onClick={() => onClickRemoveAllFile()}
                        className={cn(
                          'btn btn-secondary security-returns-remove__button',
                          'security-btn'
                        )}
                      >
                        Remove
                      </button>
                    )}
                    <button
                      type="submit"
                      className={cn(
                        'btn btn-primary security-returns-submit__button',
                        {
                          'security-returns-submit__button--create': !securityID,
                          'security-returns-submit__button--fill': !!securityID
                        },
                        'security-btn'
                      )}
                      disabled={!_.isEmpty(errors) || _.isEmpty(fileCSV) || loadingRequest}
                    >
                      {securityID ? 'Import' : 'Save'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <Disclosure />
    </form>
  );
};

SecurityReturns.defaultProps = {
  updateStep: null
};

SecurityReturns.propTypes = {
  fields: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initializeForm: PropTypes.func.isRequired,
  security: PropTypes.object.isRequired,
  currentSecurityCreateReturns: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  updateStep: PropTypes.func
};

const mapStateToProps = state => ({
  security: state.customSecurity.currentSecurity,
  currentSecurityCreateReturns: state.customSecurity.currentSecurityCreateReturns,
  fields: [
    'file',
    'frequency',
    'type',
    'format',
    'interpolation',
    'file_has_header',
    'file_columns',
    'date_column',
    'date_column_name',
    'value_column',
    'value_column_name'
  ],
  initialValues: {
    file: '',
    frequency: '',
    type: '',
    format: '',
    interpolation: '',
    file_has_header: false,
    file_columns: [],
    date_column: DEFAULT_DATE_COLUMN,
    date_column_name: DEFAULT_DATE_COLUMN_NAME,
    value_column: DEFAULT_VALUE_COLUMN,
    value_column_name: DEFAULT_VALUE_COLUMN_NAME
  }
});

const SecurityReturnsWithForm = reduxForm({
  form: 'editSecurity',
  enableReinitialize: true,
  validate
})(SecurityReturns);

const SecurityReturnsWithOutRout = withRouter(SecurityReturnsWithForm);

export default connect(mapStateToProps)(SecurityReturnsWithOutRout);
