export const ALLOCATIONS_TYPE = 'allocations-type';
export const GEOGRAPHIC_EXPOSURE_TYPE = 'geographic-exposure-type';
export const INVESTMENT_STYLE_TYPE = 'investment-style-type';
export const SECTOR_EXPOSURE_TYPE = 'sector-exposure-type';
export const TOP_HOLDINGS_TYPE = 'top-holdings-type';

export const ACCOUNT_ENTITY_TYPE = 'account-entity-type';
export const INVESTOR_ENTITY_TYPE = 'investor-entity-type';
export const MODEL_ENTITY_TYPE = 'model-entity-type';
export const PROSPECT_ENTITY_TYPE = 'prospect-entity-type';

export const SECTION_TITLE = {
  [ALLOCATIONS_TYPE]: 'Allocations',
  [GEOGRAPHIC_EXPOSURE_TYPE]: 'Geographic Exposure',
  [INVESTMENT_STYLE_TYPE]: 'Investment Style',
  [SECTOR_EXPOSURE_TYPE]: 'Sector Exposure',
  [TOP_HOLDINGS_TYPE]: 'Top 10 Holdings'
};
