import cn from 'classnames';
import ScoreBubble from 'components/advisor/utils/score-bubble';
import { AllowNewItemsAutosuggest, FormGroup, Input } from 'components/form';
import TrashCanIcon from 'components/svg-icons/trash-can-icon';
import WarningIcon from 'components/svg-icons/warning-icon';
import TooltipV2 from 'components/tooltip-v2';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { NumericFormat } from 'react-number-format';
import { EXCLUDED_MESSAGE } from '../constants';
import SelectClassification from './select-classification';
import './styles.scss';

const PositionsFormsetRow = ({
  activeExclude,
  classifications,
  isFirstPosition,
  isViewOnly,
  marketSecurities,
  position,
  removePositionDisabled,
  visible,
  withExcludePositionsFeature
}) => {
  const {
    authProvider,
    marketProvider,
    user,
    user: {
      advisor: {
        company: { allow_advisors_excluding_positions: allowAdvisorsExcludingPositions }
      }
    }
  } = useContext(AdvisorContext);

  const isComplianceOrAbove = authProvider.hasCompliancePermissionsOrAbove(user);
  const isExcluded = position?.excluded?.value && activeExclude;

  const onChangeTickerHandler = (event, { newValue, method }) => {
    // update input value
    position.ticker.onChange(newValue.toUpperCase());

    // reset target/dependent values
    if (method === 'type') {
      position.ticker_name.onChange(undefined);
      position.type.onChange(undefined);
      position.subtype.onChange(undefined);
      position.sector.onChange(undefined);
      position.security.onChange(undefined);
    }
  };

  const onSuggestionSelected = (sourceSuggestion, position) => {
    const suggestion = marketSecurities
      .filter(item => item.ticker === sourceSuggestion.ticker)
      .pop();

    position.disabled = !!suggestion.ticker_name;

    position.security.onChange(suggestion?.id);
    position.ticker_name.onChange(suggestion?.ticker_name ?? '');
    position.type.onChange(suggestion?.type);
    position.subtype.onChange(suggestion?.subtype);
    position.sector.onChange(suggestion?.sector);
    position.prism_score_summary__overall.onChange(suggestion?.prism_overall);
  };

  const onSuggestionSelectedHandler = (_, { suggestion }) => {
    onSuggestionSelected(suggestion, position);
  };

  const onSuggestionsFetchRequested = useCallback(
    _.debounce(value => marketProvider.es.securitySearch(value.toUpperCase()), 500),
    []
  );

  const onSuggestionsFetchRequestedHandler = ({ value }) => {
    position.disabled = false;
    onSuggestionsFetchRequested(value);
  };

  const renderSuggestionHandler = suggestion => (
    <span>
      {suggestion.prism_overall ? (
        <ScoreBubble score={suggestion.prism_overall} />
      ) : (
        <span className="no-score-warning">
          <WarningIcon className="warning-icon" title="Score not available" />
        </span>
      )}{' '}
      {suggestion.ticker}: {suggestion.ticker_name}
    </span>
  );

  // this component will be returned when the row should not be visible, allowing visual
  // feedback that the required component is not yet available
  if (!visible)
    return (
      <tr className={cn('positions-formset-row', 'loading-skeleton')} data-row-id={position.rowId}>
        <td aria-label="prism">
          <div />
        </td>
        <td aria-label="ticker">
          <div />
        </td>
        <td aria-label="ticker-name">
          <div />
        </td>
        <td aria-label="type">
          <div />
        </td>
        <td aria-label="subtype">
          <div />
        </td>
        <td aria-label="sector">
          <div />
        </td>
        <td aria-label="value">
          <div />
        </td>
        <td aria-label="actions">
          <div />
        </td>
      </tr>
    );

  return (
    <tr
      className={cn('positions-formset-row', { excluded: isExcluded })}
      data-row-id={position.rowId}
    >
      <td style={{ verticalAlign: 'middle' }}>
        {isExcluded && withExcludePositionsFeature && (
          <TooltipV2
            effect="float"
            place="right"
            id="excluded-info"
            label={
              <p style={{ textAlign: 'left', paddingTop: '1rem' }}>
                {EXCLUDED_MESSAGE.TOOLTIP_MESSAGE}
                <br />
                {isComplianceOrAbove
                  ? EXCLUDED_MESSAGE.TOOLTIP_HAS_PERMISSIONS
                  : EXCLUDED_MESSAGE.TOOLTIP_NO_PERMISSIONS}
              </p>
            }
          >
            <div data-tip="" data-for="excluded-info" className="excluded-info-wrapper">
              <div className="excluded-info">
                <span>!</span>
              </div>
            </div>
          </TooltipV2>
        )}
        {position.prism_score_summary__overall.value && (
          <ScoreBubble score={position.prism_score_summary__overall.value} />
        )}
        {position.id.value && !position.prism_score_summary__overall.value && (
          <span className="no-score-warning">
            <WarningIcon className="warning-icon" title="Score not available" />
          </span>
        )}
      </td>

      <td aria-label="ticker">
        <AllowNewItemsAutosuggest
          autofocus={isFirstPosition && !position.pk}
          field={position.ticker}
          getSuggestionValue={suggestion => suggestion.ticker}
          inputProps={{
            disabled: isViewOnly,
            onChange: onChangeTickerHandler,
            placeholder: 'Type Ticker',
            value: position.ticker.value || '' // should always be string
          }}
          onSuggestionSelected={onSuggestionSelectedHandler}
          onSuggestionsFetchRequested={onSuggestionsFetchRequestedHandler}
          renderSuggestion={renderSuggestionHandler}
          suggestions={marketSecurities}
          theme={{
            container: 'dropdown react-autosuggest__container',
            containerOpen: 'open react-autosuggest__container--open',
            input: 'form-control react-autosuggest__input',
            suggestionsContainer:
              'dropdown-menu dropdown-menu-scale react-autosuggest__suggestions-container',
            suggestion: 'dropdown-item react-autosuggest__suggestion',
            suggestionFocused: 'react-autosuggest__suggestion--focused'
          }}
        />
      </td>

      <td>
        <FormGroup {...position.ticker_name}>
          <Input
            {...position.ticker_name}
            className="form-control"
            disabled={isViewOnly || position.disabled}
          />
        </FormGroup>
      </td>

      <td aria-label="type">
        <SelectClassification
          position={position}
          classifications={classifications}
          classificationType="type"
          disabled={isViewOnly}
        />
      </td>

      <td aria-label="subtype">
        <SelectClassification
          position={position}
          classifications={classifications}
          classificationType="subtype"
          disabled={isViewOnly}
        />
      </td>

      <td aria-label="sector">
        <SelectClassification
          position={position}
          classifications={classifications}
          classificationType="sector"
          disabled={isViewOnly}
        />
      </td>

      <td>
        <FormGroup {...position.value}>
          <NumericFormat
            className="form-control"
            name="value"
            thousandSeparator
            allowNegative
            defaultValue={position.value.value}
            onValueChange={value => position.value.onChange(value.value)}
            isNumericString
            disabled={isViewOnly}
          />
        </FormGroup>
        {position.value.touched && position.value.error && (
          <span className="text-danger error">{position.value.error}</span>
        )}
      </td>

      {!isViewOnly && (
        <td className="actions">
          {(isComplianceOrAbove || allowAdvisorsExcludingPositions) &&
            withExcludePositionsFeature && (
              <TooltipV2
                effect="float"
                place="right"
                id="exclude-action"
                label={
                  <p style={{ textAlign: 'left', padding: 10, margin: 0 }}>
                    {isExcluded
                      ? EXCLUDED_MESSAGE.ACTION_INCLUDE_TOOLTIP
                      : EXCLUDED_MESSAGE.ACTION_EXCLUDE_TOOLTIP}
                  </p>
                }
              >
                <div data-tip="" data-for="exclude-action" className="exclude-action-wrapper">
                  <button
                    type="button"
                    className="exclude-action"
                    title=""
                    onClick={() => {
                      position.excluded.onChange(!position.excluded.value);
                    }}
                  >
                    <img
                      width={18}
                      src={`/img/icons/account-${isExcluded ? 'included' : 'excluded'}.svg`}
                      alt={`Account ${isExcluded ? 'Included' : 'Excluded'}`}
                    />
                  </button>
                </div>
              </TooltipV2>
            )}

          <button
            aria-label="Remove"
            className={cn({ disabled: removePositionDisabled })}
            onClick={() => {
              position.deleted.onChange(true);
            }}
            type="button"
          >
            <TrashCanIcon className="trash" />
          </button>
        </td>
      )}
    </tr>
  );
};

PositionsFormsetRow.propTypes = {
  activeExclude: PropTypes.bool.isRequired,
  classifications: PropTypes.object.isRequired,
  isFirstPosition: PropTypes.bool.isRequired,
  isViewOnly: PropTypes.bool.isRequired,
  marketSecurities: PropTypes.array.isRequired,
  position: PropTypes.object.isRequired,
  removePositionDisabled: PropTypes.bool.isRequired,
  visible: PropTypes.bool,
  withExcludePositionsFeature: PropTypes.bool.isRequired
};

PositionsFormsetRow.defaultProps = {
  visible: false
};

export default React.memo(PositionsFormsetRow);
