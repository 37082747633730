import {
  investorAccountsSelector,
  investorSelector,
  isProspectSelector
} from 'components/advisor/investors/selectors';
import ModelPortfolioBenchmark from 'components/advisor/model-portfolio-benchmark';
import AnalysisScores from 'components/advisor/risk-analysis/analysis-scores';
import InvestorPrismReportViewer from 'components/advisor/risk-analysis/report/investor-prism/viewer';
import PositionsAnalysis from 'components/advisor/risk-analysis/risk-analysis-target/positions-analysis';
import { PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID } from 'components/advisor/risk-analysis/risk-analysis-target/positions-analysis/constants';
import SecurityTypeConcentration from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration';
import TopRiskAttribution from 'components/advisor/risk-analysis/risk-analysis-target/top-risk-attribution';
import ScenariosScores from 'components/advisor/risk-analysis/scenarios-scores';
import GeographicExposure from 'components/advisor/risk-analysis/securities/geographic-exposure';
import { GEOGRAPHIC_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/geographic-exposure/utils';
import SectorExposure from 'components/advisor/risk-analysis/securities/sector-exposure';
import { SECTOR_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/sector-exposure/utils';
import {
  ALLOCATIONS_TYPE,
  GEOGRAPHIC_EXPOSURE_TYPE,
  SECTOR_EXPOSURE_TYPE
} from 'components/form/breakdown-custom-securities-toggle-title/constants';
import PrismRating from 'components/utils/prism-rating';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect } from 'react';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import InvestorPropertyOverview from './investor-property-overview';
import {
  combinedPortfoliosSelector,
  investorsGoalsSelector,
  mostRiskyPositionsSelector,
  scenariosSelector,
  totalAssetsSelector
} from './selectors';
import './styles.scss';
import ToggableAccount from './toggable-account';

const InvestorPrism = props => {
  const { investorProvider, modelProvider } = useContext(AdvisorContext);

  const {
    accounts,
    benchmarks,
    combinedPortfolios,
    goals,
    investor,
    mostRiskyPositions,
    portfolioBreakdownCustomSecurities,
    scenarios,
    totalAssets
  } = props;

  const { aggregated_prism_scores: aggrPrism, aggregated_target_scores: aggrTarget } = investor;

  useEffect(() => {
    modelProvider.getCommonBenchmarks();
  }, []);

  const onChartReady = useCallback(
    (id, data) => {
      investorProvider.saveChartImage({ [id]: data });
    },
    [JSON.stringify(investor), JSON.stringify(accounts)]
  );

  const positions = accounts
    .filter(account => !account.excluded)
    .map(account => account.positions)
    .flat();

  return (
    <div className="prism-investor">
      <div className="overview">
        <div className="overview__properties">
          <InvestorPropertyOverview property="Goals" value={goals.length} />
          <InvestorPropertyOverview property="Accounts" value={accounts.length} />
          <InvestorPropertyOverview
            property="Total assets"
            value={<FormattedNumber value={totalAssets} format="currency" />}
          />
        </div>
        <InvestorPrismReportViewer
          accounts={accounts}
          benchmarks={benchmarks}
          investor={investor}
          scenarios={scenarios}
          totalAssets={totalAssets}
        />
      </div>

      {!_.isEmpty(aggrPrism) && (
        <div className="result-container prism-overall">
          <div className="result-heading">
            Portfolio Risk{' '}
            <span>
              Powered by <b>PRISM Rating &trade;</b>
            </span>
          </div>
          <PrismRating prismSummary={aggrPrism} targetSummary={aggrTarget} />
        </div>
      )}

      {!_.isEmpty(scenarios) && !!totalAssets && (
        <div className="scenarios">
          <h2>PRISM Analysis for Market Crisis Scenarios</h2>
          <ScenariosScores
            scenarios={scenarios}
            value={totalAssets}
            performance={investor.aggregated_scenarios}
          />
        </div>
      )}

      {aggrPrism && aggrTarget && (
        <div className="result-container prism-overall">
          <div className="result-heading">Benchmark PRISM Rating &trade;</div>
          <ModelPortfolioBenchmark
            benchmark={benchmarks}
            prismOverall={aggrPrism.overall}
            targetOverall={aggrTarget.overall}
          />
        </div>
      )}

      <div className="risk-factor-analysis">
        <h2>Risk Factor Analysis</h2>
        <AnalysisScores targetScore={aggrTarget} prismScore={aggrPrism} />
      </div>

      {/* Used only to take the image of the chart that is needed for @react-pdf/renderer */}
      <div className="risk-factor-analysis hide-from-report">
        <div className="overview">
          <PositionsAnalysis
            breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
              ALLOCATIONS_TYPE
            )}
            id={PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID}
            onChartReady={onChartReady}
            portfolio={combinedPortfolios}
            className="fill-space"
          />
        </div>
      </div>

      {/* Used only to take the image of the chart that is needed for @react-pdf/renderer */}
      <div className="risk-factor-analysis box-position-analysis hide-from-report">
        <div className="sector-exposure-container">
          <SectorExposure
            breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
              SECTOR_EXPOSURE_TYPE
            )}
            id={SECTOR_EXPOSURE_CHART_ID}
            onChartReady={onChartReady}
            portfolio={combinedPortfolios}
            width="1000px"
          />
        </div>
      </div>

      {/* Used only to take the image of the chart that is needed for @react-pdf/renderer */}
      <div className="risk-factor-analysis box-position-analysis hide-from-report">
        <div className="geographic-exposure-container">
          <GeographicExposure
            breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
              GEOGRAPHIC_EXPOSURE_TYPE
            )}
            id={GEOGRAPHIC_EXPOSURE_CHART_ID}
            onChartReady={onChartReady}
            portfolio={combinedPortfolios}
            width="1000px"
          />
        </div>
      </div>

      {/* Used only to take the image of the chart that is needed for @react-pdf/renderer */}
      {!_.isEmpty(positions) && !!totalAssets && (
        <div
          className="security-type-concentration hide-from-report"
          style={{ width: 1000, height: 200 }}
        >
          <SecurityTypeConcentration
            positions={positions}
            onChartReady={onChartReady}
            totalAssets={totalAssets}
          />
        </div>
      )}

      {!_.isEmpty(mostRiskyPositions) && !!totalAssets && (
        <div className="top-risk-attributions">
          <h2>Top Risk Attributions from each Account</h2>
          <TopRiskAttribution
            investor={investor}
            riskyPositions={mostRiskyPositions}
            riskyPositionsOnly
          />
        </div>
      )}

      <div className="toggable-accounts">
        <h2>Accounts</h2>
        {accounts.map(account => (
          <ToggableAccount
            investor={investor}
            account={account}
            key={`toggable-acc-${account.id}`}
          />
        ))}
      </div>
    </div>
  );
};

InvestorPrism.contextTypes = {
  investorGoalsProvider: PropTypes.object.isRequired,
  investorProvider: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired,
  prospectProvider: PropTypes.object.isRequired
};

InvestorPrism.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object),
  benchmarks: PropTypes.arrayOf(PropTypes.object),
  combinedPortfolios: PropTypes.object.isRequired,
  goals: PropTypes.arrayOf(PropTypes.object),
  investor: PropTypes.object,
  isProspect: PropTypes.bool.isRequired,
  mostRiskyPositions: PropTypes.arrayOf(PropTypes.object),
  params: PropTypes.object.isRequired,
  portfolioBreakdownCustomSecurities: PropTypes.array.isRequired,
  scenarios: PropTypes.arrayOf(PropTypes.object),
  totalAssets: PropTypes.number
};

InvestorPrism.defaultProps = {
  accounts: [],
  benchmarks: [],
  goals: [],
  investor: null,
  mostRiskyPositions: [],
  scenarios: [],
  totalAssets: null
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    params: { id: investorId }
  } = ownProps;
  const { investorsBreakdownCustomSecurities, investorsGoals, prospectsBreakdownCustomSecurities } =
    stateProps;

  const breakdownCustomSecurities = {
    ...prospectsBreakdownCustomSecurities,
    ...investorsBreakdownCustomSecurities
  };
  const portfolioBreakdownCustomSecurities = breakdownCustomSecurities[investorId] || [];

  return {
    ...stateProps,
    ...ownProps,
    goals: investorsGoals[investorId],
    portfolioBreakdownCustomSecurities
  };
};

export default connect(
  state => ({
    accounts: investorAccountsSelector(state),
    benchmarks: state.models.benchmark,
    combinedPortfolios: combinedPortfoliosSelector(state),
    investor: investorSelector(state),
    investorsBreakdownCustomSecurities: state.investors.breakdownCustomSecurities,
    investorsGoals: investorsGoalsSelector(state),
    isProspect: isProspectSelector(state),
    mostRiskyPositions: mostRiskyPositionsSelector(state),
    prospectsBreakdownCustomSecurities: state.prospects.breakdownCustomSecurities,
    scenarios: scenariosSelector(state),
    totalAssets: totalAssetsSelector(state),
    user: state.auth.user
  }),
  null,
  mergeProps
)(InvestorPrism);
