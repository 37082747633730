/* eslint-disable no-underscore-dangle */
import { Text, View } from '@react-pdf/renderer';
import { TOP_HOLDINGS_TYPE } from 'components/form/breakdown-custom-securities-toggle-title/constants';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { Fragment, useContext } from 'react';
import BaseSection from 'reports/base/sections/base';
import { getTaxonomyLevelIterator } from 'utils/utils';
import Row from '../../common/pdf/row';
import styles from '../../common/pdf/styles';
import TotalRow from '../../common/pdf/total-row';
import {
  SECURITY_UNDERLYING_MODEL,
  getSecurityDetails,
  hasSecurityDetails,
  processSecurityDetails
} from '../../common/utils';
import { TOP_HOLDINGS_TO_SHOW } from '../constants';

const TopHoldings = ({
  body,
  breakSection,
  headingLabels,
  hiddenVal,
  portfolio,
  title,
  totalRowLabel
}) => {
  const {
    portfolioBreakdownCustomSecurities,
    user: {
      advisor: {
        company: {
          expand_asset_details: expandAssetDetails,
          top_holdings_enabled: topHoldingsEnabled
        }
      }
    }
  } = useContext(AdvisorContext);

  const breakdownCustomSecurities = portfolioBreakdownCustomSecurities.includes(TOP_HOLDINGS_TYPE);

  if (
    !topHoldingsEnabled ||
    !hasSecurityDetails(portfolio, SECURITY_UNDERLYING_MODEL, breakdownCustomSecurities)
  )
    return null;

  const totalAssets = portfolio.value ?? 0;
  const securityTopHoldings = getSecurityDetails(
    portfolio,
    SECURITY_UNDERLYING_MODEL,
    totalAssets,
    breakdownCustomSecurities
  );

  const filteredSecurityTopHoldings = securityTopHoldings
    .sort((a, b) => b.value - a.value)
    .slice(0, TOP_HOLDINGS_TO_SHOW);

  const { data } = processSecurityDetails(
    filteredSecurityTopHoldings,
    SECURITY_UNDERLYING_MODEL,
    totalAssets,
    null,
    true
  );

  const rowProps = { hiddenVal, total: totalAssets };

  return (
    <BaseSection title={title} body={body} breakSection={breakSection}>
      <View style={[styles.table, { marginTop: 0, maxHeight: 30 }]} wrap={false}>
        <View style={[styles.row, styles.rowTotal]}>
          <View style={styles.cell}>
            <Text>{headingLabels.name}</Text>
          </View>
          <View style={styles.cell}>
            {hiddenVal || <Text style={styles.cellText}>{headingLabels.value}</Text>}
          </View>
          <View style={[styles.cell, { marginRight: 0 }]}>
            <Text style={styles.cellText}>{headingLabels.percent}</Text>
          </View>
        </View>
      </View>

      <View style={[styles.table, { marginTop: 0 }]}>
        {getTaxonomyLevelIterator(data.summary).map(([l1Key, l1], l1Idx) => (
          <Fragment key={`l1-${l1.__id ?? l1Idx}`}>
            <Row depth={1} label={l1Key} level={l1} color={l1.__color} {...rowProps} />

            {expandAssetDetails &&
              getTaxonomyLevelIterator(l1).map(([l2Key, l2], l2Idx) => (
                <Fragment key={`l2-${l2.__id ?? `${l1Idx}-${l2Idx}`}`}>
                  <Row depth={2} label={l2Key} level={l2} color={l1.__color} {...rowProps} />
                </Fragment>
              ))}
          </Fragment>
        ))}
      </View>

      <TotalRow
        hiddenVal={hiddenVal}
        styles={styles}
        totalRowLabel={totalRowLabel}
        totalValue={data.summary.__value}
        totalValuePercentage={data.summary.__value_percentage ?? 1}
      />
    </BaseSection>
  );
};

TopHoldings.propTypes = {
  body: PropTypes.string,
  breakSection: PropTypes.bool,
  headingLabels: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    percent: PropTypes.string.isRequired
  }),
  hiddenVal: PropTypes.bool,
  portfolio: PropTypes.array.isRequired,
  title: PropTypes.string,
  totalRowLabel: PropTypes.string
};

TopHoldings.defaultProps = {
  body: '',
  breakSection: false,
  headingLabels: { name: '', value: 'Value', percent: '% of Portfolio' },
  hiddenVal: false,
  title: 'Top 10 Holdings',
  totalRowLabel: 'Total Holdings'
};

export default TopHoldings;
