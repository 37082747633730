import { AdvisorContext } from 'containers/advisor';
import NewTag from 'containers/advisor/new-tag';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import Toggle from 'react-toggle';
import {
  ACCOUNT_ENTITY_TYPE,
  INVESTOR_ENTITY_TYPE,
  MODEL_ENTITY_TYPE,
  PROSPECT_ENTITY_TYPE,
  SECTION_TITLE
} from './constants';
import './styles.scss';
import { hasCustomSecurities } from './utils';

const BreakdownCustomSecuritiesToggleTitle = ({
  isCustomSecurityBreakdownActive,
  isNewFeature,
  portfolio,
  portfolioBreakdownCustomSecurities,
  portfolioType,
  sectionType,
  title
}) => {
  const { accountProvider, investorProvider, prospectProvider, modelProvider } =
    useContext(AdvisorContext);

  const providers = {
    [ACCOUNT_ENTITY_TYPE]: accountProvider,
    [INVESTOR_ENTITY_TYPE]: investorProvider,
    [MODEL_ENTITY_TYPE]: modelProvider,
    [PROSPECT_ENTITY_TYPE]: prospectProvider
  };

  const onToggleHandler = () => {
    const provider = providers[portfolioType];
    const data = [...portfolioBreakdownCustomSecurities];
    return provider.setBreakdownCustomSecurities(
      portfolio.id,
      isCustomSecurityBreakdownActive
        ? data.filter(type => type !== sectionType)
        : [...data, sectionType]
    );
  };

  return (
    <div className="cbstt">
      <div className="cbstt__title">
        <span>{title || SECTION_TITLE[sectionType]}</span> {isNewFeature && <NewTag />}
      </div>
      {hasCustomSecurities(portfolio) && (
        <div className="cbstt__toggle">
          <Toggle
            checked={isCustomSecurityBreakdownActive}
            className="toggle-primary"
            onChange={onToggleHandler}
          />
          <span>Breakdown custom securities</span>
        </div>
      )}
    </div>
  );
};

BreakdownCustomSecuritiesToggleTitle.defaultProps = {
  isCustomSecurityBreakdownActive: false,
  isNewFeature: false,
  portfolioBreakdownCustomSecurities: [],
  title: ''
};

BreakdownCustomSecuritiesToggleTitle.propTypes = {
  isCustomSecurityBreakdownActive: PropTypes.bool,
  isNewFeature: PropTypes.bool,
  portfolio: PropTypes.object.isRequired,
  portfolioBreakdownCustomSecurities: PropTypes.array,
  portfolioType: PropTypes.string.isRequired,
  sectionType: PropTypes.string.isRequired,
  title: PropTypes.string
};

export default connect(
  state => ({
    accountsBreakdownCustomSecurities: state.accounts.breakdownCustomSecurities,
    investorsBreakdownCustomSecurities: state.investors.breakdownCustomSecurities,
    modelsBreakdownCustomSecurities: state.models.breakdownCustomSecurities,
    prospectsBreakdownCustomSecurities: state.prospects.breakdownCustomSecurities
  }),
  null,
  (stateProps, dispatchProps, ownProps) => {
    const { portfolio, portfolioType, sectionType } = ownProps;
    const {
      accountsBreakdownCustomSecurities,
      investorsBreakdownCustomSecurities,
      modelsBreakdownCustomSecurities,
      prospectsBreakdownCustomSecurities
    } = stateProps;

    const getBreakdownCustomSecurities = portfolioType => {
      if (portfolioType === ACCOUNT_ENTITY_TYPE) return accountsBreakdownCustomSecurities;
      if (portfolioType === MODEL_ENTITY_TYPE) return modelsBreakdownCustomSecurities;
      if (portfolioType === PROSPECT_ENTITY_TYPE) return prospectsBreakdownCustomSecurities;
      return investorsBreakdownCustomSecurities;
    };
    const breakdownCustomSecurities = getBreakdownCustomSecurities(portfolioType);
    const portfolioBreakdownCustomSecurities = breakdownCustomSecurities[portfolio.id] || [];

    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      isCustomSecurityBreakdownActive: portfolioBreakdownCustomSecurities.includes(sectionType),
      portfolioBreakdownCustomSecurities
    };
  }
)(BreakdownCustomSecuritiesToggleTitle);
