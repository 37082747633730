import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const Choice = ({ checked, className, disabled, small, title, toggle }) => {
  const randomHash = Math.random().toString(36).substring(2);

  return (
    <div className={cn('asset-classes-choice', className, { small })}>
      <label htmlFor={`checkbox-${randomHash}`} className="c-input c-checkbox">
        <input
          checked={checked}
          disabled={disabled}
          id={`checkbox-${randomHash}`}
          onChange={toggle}
          type="checkbox"
          value={checked}
        />
        <span className={cn('c-indicator', 'icon-checkmark', { disabled })} />
        <span className="asset-classes-choice-title" dangerouslySetInnerHTML={{ __html: title }} />
      </label>
    </div>
  );
};

Choice.defaultProps = {
  checked: false,
  className: '',
  disabled: false,
  placeholder: '',
  small: false,
  title: '',
  toggle: () => {}
};

Choice.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  small: PropTypes.bool,
  title: PropTypes.string,
  toggle: PropTypes.func
};

export default Choice;
