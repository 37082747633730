import React from 'react';
import './styles.scss';

const ScanFileSteps = () => (
  <div className="scan-file-steps">
    <h2 className="steps-title">How it works?</h2>
    <div className="step">
      <div className="step-number">1</div>
      <div className="step-content">
        <h4>Upload files</h4>
        <p>Select the files you want to scan for financial information.</p>
      </div>
    </div>
    <div className="step">
      <div className="step-number">2</div>
      <div className="step-content">
        <h4>Wait for scanning</h4>
        <p>The platform analyzes the file and extracts the relevant information.</p>
      </div>
    </div>
    <div className="step">
      <div className="step-number">3</div>
      <div className="step-content">
        <h4>Get the results</h4>
        <p>The extracted financial information is displayed.</p>
      </div>
    </div>
    <div className="step">
      <div className="step-number">4</div>
      <div className="step-content">
        <h4>Decide what to do next</h4>
        <p>Review the results and use them to create accounts or build model portfolios.</p>
      </div>
    </div>
  </div>
);

export default ScanFileSteps;
