import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney, formatPercentage } from 'utils/utils';
import PieIcon from 'assets/img/icons/index/allocation_index.svg';
import TableIcon from 'assets/img/icons/index/investment_style.svg';
import BarsIcon from 'assets/img/icons/index/sector_exposure.svg';
import DotsIcon from 'assets/img/icons/index/top_holdings.svg';
import BankIcon from 'assets/img/icons/index/account_balance.svg';

export const getIconComponent = iconName => {
  switch (iconName) {
    case 'pie':
      return <img src={PieIcon} alt="Pie Icon" className="icon" />;
    case 'table':
      return <img src={TableIcon} alt="Table Icon" className="icon" />;
    case 'bars':
      return <img src={BarsIcon} alt="Bars Icon" className="icon" />;
    case 'dots':
      return <img src={DotsIcon} alt="Dots Icon" className="icon" />;
    case 'bank':
      return <img src={BankIcon} alt="Bank Icon" className="icon" />;
    default:
      return null;
  }
};

export const renderFormattedValue = (key, value, customFormatting) => {
  const formatType = customFormatting[key]?.format;
  const align = customFormatting[key]?.align || 'center'; // Default alignment
  const isNotCorrectValue = value === undefined || value === null;

  let formattedValue = isNotCorrectValue ? '-' : value;
  if (formatType === 'money' && !isNotCorrectValue) formattedValue = `${formatMoney(value)}`;
  else if (formatType === 'percentage' && !isNotCorrectValue)
    formattedValue = `${formatPercentage(value)}`;

  return { formattedValue, align };
};

export const ExpandCollapseButton = ({ isExpanded, setIsExpanded, hasTenOrMoreRows }) => {
  if (!hasTenOrMoreRows) return null;

  return (
    <button
      type="button"
      className="btn btn-transparent"
      onClick={() => setIsExpanded(!isExpanded)}
    >
      {isExpanded ? (
        <p>
          View Less <span className="fs-icon-angle-up" />
        </p>
      ) : (
        <p>
          Show more <span className="fs-icon-angle-down" />
        </p>
      )}
    </button>
  );
};

ExpandCollapseButton.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  setIsExpanded: PropTypes.func.isRequired,
  hasTenOrMoreRows: PropTypes.bool.isRequired
};
