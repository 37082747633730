import CreatePortfolioModalBody from 'components/advisor/models/create/body';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import { DEFINING_FIELDS_STEP } from 'components/utils/csv-wizard/constants';
import _ from 'lodash';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { CSVModelMixin } from 'utils/mixins';
import { LOAD_CSV_CONFIG, normalizeCsv } from 'utils/papa_parse_config';
import { POST_CREATE_STEP, SECURITY_TARGET_TYPE } from '../../../constants';
import UnderlyingHoldingsEditModal, { modes } from '../edit';
import './styles.scss';

const INVALID_POSITIONS = 'No valid positions';

class UnderlyingHoldingForm extends PureComponent {
  constructor(props) {
    super(props);
    CSVModelMixin.applyMixin(this);

    this.state = {
      modalMode: null,

      modelEditModalShown: false,
      wizardCSV: null
    };
  }

  componentDidUpdate(prevProps) {
    const { modelEditModalShown } = this.state;
    const { modelInEdition } = this.props;
    const { modelInEdition: prevModelInEdition } = prevProps;
    if (prevModelInEdition !== modelInEdition) {
      if (!modelEditModalShown) this.setState({ modelEditModalShown: true });
      if (!modelInEdition && !!prevModelInEdition) this.setState({ modelEditModalShown: false });
    }
  }

  openModelInMode = model => {
    const { modelProvider } = this.context;
    modelProvider.edit(model);
    this.setState({ modalMode: 'create' });
  };

  skipUploadUnderlying = () => {
    const { updateStep } = this.props;
    updateStep(POST_CREATE_STEP);
  };

  hideModal = () => {
    this.setState({ modelEditModalShown: false });
  };

  uploadCSVOption = event => {
    this.registerCSVLoadedCallback(this.uploadCSVCallback);
    this.onCSVInputChange(event);
    this.openModelInMode({}, modes.CREATE);
  };

  uploadCSVCallback = event => {
    const { errorsProvider } = this.context;
    const result = normalizeCsv(event.target.result);
    const { data, errors } = Papa.parse(result, LOAD_CSV_CONFIG);
    if (data.length)
      this.setState({
        wizardCSV: {
          data,
          headerRows: Object.keys(data[0]),
          show: true,
          step: DEFINING_FIELDS_STEP
        }
      });
    if (errors && errors.length)
      errors.forEach(event => errorsProvider.registerError(event.message));
  };

  calculateRisk = id => {
    const { modelProvider } = this.context;

    toast.info(() => <div>Calculating PRISM Score.</div>, { autoClose: 3000 });

    modelProvider
      .updatePrism(id)
      .then(response => {
        if (response.message === INVALID_POSITIONS)
          throw new Error('Please add prospective value to see the PRISM rating.');
      })
      .catch((error = 'Error calculating the PRISM rating.') => {
        toast.error(error);
      });
  };

  render() {
    const { modelProvider } = this.context;

    const {
      marketStore,
      meta,
      modelInEdition,
      securitiesPositions,
      security,
      currentSecurityUnderlyingHoldings,
      isCreatePage,
      isLoading,
      updateStep
    } = this.props;

    const { modalMode, modelEditModalShown, wizardCSV } = this.state;

    return (
      <div className="security-underlying--container">
        <div className="security-underlying--wrapper">
          <Modal
            id="UnderlyingHoldingsEditModal"
            className="modal-lg"
            show={modelEditModalShown}
            onHidden={() => {
              this.setState({ modelEditModalShown: false, wizardCSV: null });
              if (isLoading) isLoading();
              modelProvider.edit();
            }}
            onShown={() => this.setState({ modelEditModalShown: true })}
            ref={c => {
              this.editModal = c;
            }}
          >
            <ModalHeader />
            <ModalBody>
              <UnderlyingHoldingsEditModal
                isCreatePage={isCreatePage}
                calculateRisk={this.calculateRisk}
                marketStore={marketStore}
                hideEditModel={this.hideModal}
                meta={meta}
                mode={modalMode}
                model={modelInEdition}
                onCSVInputChange={this.onCSVInputChange}
                positions={securitiesPositions}
                registerCSVLoadedCallback={this.registerCSVLoadedCallback}
                security={security}
                wizardCSV={wizardCSV}
                updateStep={updateStep}
              />
            </ModalBody>
          </Modal>

          {_.isEmpty(currentSecurityUnderlyingHoldings) && (
            <div className="card upload-csv-manual--wrapper">
              <div id="create-portfolio-modal">
                <CreatePortfolioModalBody
                  uploadCSVOption={this.uploadCSVOption}
                  modelCSVId="model-csv-1"
                  showCSVUploadModal={this.openModelInMode}
                  showManualUploadModal={this.openModelInMode}
                  type={SECURITY_TARGET_TYPE}
                />
              </div>
            </div>
          )}

          <div className="security-underlying__footer">
            <div className="right-container right-container__btn-link">
              {isCreatePage && (
                <button
                  className="btn btn-link create-button underlying__btn-link"
                  onClick={this.skipUploadUnderlying}
                  type="button"
                >
                  I&apos;ll do this latter
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UnderlyingHoldingForm.contextTypes = {
  modelProvider: PropTypes.object.isRequired
};

UnderlyingHoldingForm.propTypes = {
  currentSecurityUnderlyingHoldings: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  marketStore: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  modelInEdition: PropTypes.object,
  models: PropTypes.array.isRequired,
  isCreatePage: PropTypes.bool,
  isLoading: PropTypes.func,
  updateStep: PropTypes.func,
  security: PropTypes.object.isRequired,
  securitiesPositions: PropTypes.array
};

UnderlyingHoldingForm.defaultProps = {
  modelInEdition: null,
  isCreatePage: false,
  isLoading: null,
  updateStep: null,
  securitiesPositions: []
};

export default connect(state => ({
  currentSecurityUnderlyingHoldings: state.customSecurity.currentSecurityUnderlyingHoldings,
  marketStore: state.market,
  meta: state.models.listMeta,
  modelInEdition: state.models.edit,
  models: state.models.list,
  security: state.customSecurity.currentSecurity,
  securitiesPositions: state.market.securities.positions
}))(UnderlyingHoldingForm);
