export const SCAN_IQ_ID = 'scan-iq';
export const TAX_IQ_ID = 'tax-iq';
export const RESEARCH_IQ_ID = 'research-iq';

export const UPDATE_SCAN = 'scan_iq_update';
export const EXTRACTED_FILE = 'scan_iq_extracted';
export const SCAN_COMPLETED = 'scan_iq_completed';
export const SCAN_FAILED = 'scan_iq_failed';

export const ACCOUNT_SUMMARY = 'account_summary';
export const ASSET_ALLOCATION = 'asset_allocation';
export const POSITIONS_BY_ACCOUNT = 'positions_by_account';

export const ASSETS_ALLOCATION_TITLE = 'Assets Allocation';
export const ACCOUNT_SUMMARY_TITLE = 'Account Summary';
export const POSITIONS_SUMMARY_TITLE = 'Positions Summary';

export const SCAN_FILE_LIMIT_SIZE = 'Image or PDF, max file size 10 MB';

export const SCAN_IQ_SCOPE = 'scan-iq-scope';
export const DEFAULT_SCOPE = 'default-scope';
