import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'components/dropzone';
import CheckFileIcon from 'assets/img/icons/dropzone/file-check-alt.svg';
import UploadFileIcon from 'assets/img/icons/dropzone/file-arrow-up-alt.svg';
import MinusIcon from 'components/svg-icons/minus-icon';
import _ from 'lodash';
import './styles.scss';

const DropzoneComponent = ({
  files,
  setFiles,
  accept,
  maxFiles,
  uploadMessage,
  fileFormatMessage,
  onDrop,
  isFileSizeExceeded
}) => {
  const onRemoveFile = file => () => {
    const currentFiles = [...files];
    currentFiles.splice(currentFiles.indexOf(file), 1);
    setFiles(currentFiles);
  };

  const dropzoneFiles = files.map(file => {
    const [fileName, fileExtension] = file.name.split(/\.(?=[^.]+$)/);
    const fileSize =
      file.size >= 1024 * 1024
        ? `${(file.size / (1024 * 1024)).toFixed(2)} MB`
        : `${(file.size / 1024).toFixed(2)} KB`;

    return (
      <div key={file.path} className="dropzone__file">
        <div className="dropzone__file__text-container">
          <span>{fileName}</span>
          <div className="minus-icon">
            <MinusIcon
              onClick={onRemoveFile(file)}
              style={{ height: 20, cursor: 'pointer' }}
              title="Remove file"
            />
          </div>
        </div>
        <p>{`${fileExtension.toUpperCase()} · ${fileSize}`}</p>
        {isFileSizeExceeded && <span className="dropzone-error">Max file zise: 10MB</span>}
      </div>
    );
  });

  const hasFiles = !_.isEmpty(dropzoneFiles);

  return (
    <div className="dropzone-component">
      <div className="dropzone" style={{ cursor: hasFiles ? 'auto' : 'pointer' }}>
        {hasFiles ? (
          <div className="dropzone__preview">
            <img className="dropzone-icon" src={CheckFileIcon} alt="upload-file-icon" />
            {dropzoneFiles}
          </div>
        ) : (
          <Dropzone onDrop={onDrop} accept={accept} maxFiles={maxFiles}>
            <div className="dorpzone-upload-area">
              <img className="dropzone-icon" src={UploadFileIcon} alt="upload-file-icon" />
              <div id="signature-dropzone-area" className="dropzone-area empty">
                {uploadMessage} <small>Or drag & drop the file here</small>
                <p>{fileFormatMessage}</p>
              </div>
            </div>
          </Dropzone>
        )}
      </div>
    </div>
  );
};

DropzoneComponent.propTypes = {
  files: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired,
  accept: PropTypes.string,
  maxFiles: PropTypes.number,
  uploadMessage: PropTypes.string,
  fileFormatMessage: PropTypes.string,
  isFileSizeExceeded: PropTypes.bool,
  onDrop: PropTypes.func.isRequired
};

DropzoneComponent.defaultProps = {
  accept: '',
  isFileSizeExceeded: false,
  maxFiles: null,
  uploadMessage: 'Click to Upload',
  fileFormatMessage: ''
};

export default DropzoneComponent;
