export const GEOGRAPHIC_EXPOSURE_CHART_ID = 'geographicExposureChart';

export const UNITED_STATES = 'United States';
export const CANADA = 'Canada';
export const LATIN_AMERICA = 'Latin America';
export const AFRICA = 'Africa';
export const EUROZONE = 'Eurozone';
export const EUROPE_EX_EURO = 'Europe - ex Euro';
export const EUROPE_EMERGING = 'Europe - Emerging';
export const UNITED_KINGDOM = 'United Kingdom';
export const ASIA_DEVELOPED = 'Asia - Developed';
export const ASIA_EMERGING = 'Asia - Emerging';
export const MIDDLE_EAST = 'Middle East';
export const JAPAN = 'Japan';
export const AUSTRALASIA = 'Australasia';

export const REGIONS_LIST = [
  UNITED_STATES,
  CANADA,
  LATIN_AMERICA,
  AFRICA,
  EUROZONE,
  EUROPE_EX_EURO,
  EUROPE_EMERGING,
  UNITED_KINGDOM,
  ASIA_DEVELOPED,
  ASIA_EMERGING,
  MIDDLE_EAST,
  JAPAN,
  AUSTRALASIA
];

export const REGION_MAPPING = {
  [UNITED_STATES]: ['US'],
  [CANADA]: ['CA'],
  [LATIN_AMERICA]: [
    'AR',
    'BR',
    'CL',
    'CO',
    'MX',
    'PE',
    'VE',
    'UY',
    'EC',
    'BO',
    'PY',
    'CR',
    'SV',
    'GT',
    'HN',
    'NI',
    'PA',
    'CU',
    'DO',
    'HT',
    'JM',
    'TT',
    'BB',
    'GF',
    'GY',
    'SR'
  ],
  [AFRICA]: [
    'DZ',
    'AO',
    'BJ',
    'BW',
    'BF',
    'BI',
    'CV',
    'CM',
    'EH',
    'CF',
    'TD',
    'KM',
    'CD',
    'CG',
    'DJ',
    'EG',
    'GQ',
    'ER',
    'SZ',
    'ET',
    'GA',
    'GM',
    'GH',
    'GN',
    'GW',
    'CI',
    'KE',
    'LS',
    'LR',
    'LY',
    'MG',
    'MW',
    'ML',
    'MR',
    'MU',
    'MA',
    'MZ',
    'NA',
    'NE',
    'NG',
    'RW',
    'ST',
    'SN',
    'SC',
    'SL',
    'SO',
    'ZA',
    'SS',
    'SD',
    'TZ',
    'TG',
    'TN',
    'UG',
    'ZM',
    'ZW'
  ],
  [EUROZONE]: [
    'AT',
    'BE',
    'CY',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PT',
    'SK',
    'SI',
    'ES'
  ],
  [EUROPE_EX_EURO]: ['DK', 'IS', 'NO', 'SE', 'CH'],
  [EUROPE_EMERGING]: [
    'AL',
    'AM',
    'AZ',
    'BY',
    'BA',
    'BG',
    'HR',
    'CZ',
    'GE',
    'HU',
    'KZ',
    'XK',
    'KG',
    'MD',
    'ME',
    'MK',
    'PL',
    'RO',
    'RU',
    'RS',
    'TJ',
    'TM',
    'UA',
    'UZ',
    'TR'
  ],
  [UNITED_KINGDOM]: ['GB'],
  [ASIA_DEVELOPED]: ['HK', 'IL', 'JP', 'SG', 'KR', 'TW'],
  [ASIA_EMERGING]: [
    'BD',
    'BT',
    'BN',
    'KH',
    'CN',
    'IN',
    'ID',
    'LA',
    'MY',
    'MN',
    'MM',
    'NP',
    'PK',
    'PH',
    'LK',
    'TH',
    'TL',
    'VN'
  ],
  [MIDDLE_EAST]: [
    'BH',
    'IR',
    'IQ',
    'JO',
    'KW',
    'LB',
    'OM',
    'QA',
    'SA',
    'SY',
    'AE',
    'YE',
    'IL',
    'AF'
  ],
  [JAPAN]: ['JP'],
  [AUSTRALASIA]: [
    'AU',
    'NZ',
    'PG',
    'FJ',
    'SB',
    'VU',
    'NC',
    'WS',
    'TO',
    'TV',
    'KI',
    'NR',
    'PW',
    'FM',
    'MH'
  ]
};

export const PDF_IMAGE_STYLES = { width: '100%', maxHeight: '250px', objectFit: 'scale-down' };

export const REGION_STYLE_MAPPING = {
  [UNITED_STATES]: { background: '#0d9488', align: 'left' },
  [CANADA]: { background: '#134e4a', align: 'left' },
  [LATIN_AMERICA]: { background: '#16a34a', align: 'left' },
  [AFRICA]: { background: '#fb923c', align: 'left' },
  [EUROZONE]: { background: '#075985', align: 'left' },
  [EUROPE_EX_EURO]: { background: '#1e40af', align: 'left' },
  [EUROPE_EMERGING]: { background: '#0ea5e9', align: 'right' },
  [UNITED_KINGDOM]: { background: '#2563eb', align: 'left' },
  [ASIA_DEVELOPED]: { background: '#be123c', align: 'right' },
  [ASIA_EMERGING]: { background: '#f43f5e', align: 'right' },
  [MIDDLE_EAST]: { background: '#e879f9', align: 'right' },
  [JAPAN]: { background: '#e11d48', align: 'right' },
  [AUSTRALASIA]: { background: '#4a044e', align: 'right' }
};

export const COUNTRY_COLORS = Object.keys(REGION_MAPPING).reduce((acc, region) => {
  REGION_MAPPING[region].forEach(country => {
    acc[country] = REGION_STYLE_MAPPING[region].background;
  });
  return acc;
}, {});

export const COUNTRY_REGION_MAPPING = Object.keys(REGION_MAPPING).reduce((acc, region) => {
  REGION_MAPPING[region].forEach(country => {
    acc[country] = region;
  });
  return acc;
}, {});
