import FeatureLimitReachedModal from 'components/advisor/feature-limit';
import CombinePortfoliosModal from 'components/advisor/models/combine';
import CreatePortfolioModal from 'components/advisor/models/create';
import ModelEdit, { modes } from 'components/advisor/models/edit';
import ModelList from 'components/advisor/models/model-list';
import { calculateRisk } from 'components/advisor/models/utils';
import Disclosure from 'components/disclosure';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import { DEFINING_FIELDS_STEP } from 'components/utils/csv-wizard/constants';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { modelsLimitReachedSelector } from 'selectors/limits';
import { modelsWithPrismScoresInProgressSelector } from 'selectors/models';
import { modelPortfoliosWithScoredPrismIntentsSelector } from 'selectors/prism';
import { CSVModelMixin } from 'utils/mixins';
import { LOAD_CSV_CONFIG, normalizeCsv } from 'utils/papa_parse_config';
import BulkUpdatePortfoliosModal from './bulk-update-portfolios';
import './styles.scss';

class AdvisorModels extends PureComponent {
  constructor(props) {
    super(props);
    CSVModelMixin.applyMixin(this);

    this.state = {
      bulkUpdateModalShown: false,
      combineModelsModalShown: false,
      modalMode: null,
      modelCreateModalShown: false,
      modelEditModalShown: false,
      modelsLimitModalShown: false,
      showHelpTips: false,
      wizardCSV: null
    };
  }

  componentDidUpdate(prevProps) {
    const { modelEditModalShown } = this.state;
    const { modelInEdition } = this.props;
    const { modelInEdition: prevModelInEdition } = prevProps;

    if (prevModelInEdition !== modelInEdition) {
      if (!modelEditModalShown) this.setState({ modelEditModalShown: true });
      if (!modelInEdition && !!prevModelInEdition) this.setState({ modelEditModalShown: false });
    }
  }

  openModelInMode = (model, mode) => {
    const { modelProvider } = this.context;
    modelProvider.edit(model);
    this.setState({ modalMode: mode });
  };

  showBulkUpdateModal = () => {
    this.setState({ bulkUpdateModalShown: true });
  };

  hideBulkUpdateModal = () => {
    this.setState({ bulkUpdateModalShown: false });
  };

  showModelCreateModal = () => {
    const { modelsLimitReached } = this.props;
    if (modelsLimitReached) this.setLimitReached();
    else this.setState({ modelCreateModalShown: true });
  };

  hideModelCreateModal = () => {
    this.setState({ modelCreateModalShown: false });
  };

  showCombineModelsModal = () => {
    const { modelsLimitReached } = this.props;
    if (modelsLimitReached) this.setLimitReached();
    else this.setState({ combineModelsModalShown: true });
  };

  hideCombineModelsModal = () => {
    this.setState({ combineModelsModalShown: false });
  };

  calculateRisk = (id, name) => {
    const { modelProvider } = this.context;
    const { meta } = this.props;
    return calculateRisk(id, name, modelProvider, meta);
  };

  onCreateCombinedModel = portfolio => this.calculateRisk(portfolio.id, portfolio.name);

  setLimitReached = () => {
    const { modelEditModalShown, modelCreateModalShown, combineModelsModalShown } = this.state;
    if (modelEditModalShown && this.editModal) this.editModal.hide(); // the state of this modal is a mess
    if (modelCreateModalShown) this.setState({ modelCreateModalShown: false });
    if (combineModelsModalShown) this.setState({ combineModelsModalShown: false });
    this.setState({ modelsLimitModalShown: true });
  };

  unsetLimitReached = () => {
    this.setState({ modelsLimitModalShown: false });
  };

  uploadCSVOption = event => {
    this.hideModelCreateModal();
    this.registerCSVLoadedCallback(this.uploadCSVCallback);
    this.onCSVInputChange(event);
    this.openModelInMode({}, modes.CREATE);
  };

  uploadCSVCallback = event => {
    const { errorsProvider } = this.context;
    const result = normalizeCsv(event.target.result);
    const { data, errors } = Papa.parse(result, LOAD_CSV_CONFIG);

    if (data.length)
      this.setState({
        wizardCSV: {
          data,
          headerRows: Object.keys(data[0]),
          show: true,
          step: DEFINING_FIELDS_STEP
        }
      });
    if (errors && errors.length)
      errors.forEach(event => errorsProvider.registerError(event.message));
  };

  getInitialParams = () => {
    const { advisorByManager, selectedTeam } = this.props;
    const initialParams = { is_benchmark: false };
    if (advisorByManager !== 0) initialParams.advisor = advisorByManager;
    if (selectedTeam !== 0) initialParams.team = selectedTeam;
    return initialParams;
  };

  render() {
    const { modelProvider, user } = this.context;

    const {
      marketStore,
      meta,
      modelInEdition,
      models,
      prismScoresInProgress,
      scoredPrismIntents,
      securitiesPositions,
      selectedModelIds,
      selectedModels
    } = this.props;

    const {
      bulkUpdateModalShown,
      combineModelsModalShown,
      modalMode,
      modelCreateModalShown,
      modelEditModalShown,
      modelsLimitModalShown,
      showHelpTips,
      wizardCSV
    } = this.state;

    const initialParams = this.getInitialParams();

    return (
      <div className="models-container">
        <div className="models-wrapper">
          <div className="header">
            <div className="title-container">
              <h2>Model Portfolios</h2>
            </div>
            <div className="right-container">
              <button
                className="btn btn-primary create-button"
                onClick={this.showModelCreateModal}
                type="button"
              >
                Create a Model
              </button>
            </div>
          </div>

          <ModelList
            bulkUpdate={this.showBulkUpdateModal}
            calculateRisk={this.calculateRisk}
            combineModels={this.showCombineModelsModal}
            data={models}
            editModel={model => this.openModelInMode(model, modes.UPDATE)}
            initialParams={initialParams}
            meta={meta}
            prismScoresInProgress={prismScoresInProgress}
            scoredPrismIntents={scoredPrismIntents}
            selectedModelIds={selectedModelIds}
            selectedModels={selectedModels}
            showHelpTips={showHelpTips}
            user={user}
          />

          <Modal
            id="modelEditModal"
            className="modal-lg"
            show={modelEditModalShown}
            onHidden={() => {
              this.setState({ modelEditModalShown: false, wizardCSV: null });
              modelProvider.edit();
            }}
            onShown={() => this.setState({ modelEditModalShown: true })}
            ref={c => {
              this.editModal = c;
            }}
          >
            <ModalHeader />
            <ModalBody>
              <ModelEdit
                calculateRisk={this.calculateRisk}
                marketStore={marketStore}
                meta={meta}
                mode={modalMode}
                model={modelInEdition}
                onCSVInputChange={this.onCSVInputChange}
                onLimitReached={this.setLimitReached}
                positions={securitiesPositions}
                registerCSVLoadedCallback={this.registerCSVLoadedCallback}
                wizardCSV={wizardCSV}
              />
            </ModalBody>
          </Modal>

          <FeatureLimitReachedModal
            content={{ action: 'generating', kind: 'model portfolios' }}
            onHide={this.unsetLimitReached}
            show={modelsLimitModalShown}
          />

          <CreatePortfolioModal
            modelCSVId="model-csv-1"
            onHide={this.hideModelCreateModal}
            show={modelCreateModalShown}
            showManualModal={this.openModelInMode}
            uploadCSVOption={this.uploadCSVOption}
          />

          <CombinePortfoliosModal
            onCreate={this.onCreateCombinedModel}
            onHide={this.hideCombineModelsModal}
            onLimitReached={this.setLimitReached}
            show={combineModelsModalShown}
          />

          <BulkUpdatePortfoliosModal
            onHide={this.hideBulkUpdateModal}
            selectedPortfolioIds={selectedModelIds}
            show={bulkUpdateModalShown}
          />

          <Disclosure />
        </div>
      </div>
    );
  }
}

AdvisorModels.contextTypes = {
  config: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

AdvisorModels.propTypes = {
  advisorByManager: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  marketStore: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  modelInEdition: PropTypes.object,
  models: PropTypes.array.isRequired,
  modelsLimitReached: PropTypes.bool,
  prismScoresInProgress: PropTypes.array.isRequired,
  scoredPrismIntents: PropTypes.object.isRequired,
  securitiesPositions: PropTypes.array,
  selectedModelIds: PropTypes.array.isRequired,
  selectedModels: PropTypes.array.isRequired,
  selectedTeam: PropTypes.number
};

AdvisorModels.defaultProps = {
  modelInEdition: null,
  modelsLimitReached: false,
  securitiesPositions: [],
  selectedTeam: 0
};

export default connect(state => ({
  advisorByManager: state.auth.advisorByManager,
  marketStore: state.market,
  meta: state.models.listMeta,
  modelInEdition: state.models.edit,
  models: state.models.list,
  modelsLimitReached: modelsLimitReachedSelector(state),
  prismScoresInProgress: modelsWithPrismScoresInProgressSelector(state),
  scoredPrismIntents: modelPortfoliosWithScoredPrismIntentsSelector(state),
  securitiesPositions: state.market.securities.positions,
  selectedModelIds: state.models.selectedModelIds,
  selectedModels: state.models.selectedModels,
  selectedTeam: state.teams.selected.value
}))(AdvisorModels);
