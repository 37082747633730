/* eslint-disable no-underscore-dangle */
import {
  DEFAULT_PORTFOLIO_TOTAL_ASSETS,
  SECURITY_UNDERLYING_MODEL,
  getSecurityDetails,
  processSecurityDetails,
  PROPOSAL_SOURCE
} from 'components/advisor/risk-analysis/securities/common/utils';
import { TOP_HOLDINGS_TO_SHOW } from 'components/advisor/risk-analysis/securities/top-holdings/constants';
import _ from 'lodash';

export const EMPTY_SECURITY_STYLES = { data: { chart: [], summary: {} }, total: 0 };

export const getLevelPercent = (value, total) => (total ? (value ?? 0) / total : '-');

export const processTaxonomy = (attr, portfolioTaxonomy, resultObj) =>
  Object.entries(portfolioTaxonomy)
    .filter(
      ([key, level]) => !_.isPlainObject(key) && _.isPlainObject(level) && !key.startsWith('__')
    )
    .forEach(([key, level]) => {
      if (!resultObj[key]) resultObj[key] = {};
      resultObj[key].__color = level?.__color;
      resultObj[key].__icon = level?.__icon;
      resultObj[key].__report_icon = level?.__report_icon;
      resultObj[key][`__${attr}_value`] = level.__value;
      if (_.isNil(resultObj[key].__order)) resultObj[key].__order = level.__order;
      processTaxonomy(attr, level, resultObj[key]);
    });

export const formatNumber = (value, sign) => {
  if (!value) return '-';
  return `${sign && value > 0 ? '+' : ''}${Math.round(value * 10000) / 100}%`;
};

export const buildCollapsibleMap = (data, keyDefaultValue = false) =>
  Object.entries(data)
    .filter(([k]) => !k.startsWith('__'))
    .reduce((acum, [key, level]) => {
      if (_.isPlainObject(level))
        return { ...acum, [key]: keyDefaultValue, ...buildCollapsibleMap(level) };
      return { ...acum };
    }, {});

export const getDistributionData = (
  target,
  recommended,
  benchmark,
  type,
  breakdownCustomSecurities = false
) => {
  const targetValue = target.value ?? DEFAULT_PORTFOLIO_TOTAL_ASSETS;
  const recommendedValue = targetValue;
  const benchmarkValue = targetValue;

  const targetTopHoldings = getSecurityDetails(
    target,
    type,
    targetValue,
    breakdownCustomSecurities
  );
  const recommendedTopHoldings = recommended
    ? getSecurityDetails(
        recommended,
        type,
        DEFAULT_PORTFOLIO_TOTAL_ASSETS,
        breakdownCustomSecurities
      )
    : [];
  const benchmarkTopHoldings = benchmark
    ? getSecurityDetails(benchmark, type, DEFAULT_PORTFOLIO_TOTAL_ASSETS, breakdownCustomSecurities)
    : [];

  const filteredTargetTopHoldings =
    type === SECURITY_UNDERLYING_MODEL
      ? targetTopHoldings.sort((a, b) => b.value - a.value).slice(0, TOP_HOLDINGS_TO_SHOW)
      : targetTopHoldings;
  const filteredRecommendedTopHoldings =
    type === SECURITY_UNDERLYING_MODEL
      ? recommendedTopHoldings.sort((a, b) => b.value - a.value).slice(0, TOP_HOLDINGS_TO_SHOW)
      : recommendedTopHoldings;
  const filteredBenchmarkTopHoldings =
    type === SECURITY_UNDERLYING_MODEL
      ? benchmarkTopHoldings.sort((a, b) => b.value - a.value).slice(0, TOP_HOLDINGS_TO_SHOW)
      : benchmarkTopHoldings;

  const { data: targetProcessedHoldings, total: targetProcessedTotal } = processSecurityDetails(
    filteredTargetTopHoldings,
    type,
    targetValue,
    PROPOSAL_SOURCE
  );
  const { data: recommendedProcessedHoldings, total: recommendedProcessedTotal } = recommended
    ? processSecurityDetails(
        filteredRecommendedTopHoldings,
        type,
        recommendedValue,
        PROPOSAL_SOURCE
      )
    : EMPTY_SECURITY_STYLES;
  const { data: benchmarkProcessedHoldings, total: benchmarkProcessedTotal } = benchmark
    ? processSecurityDetails(filteredBenchmarkTopHoldings, type, benchmarkValue, PROPOSAL_SOURCE)
    : EMPTY_SECURITY_STYLES;

  const distributionTable = {};
  processTaxonomy('target', targetProcessedHoldings.summary, distributionTable);
  if (recommended)
    processTaxonomy('recommended', recommendedProcessedHoldings.summary, distributionTable);
  if (benchmark)
    processTaxonomy('benchmark', benchmarkProcessedHoldings.summary, distributionTable);

  return {
    distributionTable,
    target: {
      chart: targetProcessedHoldings.chart,
      value: targetValue,
      total: targetProcessedTotal
    },
    recommended: {
      chart: recommendedProcessedHoldings.chart,
      value: recommendedValue,
      total: recommendedProcessedTotal
    },
    benchmark: {
      chart: benchmarkProcessedHoldings.chart,
      value: benchmarkValue,
      total: benchmarkProcessedTotal
    }
  };
};

export const getCollapsedCategories = collapsibleMap =>
  Object.keys(collapsibleMap).reduce((acc, key) => {
    if (collapsibleMap[key]) return [...acc, key];
    return acc;
  }, []);
