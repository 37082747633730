import { Container, percent, Root, Color, color } from '@amcharts/amcharts5';
import { Treemap } from '@amcharts/amcharts5/hierarchy';
import { Exporting } from '@amcharts/amcharts5/plugins/exporting';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import './styles.scss';

const TreemapChart = ({
  data,
  height,
  id,
  initialDepth,
  onChartReady,
  showLabelPercentages,
  width
}) => {
  const chartRef = useRef(null);

  const chartId = id || `treemap-chart-${Math.random().toString(36).substring(2)}`;

  useEffect(() => {
    const root = Root.new(chartId);

    // eslint-disable-next-line no-underscore-dangle
    root._logo.dispose();

    // set chart container dimensions
    const container = root.container.children.push(
      Container.new(root, { width: percent(100), height: percent(100) })
    );

    // set Treemap chart styles
    const chart = container.children.push(
      Treemap.new(root, {
        downDepth: 1,
        upDepth: 0,
        initialDepth,
        valueField: 'value',
        categoryField: 'name',
        childDataField: 'children',
        layoutAlgorithm: 'binary'
      })
    );

    // set custom fill color for each chart rectangle
    chart.rectangles.template.adapters.add(
      'fill',
      (fill, target) => target.dataItem.dataContext.color || fill
    );

    // set custom fill color for each chart rectangle label
    // https://www.amcharts.com/docs/v5/tutorials/adaptive-label-colors-on-a-treemap/
    chart.labels.template.adapters.add('fill', (fill, target) => {
      if (target.dataItem) {
        const rectangleColor = target.dataItem.get('rectangle').get('fill');
        return Color.alternative(rectangleColor, color('#000'), color('#fff'));
      }
      return fill;
    });

    // customize chart rectangle label text if needed
    if (showLabelPercentages)
      chart.labels.template.setAll({
        paddingBottom: 5,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        textAlign: 'center',
        text: "{category}\n[bold]{sum.formatNumber('#.##%')}[/]"
      });

    // format each chart rectangle tooltip value as a percentage
    // https://www.amcharts.com/docs/v5/concepts/formatters/formatting-numbers/
    chart.nodes.template.set('tooltipText', "{category} [bold]{sum.formatNumber('#.##%')}[/]");

    chart.data.setAll([{ children: data.options }]);
    chartRef.current = chart;

    // export Treemap chart
    // https://www.amcharts.com/docs/v5/concepts/exporting/exporting-api/
    if (onChartReady) {
      const exporting = Exporting.new(root, {
        pngOptions: { quality: 1, minWidth: 1000, maintainPixelRatio: true }
      });
      exporting.export('png').then(data => {
        onChartReady(chartId, data);
      });
    }

    return () => {
      root.dispose();
    };
  }, []);

  return (
    <div className="treemap-chart">
      <div id={chartId} style={{ width, height }} />
      {!!data.legend.length && (
        <div className="treemap-chart__legend">
          {data.legend.map(option => (
            <div key={option.name}>
              <div className="legend__dot" style={{ backgroundColor: option.color }} />
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

TreemapChart.propTypes = {
  data: PropTypes.shape({
    options: PropTypes.array.isRequired,
    legend: PropTypes.array.isRequired
  }).isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  id: PropTypes.string,
  initialDepth: PropTypes.number,
  onChartReady: PropTypes.func,
  showLabelPercentages: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

TreemapChart.defaultProps = {
  id: null,
  initialDepth: 1,
  onChartReady: null,
  showLabelPercentages: false
};

export default TreemapChart;
