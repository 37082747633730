/* eslint-disable no-underscore-dangle */
import PositionChart from 'components/charts/position-chart';
import CollapsibleTable from 'components/collapsible-table';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { formatMoney, getPositionsTaxonomy } from 'utils/utils';
import './styles.scss';
import { processTaxonomy } from './utils';

const LAYOUTS = {
  BOX: 'box',
  INLINE: 'inline'
};

const PositionsAnalysis = ({
  breakdownCustomSecurities,
  className,
  collapsibleProps,
  expandAll,
  expandRegions,
  headingLabels,
  hiddenVal,
  id,
  layout,
  onChartReady,
  portfolio,
  positionChartOptions,
  positionChartStyle,
  showHeadingRow,
  showTotalLabelPie,
  taxonomies
}) => {
  const {
    user: {
      advisor: {
        company: {
          hide_positions: hidePositions,
          expand_asset_classification: expandAssetClassification,
          expand_asset_details: expandAssetDetails
        }
      }
    }
  } = useContext(AdvisorContext);

  const portfolioTaxonomy = getPositionsTaxonomy(
    portfolio,
    taxonomies,
    expandAssetClassification,
    expandAssetDetails,
    breakdownCustomSecurities
  );
  const chartData = processTaxonomy(portfolioTaxonomy);

  const totalValue = portfolioTaxonomy.__value ?? portfolio.value;

  const currentPositionChartOptions = { ...positionChartOptions };

  if (showTotalLabelPie)
    currentPositionChartOptions.allLabels = [
      {
        align: 'center',
        y: '48%',
        id: 'total',
        text: formatMoney(totalValue)
      }
    ];

  currentPositionChartOptions.balloon = {
    fontSize: 10,
    color: '#FFFFFF',
    fillColor: '#000000',
    fillAlpha: 0.7,
    borderThickness: 0
  };

  return (
    <div className={`positions-analysis ${className}`}>
      <div className="portfolio-breakdown">
        <div className="chart-container">
          <PositionChart
            id={id}
            data={chartData}
            onChartReady={onChartReady}
            options={currentPositionChartOptions}
            style={positionChartStyle}
          />
        </div>
        {layout === LAYOUTS.INLINE && (
          <div className="collapsible-container">
            <CollapsibleTable
              bottomNameLabel="Total portfolio value"
              data={portfolioTaxonomy}
              expandRegions={expandRegions}
              expandAll={expandAll}
              hiddenVal={hiddenVal}
              hideLeaves={hidePositions}
              showBottomSection
              showHeadingRow={showHeadingRow}
              expandAssetClassification={expandAssetClassification}
              expandAssetDetails={expandAssetDetails}
              headingLabels={
                headingLabels || {
                  name: '',
                  value: 'Value',
                  percent: '% of Portfolio'
                }
              }
              showPercent
              {...collapsibleProps}
            />
          </div>
        )}
      </div>
    </div>
  );
};

PositionsAnalysis.propTypes = {
  breakdownCustomSecurities: PropTypes.bool,
  className: PropTypes.string,
  collapsibleProps: PropTypes.object,
  expandAll: PropTypes.bool,
  expandRegions: PropTypes.bool,
  headingLabels: PropTypes.object,
  hiddenVal: PropTypes.bool,
  id: PropTypes.string,
  layout: PropTypes.string,
  onChartReady: PropTypes.func,
  portfolio: PropTypes.object.isRequired,
  positionChartOptions: PropTypes.object,
  positionChartStyle: PropTypes.object,
  showHeadingRow: PropTypes.bool,
  showTotalLabelPie: PropTypes.bool,
  taxonomies: PropTypes.array
};

PositionsAnalysis.defaultProps = {
  breakdownCustomSecurities: false,
  className: '',
  collapsibleProps: {},
  expandAll: false,
  expandRegions: false,
  headingLabels: null,
  hiddenVal: true,
  id: null,
  layout: LAYOUTS.INLINE,
  onChartReady: null,
  positionChartOptions: {
    precision: 2,
    radius: '35%',
    innerRadius: '60%',
    pullOutDuration: 0.3,
    pullOutRadius: 20,
    pullOutEffect: 'easeInSine',
    balloonText:
      '<span className="balloon-position-analysis">' +
      '<span className="ballon-color-box" style="background-color: [[color]]"></span>' +
      '<span className="ballon-content">[[title]] [[value]]%</span>' +
      '</span>',
    bulletBorderAlpha: 0,
    bulletAlpha: 0.6
  },
  positionChartStyle: {},
  showHeadingRow: false,
  showTotalLabelPie: false,
  taxonomies: []
};

PositionsAnalysis.contextTypes = {
  portfolioProvider: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default connect(state => ({
  taxonomies: state.market.taxonomies
}))(PositionsAnalysis);
