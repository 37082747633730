import { MODEL_TARGET_TYPE } from 'components/advisor/risk-analysis/constants';
import RiskAnalysisTarget from 'components/advisor/risk-analysis/risk-analysis-target';
import Disclosure from 'components/disclosure';
import SpinnerLoader from 'components/performance-spinner';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const RiskAnalysis = ({ calculating, fetching, portfolio, riskAnalysis, showEditModal }) => {
  if (fetching) return <SpinnerLoader spinnerLoading />;

  return (
    <section className="risk-analysis">
      <div className="risk-analysis-body">
        <div className="risk-analysis-body-container">
          {portfolio && !_.isEmpty(riskAnalysis) && (
            <section>
              <RiskAnalysisTarget
                calculating={calculating}
                editing={showEditModal}
                portfolio={portfolio}
                riskAnalysis={riskAnalysis}
                type={MODEL_TARGET_TYPE}
              />
            </section>
          )}

          {portfolio &&
            !portfolio.prism_score_summary &&
            !calculating &&
            !fetching &&
            !showEditModal && (
              <h3 className="text-sm-center placeholder-text">
                The PRISM score is not ready for this portfolio.
              </h3>
            )}

          <Disclosure />
        </div>
      </div>
    </section>
  );
};

RiskAnalysis.defaultProps = {
  fetching: false,
  portfolio: {},
  riskAnalysis: {}
};

RiskAnalysis.propTypes = {
  calculating: PropTypes.bool.isRequired,
  fetching: PropTypes.bool,
  portfolio: PropTypes.object,
  riskAnalysis: PropTypes.object,
  showEditModal: PropTypes.bool.isRequired
};

export default connect(state => ({
  portfolio: state.models.view,
  riskAnalysis: state.models.riskAnalysis
}))(RiskAnalysis);
