import AccountDetailsHeader from 'components/advisor/accounts/details/header';
import AccountDetailsView from 'components/advisor/accounts/details/view';
import AccountEdit, { modes } from 'components/advisor/investors/edit/index';
import Disclosure from 'components/disclosure';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import SpinnerLoader from 'components/performance-spinner';
import { DEFINING_FIELDS_STEP } from 'components/utils/csv-wizard/constants';
import _ from 'lodash';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import InvestorProvider from 'providers/investor';
import ProspectProvider from 'providers/prospects';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { CSVModelMixin } from 'utils/mixins';
import { LOAD_CSV_CONFIG, normalizeCsv } from 'utils/papa_parse_config';
import { triggerAccountPrismScore } from 'utils/prism';
import { selectQuestionnaire } from 'utils/questionnaire';
import { getAccountQuestionnaires, getPortfolioForUpdate } from 'utils/utils';

class AccountDetails extends Component {
  constructor(props) {
    super(props);

    CSVModelMixin.applyMixin(this);
    this.state = {
      heldAway: false,
      loading: true,
      portfolioModal: { show: false, mode: modes.UPDATE },
      wizardCSV: null
    };

    this.getAccount = this.getAccount.bind(this);
    this.showPortfolioModal = this.showPortfolioModal.bind(this);
    this.toggleEditAccountModal = this.toggleEditAccountModal.bind(this);
    this.getPrismScore = this.getPrismScore.bind(this);
    this.toggleAccountStatus = this.toggleAccountStatus.bind(this);
    this.fetchAccountData = this.fetchAccountData.bind(this);
    this.toggleIncludeInPrism = this.toggleIncludeInPrism.bind(this);
    this.toggleHeldAway = this.toggleHeldAway.bind(this);
  }

  getChildContext() {
    const { account, investor, riskAnalysis, benchmark, questionnaires } = this.props;

    let questions = [];
    if (investor && account && !_.isEmpty(questionnaires))
      questions = selectQuestionnaire(questionnaires, investor.id, account.id);
    if (investor && !_.isEmpty(questionnaires) && _.isEmpty(questions))
      questions = selectQuestionnaire(questionnaires, investor.id);

    return {
      account,
      benchmark,
      getAccount: this.getAccount,
      investor,
      questions,
      riskAnalysis,
      toggleEditAccountModal: this.toggleEditAccountModal
    };
  }

  componentDidMount() {
    this.fetchAccountData();
  }

  componentDidUpdate(prevProps) {
    const {
      params: { accountId: newAccountId }
    } = this.props;
    const {
      params: { accountId: oldAccountId }
    } = prevProps;
    if (newAccountId !== oldAccountId) this.fetchAccountData();
  }

  componentWillUnmount() {
    const { setupTargetScoreWizard } = this.context;
    setupTargetScoreWizard({});
  }

  toggleIncludeInPrism() {
    const { accountProvider } = this.context;
    const { account } = this.props;
    accountProvider.updatePatch(account.id, {
      excluded: !account.excluded
    });
  }

  toggleHeldAway() {
    const { accountProvider } = this.context;
    const { account } = this.props;
    accountProvider.updatePatch(account.id, { held_away: !account.held_away });
  }

  fetchAccountData() {
    const { accountProvider, modelProvider, questionProvider, setupTargetScoreWizard } =
      this.context;
    const {
      location: { pathname },
      params: { id: investorId, accountId },
      provider
    } = this.props;

    this.setState({ loading: true });

    Promise.all([
      provider.get(investorId),
      modelProvider.getCommonBenchmarks(),
      questionProvider
        .listQuestionnaires(investorId, _.includes(pathname, 'advisor/prospects/'))
        .then(({ data: questionnaires }) => {
          const scoredQuestionnaires = questionnaires.filter(q => q.template.is_scored);
          const [questionnaire] = scoredQuestionnaires;
          const hasAccountLevelQuestionnaire =
            questionnaire && questionnaire?.accounts && questionnaire.accounts.length > 0;

          if (_.isEmpty(scoredQuestionnaires)) return;

          if (hasAccountLevelQuestionnaire) {
            const accountQuestionnaires = getAccountQuestionnaires(
              [{ id: accountId }],
              scoredQuestionnaires
            );
            Object.keys(accountQuestionnaires).forEach(accountId => {
              if (accountQuestionnaires[accountId])
                questionProvider.setQuestionnaire(
                  accountQuestionnaires[accountId],
                  investorId,
                  accountId
                );
            });
          } else questionProvider.setQuestionnaire(questionnaire, investorId);
        }),
      accountProvider.get(accountId).then(({ data }) => {
        setupTargetScoreWizard({
          investors: [{ ...data.investor, accounts: [data] }],
          refresh: this.fetchAccountData
        });
        if (data.prism_score)
          modelProvider.riskAnalysisPrecomputed({
            ...data.prism_score.output,
            portfolio: { ...data, name: data.display_name, portfolio_id: data.id }
          });
      })
    ]).then(() => {
      this.setState({ loading: false });
    });
  }

  getAccount() {
    const {
      params: { accountId }
    } = this.props;
    const { accountProvider } = this.context;
    return accountProvider.get(accountId);
  }

  toggleEditAccountModal(account) {
    if (account)
      return this.setState({
        portfolioModal: {
          account,
          investor: account.investor,
          mode: modes.UPDATE,
          model: getPortfolioForUpdate(account),
          show: true
        }
      });

    return this.setState({ portfolioModal: { show: false } });
  }

  showPortfolioModal(show = true, defaultAccount = {}, mode = modes.CREATE) {
    this.setState({ portfolioModal: { account: defaultAccount, mode, show } });

    if (!show) this.setState({ wizardCSV: null });
  }

  uploadCSVOption(e) {
    this.toggleCreateAccountModal();
    this.registerCSVLoadedCallback(this.uploadCSVCallback);
    this.onCSVInputChange(e);
  }

  uploadCSVCallback = e => {
    const { errorsProvider } = this.context;
    const result = normalizeCsv(e.target.result);
    const { data, errors } = Papa.parse(result, LOAD_CSV_CONFIG);
    if (data.length)
      this.setState({
        wizardCSV: {
          data,
          headerRows: Object.keys(data[0]),
          show: true,
          step: DEFINING_FIELDS_STEP
        }
      });

    if (errors && errors.length) errors.forEach(e => errorsProvider.registerError(e.message));
    this.showPortfolioModal(true, modes.CREATE);
  };

  getPrismScore(accountId, reCalculate = false, event) {
    const {
      params: { id: investorId },
      provider
    } = this.props;
    const { accountProvider } = this.context;

    const updateStorePromises = () => [
      this.getAccount(),
      provider.get(investorId),
      provider.getAccounts(investorId)
    ];

    triggerAccountPrismScore(accountId, accountProvider, updateStorePromises, reCalculate, event);
  }

  toggleAccountStatus(value) {
    const { account } = this.props;
    const { accountProvider } = this.context;
    accountProvider
      .updatePatch(account.id, {
        excluded: value
      })
      .then(() => {
        this.getAccount().then(() => {
          if (value)
            toast.success(() => (
              <div>
                The account has been excluded and will no longer be a part of the aggregated asset
                value and PRISM rating.
              </div>
            ));
          else
            toast.success(() => (
              <div>
                The account has been added and will be a part of the aggregated asset value and
                PRISM rating.
              </div>
            ));
        });
      });
  }

  render() {
    const {
      account,
      benchmark,
      children,
      investor,
      location,
      marketStore,
      marketStore: {
        securities: { positions }
      },
      riskAnalysis,
      type
    } = this.props;
    const {
      loading,
      portfolioModal,
      portfolioModal: { show: showPortfolioModal, mode: accountEditMode },
      heldAway,
      wizardCSV
    } = this.state;

    return (
      <div className="account-details-container">
        {!loading && (
          <div className="account-details-wrapper">
            <AccountDetailsHeader
              account={account}
              investor={investor}
              type={type}
              getAccount={this.getAccount}
              toggleIncludeInPrism={this.toggleIncludeInPrism}
              toggleHeldAway={this.toggleHeldAway}
              toggleEditAccountModal={this.toggleEditAccountModal}
            />
            <AccountDetailsView
              account={account}
              investor={investor}
              riskAnalysis={riskAnalysis}
              location={location}
              toggleEditAccountModal={this.toggleEditAccountModal}
              toggleAccountStatus={this.toggleAccountStatus}
              benchmark={benchmark}
            >
              {children}
            </AccountDetailsView>
          </div>
        )}

        {loading && <SpinnerLoader spinnerLoading={loading} />}

        <Modal
          id="modelEditModal"
          className="modal-lg"
          show={showPortfolioModal}
          onHidden={() => this.showPortfolioModal(false)}
          ref={c => {
            this.editModal = c;
          }}
        >
          <ModalHeader />
          <ModalBody>
            <AccountEdit
              {...portfolioModal}
              marketStore={marketStore}
              registerCSVLoadedCallback={this.registerCSVLoadedCallback}
              onCSVInputChange={this.onCSVInputChange}
              mode={accountEditMode}
              show={showPortfolioModal}
              positions={positions}
              investor={investor}
              hideEditModel={() => this.showPortfolioModal(false)}
              onSuccess={this.getAccount}
              wizardCSV={wizardCSV}
              initialValues={{ held_away: heldAway }}
              reCalculatePrism={this.getPrismScore}
            />
          </ModalBody>
        </Modal>

        <Disclosure />
      </div>
    );
  }
}

AccountDetails.propTypes = {
  account: PropTypes.object,
  benchmark: PropTypes.array,
  children: PropTypes.element.isRequired,
  investor: PropTypes.object,
  location: PropTypes.object.isRequired,
  marketStore: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired,
  questionnaires: PropTypes.array.isRequired,
  riskAnalysis: PropTypes.object,
  type: PropTypes.string.isRequired
};

AccountDetails.defaultProps = {
  account: null,
  benchmark: [],
  investor: null,
  riskAnalysis: {}
};

AccountDetails.contextTypes = {
  accountProvider: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired,
  questionProvider: PropTypes.object.isRequired,
  setupTargetScoreWizard: PropTypes.func.isRequired
};

AccountDetails.childContextTypes = {
  account: PropTypes.object,
  benchmark: PropTypes.arrayOf(PropTypes.object),
  getAccount: PropTypes.func.isRequired,
  investor: PropTypes.object,
  questions: PropTypes.arrayOf(PropTypes.object),
  riskAnalysis: PropTypes.object.isRequired,
  toggleEditAccountModal: PropTypes.func.isRequired
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    location: { pathname }
  } = ownProps;

  const { investorProvider, prospectProvider } = dispatchProps;
  const { investor, prospect } = stateProps;
  const isProspect = _.includes(pathname, 'advisor/prospects/');
  const provider = isProspect ? prospectProvider : investorProvider;
  const type = isProspect ? 'prospects' : 'investors';

  return {
    ...stateProps,
    ...ownProps,
    ...dispatchProps,
    provider,
    investor: isProspect ? prospect : investor,
    type
  };
}

export default connect(
  state => ({
    investor: state.investors.view,
    account: state.accounts.view,
    prospect: state.prospects.view,
    riskAnalysis: state.models.riskAnalysis,
    marketStore: state.market,
    questionnaires: state.questions.questionnaires,
    benchmark: state.models.benchmark
  }),
  dispatch => ({
    investorProvider: new InvestorProvider({ dispatch }),
    prospectProvider: new ProspectProvider({ dispatch })
  }),
  mergeProps
)(AccountDetails);
