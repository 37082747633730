import {
  BasicMaterialsIcon,
  CommunicationServicesIcon,
  ConsumerCyclicalIcon,
  ConsumerDefensiveIcon,
  EnergyIcon,
  FinancialServicesIcon,
  HealthcareIcon,
  IndustrialsIcon,
  RealEstateIcon,
  TechnologyIcon,
  UtilitiesIcon
} from './pdf/icons';

export const SECTOR_EXPOSURE_CHART_ID = 'sectorExposureChart';

export const INDUSTRIALS = 'Industrials';
export const BASIC_MATERIALS = 'Basic Materials';
export const FINANCIAL_SERVICES = 'Financial Services';
export const COMMUNICATION_SERVICES = 'Communication Services';
export const ENERGY = 'Energy';
export const UTILITIES = 'Utilities';
export const CONSUMER_DEFENSIVE = 'Consumer Defensive';
export const CONSUMER_CYCLICAL = 'Consumer Cyclical';
export const HEALTHCARE = 'Healthcare';
export const TECHNOLOGY = 'Technology';
export const REAL_ESTATE = 'Real Estate';

export const SECTOR_EXPOSURE_MAPPING = {
  [INDUSTRIALS]: {
    background: '#A1887F',
    src: '/img/icons/sector-exposure/industrials.svg',
    reportIcon: IndustrialsIcon
  },
  [BASIC_MATERIALS]: {
    background: '#FF8A65',
    src: '/img/icons/sector-exposure/basic-materials.svg',
    reportIcon: BasicMaterialsIcon
  },
  [FINANCIAL_SERVICES]: {
    background: '#4FC3F7',
    src: '/img/icons/sector-exposure/financial-services.svg',
    reportIcon: FinancialServicesIcon
  },
  [COMMUNICATION_SERVICES]: {
    background: '#9575CD',
    src: '/img/icons/sector-exposure/communication-services.svg',
    reportIcon: CommunicationServicesIcon
  },
  [ENERGY]: {
    background: '#FFB74D',
    src: '/img/icons/sector-exposure/energy.svg',
    reportIcon: EnergyIcon
  },
  [UTILITIES]: {
    background: '#F06292',
    src: '/img/icons/sector-exposure/utilities.svg',
    reportIcon: UtilitiesIcon
  },
  [CONSUMER_DEFENSIVE]: {
    background: '#81C784',
    src: '/img/icons/sector-exposure/consumer-defensive.svg',
    reportIcon: ConsumerDefensiveIcon
  },
  [CONSUMER_CYCLICAL]: {
    background: '#7986CB',
    src: '/img/icons/sector-exposure/consumer-cyclical.svg',
    reportIcon: ConsumerCyclicalIcon
  },
  [HEALTHCARE]: {
    background: '#F06292',
    src: '/img/icons/sector-exposure/healthcare.svg',
    reportIcon: HealthcareIcon
  },
  [TECHNOLOGY]: {
    background: '#4DB6AC',
    src: '/img/icons/sector-exposure/technology.svg',
    reportIcon: TechnologyIcon
  },
  [REAL_ESTATE]: {
    background: '#E57373',
    src: '/img/icons/sector-exposure/real-estate.svg',
    reportIcon: RealEstateIcon
  }
};

export const PDF_IMAGE_STYLES = { width: '100%', maxHeight: '250px', objectFit: 'scale-down' };
