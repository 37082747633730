/* global $ */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...this.state, shown: false, hidden: true };
  }

  componentDidMount() {
    const { show, onShown, onHidden, onShow, onHide } = this.props;
    const hash = 'modal';

    $(this.mainModal).on('shown.bs.modal', () => {
      if (onShown) onShown();
    });

    $(this.mainModal).on('hidden.bs.modal', () => {
      if (onHidden) onHidden();
    });

    $(this.mainModal).on('show.bs.modal', () => {
      const { backdropClassName } = this.props;
      window.location.hash = hash;
      this.setState({ shown: true, hidden: false });
      if (onShow) onShow();
      setTimeout(() => {
        // adds a custom class to control backdrop styling
        if (backdropClassName) $('.modal-backdrop').addClass(backdropClassName);
      });
    });

    $(this.mainModal).on('hide.bs.modal', () => {
      this.setState({ shown: false, hidden: true });
      if (onHide) onHide();
    });

    $(window).on('hashchange', () => {
      if (window.location.hash !== `#${hash}`) {
        this.modal('hide');
        window.location.hash = '';
      }
    });

    if (show) this.show();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { show } = this.props;
    const { hidden, shown } = this.state;
    if (nextProps.show === show) return;
    if (nextProps.show && hidden) this.show();
    if (!nextProps.show && shown) this.hide();
  }

  componentWillUnmount() {
    $(this.mainModal).off('shown.bs.modal');
    $(this.mainModal).off('hidden.bs.modal');
    $(this.mainModal).off('show.bs.modal');
    $(this.mainModal).off('hide.bs.modal');
    $(window).off('hashchange');
    this.modal('hide');
  }

  modal(action) {
    return $(this.mainModal).modal(action);
  }

  show() {
    return this.modal('show');
  }

  hide() {
    return this.modal('hide');
  }

  toggle() {
    return this.modal('toggle');
  }

  render() {
    const { backdrop, children, className, classNameContent, id, keyboard, modalStyle, style } =
      this.props;
    const { shown } = this.state;
    const classNameModal = `modal-content ${classNameContent}`;

    return (
      <div
        aria-hidden="true"
        className="modal fade"
        data-backdrop={backdrop}
        data-keyboard={keyboard}
        id={id}
        ref={m => {
          this.mainModal = m;
        }}
        role="dialog"
        style={modalStyle}
        tabIndex="-1"
      >
        <div className={`modal-dialog ${className}`} role="document">
          <div className={classNameModal} style={style}>
            {shown && children}
          </div>
        </div>
      </div>
    );
  }
}

Modal.defaultProps = {
  backdrop: undefined,
  backdropClassName: '',
  className: '',
  classNameContent: '',
  keyboard: undefined,
  modalStyle: {},
  onHidden: () => {},
  onHide: () => {},
  onShow: () => {},
  onShown: () => {},
  show: false,
  style: {},
  title: ''
};

Modal.propTypes = {
  backdrop: PropTypes.string,
  backdropClassName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  className: PropTypes.string,
  classNameContent: PropTypes.string,
  id: PropTypes.string.isRequired,
  keyboard: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  modalStyle: PropTypes.object,
  onHidden: PropTypes.func,
  onHide: PropTypes.func,
  onShow: PropTypes.func,
  onShown: PropTypes.func,
  show: PropTypes.bool,
  style: PropTypes.object,
  title: PropTypes.string
};

export const ModalHeader = ({ className, removeCloseButton, title }) => {
  if (title)
    return (
      <div className={`modal-header ${className}`}>
        <h3 className="modal-title text-title">{title}</h3>
        {!removeCloseButton && (
          <button
            type="button"
            className="close text-danger"
            style={{ opacity: 1 }}
            data-dismiss="modal"
            aria-label="Close"
          >
            <span className="icon-remove" />
          </button>
        )}
      </div>
    );

  if (!removeCloseButton)
    return (
      <div style={{ position: 'absolute', top: 20, right: 20, zIndex: 1 }}>
        <button
          type="button"
          style={{ opacity: 1 }}
          className="close text-danger"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span className="icon-remove" />
        </button>
      </div>
    );

  return null;
};

ModalHeader.defaultProps = { className: '', removeCloseButton: false, title: '' };

ModalHeader.propTypes = {
  className: PropTypes.string,
  removeCloseButton: PropTypes.bool,
  title: PropTypes.string
};

export const ModalBody = ({ className, children }) => (
  <div className={`modal-body ${className}`}>{children}</div>
);

ModalBody.defaultProps = { className: '', title: '' };

ModalBody.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  className: PropTypes.string,
  title: PropTypes.string
};

export default Modal;
