/* eslint-disable camelcase */
import * as am5 from '@amcharts/amcharts5';
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow';
import * as am5map from '@amcharts/amcharts5/map';
import { Exporting } from '@amcharts/amcharts5/plugins/exporting';
import {
  COUNTRY_COLORS,
  COUNTRY_REGION_MAPPING,
  GEOGRAPHIC_EXPOSURE_CHART_ID,
  REGIONS_LIST,
  REGION_MAPPING,
  REGION_STYLE_MAPPING
} from 'components/advisor/risk-analysis/securities/geographic-exposure/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { CYAN_COLOR, GRAPHITE_COLOR, LILAC_COLOR } from 'reports/base/styles';

const PROPOSAL_COLORS = {
  target: GRAPHITE_COLOR,
  recommended: CYAN_COLOR,
  benchmark: LILAC_COLOR
};

function AssambleLegendItem(source, data, region) {
  const regionData = data[source].find(item => item.title === region.name);
  if (!regionData)
    return {
      value: 0,
      label: ''
    };

  const { value } = regionData;
  return {
    value,
    label: `\n[${PROPOSAL_COLORS[source]}][fontSize:16px]▮[/][/]${value}%`
  };
}

const MapChart = ({ id, data, multipleDataSource, onChartReady, width }) => {
  let sumarizedData = data;
  if (multipleDataSource)
    sumarizedData = data.target
      .concat(data.model)
      .concat(data.benchmark)
      .filter(item => item);

  const chartId = id || GEOGRAPHIC_EXPOSURE_CHART_ID;

  const chartRef = useRef(null);

  useEffect(() => {
    const root = am5.Root.new(chartRef.current);

    // disable root animations
    root.duration = 0;

    // eslint-disable-next-line no-underscore-dangle
    root._logo.dispose();

    // set root background to white
    root.container.set('background', am5.Rectangle.new(root, { fill: am5.color(0xffffff) }));

    // create a container for the map chart
    const mapContainer = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(85), // adjust this value to make the map smaller
        height: am5.percent(85), // adjust this value to make the map smaller
        centerX: am5.p50,
        x: am5.p50
      })
    );

    const chart = mapContainer.children.push(
      am5map.MapChart.new(root, {
        projection: am5map.geoMercator(),
        zoomStep: 0, // disable zooming
        wheelYStep: 0 // disable zooming with the mouse wheel
      })
    );

    const polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow,
        exclude: ['AQ', 'GL', 'SJ']
      })
    );

    // custom region data
    const regionData = REGIONS_LIST.map((regionName, index) => ({
      id: `region${index}`,
      name: regionName,
      countries: REGION_MAPPING[regionName],
      color: am5.color(REGION_STYLE_MAPPING[regionName].background),
      hexColor: REGION_STYLE_MAPPING[regionName].background,
      value: sumarizedData.find(item => item.title === regionName)?.value ?? 0
    }));

    polygonSeries.mapPolygons.template.adapters.add('fill', (fill, target) => {
      const countryCode = target.dataItem.get('id');
      const region = COUNTRY_REGION_MAPPING[countryCode];
      const regionData = sumarizedData.find(item => item.title === region);
      const color = regionData ? COUNTRY_COLORS[countryCode] : am5.color('#dddddd');
      return color;
    });

    // create a container for the legend
    const legendContainer = root.container.children.push(
      am5.Container.new(root, {
        layout: root.horizontalLayout,
        width: am5.p100,
        height: am5.p100,
        x: 0,
        y: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0
      })
    );

    // add left and right legend containers
    const leftLegend = legendContainer.children.push(
      am5.Container.new(root, {
        layout: root.verticalLayout,
        height: am5.p100,
        width: am5.p50,
        x: 0,
        y: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0
      })
    );

    const rightLegend = legendContainer.children.push(
      am5.Container.new(root, {
        layout: root.verticalLayout,
        height: am5.p100,
        width: am5.p50,
        x: am5.p50,
        y: 0,
        paddingTop: 0,
        paddingBottom: 0
      })
    );

    // create legends for each region and add them to the left and right containers
    regionData.forEach(region => {
      const legendItem = am5.Container.new(root, {
        layout: root.horizontalLayout,
        paddingBottom: 0,
        marginBottom: multipleDataSource ? 8 : 15,
        width: am5.p100
      });

      const position = REGION_STYLE_MAPPING[region.name].align;
      let label = '';
      if (multipleDataSource) {
        const { value: targetValue, label: targetLabel } = AssambleLegendItem(
          'target',
          data,
          region
        );
        const { value: recommendedValue, label: recommendedLabel } = AssambleLegendItem(
          'recommended',
          data,
          region
        );
        const { value: benchmarkValue, label: benchmarkLabel } = AssambleLegendItem(
          'benchmark',
          data,
          region
        );

        if (targetValue < 1 && recommendedValue < 1 && benchmarkValue < 1) return;

        const sublabels = targetLabel + recommendedLabel + benchmarkLabel;
        label = `[fontSize:13px][bold][${region.hexColor}]${region.name}[/][/][/]${sublabels}`;
      } else {
        if (region.value < 1) return;

        label = `[fontSize:13px][bold][${region.hexColor}]${region.name}[/]\n${region.value}%[/]`;
      }

      legendItem.children.push(
        am5.Label.new(root, {
          text: label,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          textAlign: position === 'left' ? 'left' : 'right',
          width: am5.p100
        })
      );

      if (position === 'left') leftLegend.children.push(legendItem);
      if (position === 'right') rightLegend.children.push(legendItem);
    });

    leftLegend.children.push(
      am5.Label.new(root, {
        text: '[#aaa][fontSize:12px]* The map legend displays the regions with more than 1% exposure.[/][/]',
        x: 0,
        y: 470
      })
    );

    if (onChartReady) {
      const exporting = Exporting.new(root, {
        pngOptions: { quality: 1, minWidth: 1000, maxHeight: 500, maintainPixelRatio: false }
      });

      exporting.export('png').then(data => {
        onChartReady(chartId, data);
      });
    }

    return () => {
      root.dispose();
    };
  }, [JSON.stringify(data)]);

  return (
    <div className="world-chart">
      <div
        id={chartId}
        ref={chartRef}
        style={{ minWidth: width, height: '500px', pointerEvents: 'none' }}
      />
    </div>
  );
};

MapChart.propTypes = {
  id: PropTypes.string,
  data: PropTypes.array.isRequired,
  multipleDataSource: PropTypes.bool,
  onChartReady: PropTypes.func,
  width: PropTypes.string
};

MapChart.defaultProps = {
  id: null,
  multipleDataSource: false,
  onChartReady: null,
  width: '100%'
};

export default MapChart;
