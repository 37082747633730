/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import tooltipCloseButton from 'assets/img/tooltip-close-button.svg';
import {
  combinedPortfoliosSelector,
  totalAssetsSelector
} from 'components/advisor/investors/details/prism-investor/selectors';
import InvestorProfileBasic from 'components/advisor/investors/investor-profile-basic';
import { INVESTOR_TARGET_TYPE } from 'components/advisor/risk-analysis/constants';
import PositionsAnalysis from 'components/advisor/risk-analysis/risk-analysis-target/positions-analysis';
import { PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID } from 'components/advisor/risk-analysis/risk-analysis-target/positions-analysis/constants';
import SecurityTypeConcentration from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration';
import GeographicExposure from 'components/advisor/risk-analysis/securities/geographic-exposure';
import { GEOGRAPHIC_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/geographic-exposure/utils';
import InvestmentStyle from 'components/advisor/risk-analysis/securities/investment-style';
import SectorExposure from 'components/advisor/risk-analysis/securities/sector-exposure';
import { SECTOR_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/sector-exposure/utils';
import TopHoldings from 'components/advisor/risk-analysis/securities/top-holdings';
import ScrollCardsIndex from 'components/advisor/scroll-cards-index';
import ScrollToTop from 'components/advisor/scroll-cards-index/scroll-to-top';
import AggregatedToleranceScoreBubble from 'components/advisor/utils/score-bubble/aggregated-tolerance';
import BreakdownCustomSecuritiesToggleTitle from 'components/form/breakdown-custom-securities-toggle-title';
import {
  ALLOCATIONS_TYPE,
  GEOGRAPHIC_EXPOSURE_TYPE,
  INVESTMENT_STYLE_TYPE,
  INVESTOR_ENTITY_TYPE,
  PROSPECT_ENTITY_TYPE,
  SECTOR_EXPOSURE_TYPE,
  TOP_HOLDINGS_TYPE
} from 'components/form/breakdown-custom-securities-toggle-title/constants';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import CardOverview from 'components/utils/card-overview';
import Link from 'components/utils/link';
import PrismRatingUpsideDownside from 'components/utils/prism-rating-upside-downside';
import tabs from 'constants/tabs';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import {
  getInvestorRtqSentTimestamp,
  getInvestorTargetScore,
  getRiskToleranceLatestUpdate,
  numToRiskScaleString
} from 'utils/utils';
import InvestorAccountsOverviewTable from './investor-accounts-overview-table';
import InvestorDetailStats from './investor-stats';
import './styles.scss';

class InvestorOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      basicProfileModal: false,
      householdSearch: '',
      householdSelected: {},
      joinHouseholdLoading: false,
      tooltipManuallyClosed: false
    };

    this.onHouseholdChange = _.debounce(this.onHouseholdChange, 750);
    this.getInvestorAccounts = this.getInvestorAccounts.bind(this);

    this.riskToleranceTooltipRef = React.createRef();
  }

  showModal = () => this.setState({ basicProfileModal: true });

  closeModal = () => this.setState({ basicProfileModal: false });

  hideAssessRiskToleranceTooltip = () => {
    this.setState({ tooltipManuallyClosed: true });
    ReactTooltip.hide(this.riskToleranceTooltipRef.current);
  };

  getInvestor = () => {
    const { clientProvider } = this.context;
    const {
      params: { id }
    } = this.props;
    return clientProvider.get(id);
  };

  getInvestorAccounts = () => {
    const { clientProvider } = this.context;
    const {
      params: { id }
    } = this.props;
    return clientProvider.getAccounts(id);
  };

  getData = () => [this.getInvestor(), this.getInvestorAccounts()];

  handleJoinHousehold = () => {
    const { clientProvider } = this.context;
    const { investor } = this.props;
    const { householdSelected } = this.state;

    if (_.isEmpty(householdSelected)) toast.error(() => <div>Choose a household to add</div>);
    else {
      this.setState({ joinHouseholdLoading: true });
      clientProvider
        .linkHousehold(investor.id, { id: householdSelected.id })
        .then(() => this.getInvestor());
    }
  };

  onHouseholdSelected = suggestion => {
    this.setState({ householdSearch: suggestion.name, householdSelected: suggestion });
  };

  onHouseholdChange = search => {
    const { householdProvider } = this.context;
    householdProvider.list({ search });
  };

  render() {
    const { showTargetScoreWizard, user, clientProvider } = this.context;

    const {
      accounts,
      breakdownCustomSecurities,
      combinedPortfolios,
      households,
      investor,
      selectedAccountIds,
      totalAccounts,
      totalAssets,
      totalValue
    } = this.props;

    const { basicProfileModal, householdSearch, joinHouseholdLoading, tooltipManuallyClosed } =
      this.state;

    const prismScore = investor && investor.aggregated_prism_scores;
    const targetScore = getInvestorTargetScore(investor).data;
    const targetScoreLatestUpdate = getRiskToleranceLatestUpdate(investor, accounts);

    const clientType = investor.is_prospect ? 'prospects' : 'investors';
    const portfolioType = investor.is_prospect ? PROSPECT_ENTITY_TYPE : INVESTOR_ENTITY_TYPE;
    const portfolioBreakdownCustomSecurities = breakdownCustomSecurities[investor.id];
    const somePrismOverall = _.some(accounts, 'prism_score_summary');

    const investorRtqSentTimestamp = getInvestorRtqSentTimestamp(investor);

    const positions = accounts
      .filter(account => !account.excluded)
      .map(account => account.positions)
      .flat();

    // Check on every render if the tooltip should be hidden
    if (targetScore && !_.isEmpty(targetScore) && !investorRtqSentTimestamp)
      ReactTooltip.hide(this.riskToleranceTooltipRef.current);

    return (
      <div>
        <div className="investor-overview">
          {!_.isEmpty(positions) && !!totalAssets && (
            <ScrollCardsIndex
              score={prismScore && prismScore.overall}
              type={INVESTOR_TARGET_TYPE}
            />
          )}

          <InvestorDetailStats
            investor={investor}
            countGoals={investor.goals.length}
            countAccounts={totalAccounts}
            totalValue={totalValue}
            showModal={this.showModal}
            households={households}
            onHouseholdSelected={this.onHouseholdSelected}
            householdSearch={householdSearch}
            onHouseholdChange={this.onHouseholdChange}
            handleJoinHousehold={this.handleJoinHousehold}
            joinHouseholdLoading={joinHouseholdLoading}
          />

          {prismScore && prismScore.overall && (
            <div>
              <div className="prism-overview-header">
                <span className="prism-header">
                  Portfolio Risk <span className="sub-text">Powered By</span> PRISM Rating &trade;
                </span>
                <span className="view-report">
                  <Link
                    to={`/advisor/${clientType}/${investor.id}/${tabs.PRISM}`}
                    className="btn btn-outline-secondary btn-prism"
                  >
                    View Report
                  </Link>
                </span>
              </div>
              <div id="portfolio-risk">
                <PrismRatingUpsideDownside
                  prismSummary={prismScore}
                  targetSummary={targetScore}
                  value={totalValue}
                  market={{
                    up: user.advisor.company.market_upside_performance,
                    down: user.advisor.company.market_downside_performance
                  }}
                />
              </div>
            </div>
          )}

          <div className="card-deck">
            <CardOverview title="Risk Tolerance">
              <div>
                <div className="info-div">
                  {targetScore && targetScore.overall ? (
                    <div className="tolerance-div">
                      {investor && (
                        <AggregatedToleranceScoreBubble
                          element={{ ...investor, accounts }}
                          overview
                        />
                      )}
                      <span className="text">{numToRiskScaleString(targetScore.overall)}</span>
                    </div>
                  ) : (
                    <>
                      <span className="info-text tolerance">No Risk Tolerance Generated yet</span>
                      {investorRtqSentTimestamp && (
                        <span className="risk-tolerance-sent-timestamp">
                          (You sent a questionnaire on{' '}
                          {moment(investorRtqSentTimestamp).format('LL')})
                        </span>
                      )}
                    </>
                  )}
                </div>

                {targetScoreLatestUpdate && (
                  <div className="target-score-latest-update">{`(Last updated: ${moment
                    .utc(targetScoreLatestUpdate)
                    .fromNow()})`}</div>
                )}

                <div className="button-div">
                  {targetScore && _.isEmpty(targetScore) && investorRtqSentTimestamp ? (
                    <button
                      className="btn btn-outline-primary risk-card"
                      type="button"
                      onClick={() => showTargetScoreWizard()}
                    >
                      Send Reminder
                    </button>
                  ) : (
                    <>
                      <button
                        data-tip=""
                        data-for="assess-update-risk-tolerance-tooltip"
                        data-event="fake"
                        className="btn btn-primary risk-card"
                        type="button"
                        ref={this.riskToleranceTooltipRef}
                        onClick={() => showTargetScoreWizard()}
                      >
                        {targetScore && targetScore.overall
                          ? 'Update Risk Tolerance'
                          : 'Assess Risk Tolerance'}
                      </button>

                      <ReactTooltip
                        id="assess-update-risk-tolerance-tooltip"
                        effect="solid"
                        place="bottom"
                        clickable
                      >
                        <img
                          className="tooltip-close-button"
                          src={tooltipCloseButton}
                          alt="Close"
                          onClick={this.hideAssessRiskToleranceTooltip}
                        />
                        Find out risk tolerance by inviting the prospect/client to take the
                        questionnaire.{' '}
                        <span role="img" aria-label="happy-face">
                          😃
                        </span>
                      </ReactTooltip>

                      {targetScore &&
                        _.isEmpty(targetScore) &&
                        !investorRtqSentTimestamp &&
                        !tooltipManuallyClosed &&
                        ReactTooltip.show(this.riskToleranceTooltipRef.current)}
                    </>
                  )}
                </div>
              </div>
            </CardOverview>

            <CardOverview title="Proposals">
              <div>
                <div className="card-description">
                  <span className="info-text">
                    You can select a model portfolio and generate a proposal for this client.
                  </span>
                </div>
                <div className="button-div">
                  <Link
                    disabled={!somePrismOverall}
                    className="btn btn-primary"
                    to={`/advisor/${clientType}/${investor.id}/${tabs.PROPOSAL}`}
                  >
                    Generate Proposal
                  </Link>
                </div>
              </div>
            </CardOverview>

            <CardOverview title="IPS">
              <div>
                <div className="card-description">
                  <span className="info-text">Risk Tolerance is required to generate an IPS.</span>
                </div>
                <div className="button-div">
                  <Link
                    disabled={
                      !targetScore || !targetScore.overall || !prismScore || !prismScore.overall
                    }
                    className="btn btn-primary"
                    to={`/advisor/${clientType}/${investor.id}/${tabs.IPS}`}
                  >
                    Generate IPS
                  </Link>
                </div>
              </div>
            </CardOverview>
          </div>

          {!_.isEmpty(positions) && !!totalAssets && (
            <div className="client-overview-risk-factor-analysis" id="allocation">
              <BreakdownCustomSecuritiesToggleTitle
                portfolio={{ id: investor.id, ...combinedPortfolios }}
                portfolioType={portfolioType}
                sectionType={ALLOCATIONS_TYPE}
                title="Assets Allocation"
              />
              <div className="overview-client">
                <PositionsAnalysis
                  breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
                    ALLOCATIONS_TYPE
                  )}
                  className="fill-space"
                  hiddenVal={false}
                  id={PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID}
                  portfolio={combinedPortfolios}
                />
              </div>
              <ScrollToTop />
            </div>
          )}

          {!_.isEmpty(positions) && !!totalAssets && (
            <div className="client-overview-risk-factor-analysis box-position-analysis">
              <div className="header">
                <BreakdownCustomSecuritiesToggleTitle
                  isNewFeature
                  portfolio={{ id: investor.id, ...combinedPortfolios }}
                  portfolioType={portfolioType}
                  sectionType={INVESTMENT_STYLE_TYPE}
                />
              </div>
              <div className="investment-style-container">
                <InvestmentStyle
                  breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
                    INVESTMENT_STYLE_TYPE
                  )}
                  portfolio={combinedPortfolios}
                />
              </div>
              <ScrollToTop />
            </div>
          )}

          {!_.isEmpty(positions) && !!totalAssets && (
            <div className="client-overview-risk-factor-analysis box-position-analysis">
              <div className="header" id="sector-exposure--header">
                <BreakdownCustomSecuritiesToggleTitle
                  isNewFeature
                  portfolio={{ id: investor.id, ...combinedPortfolios }}
                  portfolioType={portfolioType}
                  sectionType={SECTOR_EXPOSURE_TYPE}
                />
              </div>
              <div className="sector-exposure-container">
                <SectorExposure
                  breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
                    SECTOR_EXPOSURE_TYPE
                  )}
                  id={SECTOR_EXPOSURE_CHART_ID}
                  portfolio={combinedPortfolios}
                />
              </div>
              <ScrollToTop />
            </div>
          )}

          {!_.isEmpty(positions) && !!totalAssets && (
            <div className="client-overview-risk-factor-analysis box-position-analysis">
              <div className="header">
                <BreakdownCustomSecuritiesToggleTitle
                  isNewFeature
                  portfolio={{ id: investor.id, ...combinedPortfolios }}
                  portfolioType={portfolioType}
                  sectionType={TOP_HOLDINGS_TYPE}
                />
              </div>
              <div className="sector-exposure-container">
                <TopHoldings
                  breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
                    TOP_HOLDINGS_TYPE
                  )}
                  portfolio={combinedPortfolios}
                />
              </div>
              <ScrollToTop />
            </div>
          )}

          {!_.isEmpty(positions) && !!totalAssets && (
            <div className="client-overview-risk-factor-analysis box-position-analysis">
              <div className="header" id="geographic-exposure--header">
                <BreakdownCustomSecuritiesToggleTitle
                  isNewFeaturee
                  portfolio={{ id: investor.id, ...combinedPortfolios }}
                  portfolioType={portfolioType}
                  sectionType={GEOGRAPHIC_EXPOSURE_TYPE}
                />
              </div>
              <div className="geographic-exposure-container">
                <GeographicExposure
                  breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
                    GEOGRAPHIC_EXPOSURE_TYPE
                  )}
                  id={GEOGRAPHIC_EXPOSURE_CHART_ID}
                  portfolio={combinedPortfolios}
                />
              </div>
              <ScrollToTop />
            </div>
          )}

          {!_.isEmpty(positions) && !!totalAssets && (
            <div className="security-type-concentration">
              <h2>Security Type Concentration</h2>
              <SecurityTypeConcentration positions={positions} totalAssets={totalAssets} />
              <p>This chart only considers positions from non-excluded accounts.</p>
              <ScrollToTop />
            </div>
          )}

          {accounts && (
            <div className="investor-accounts__container">
              <InvestorAccountsOverviewTable
                accounts={accounts}
                getData={this.getData}
                investor={investor}
                selectedAccountIds={selectedAccountIds}
              />
              <div className="scroll-to-top__container">
                <ScrollToTop />
              </div>
            </div>
          )}
        </div>

        <Modal
          id="basic-profile-modal"
          key={3}
          title="test"
          className="modal-lg modal-basic-profile"
          show={basicProfileModal}
          onShown={this.showModal}
          onHidden={this.closeModal}
        >
          <ModalHeader title={_.isEmpty(investor) ? '' : `${investor.full_name}'s Profile`} />
          <ModalBody>
            <InvestorProfileBasic
              investor={investor}
              investorProvider={clientProvider}
              closeModal={this.closeModal}
              getInvestor={this.getInvestor}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

InvestorOverview.propTypes = {
  accounts: PropTypes.array.isRequired,
  breakdownCustomSecurities: PropTypes.object.isRequired,
  combinedPortfolios: PropTypes.object.isRequired,
  households: PropTypes.array.isRequired,
  investor: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  selectedAccountIds: PropTypes.array,
  totalAccounts: PropTypes.number.isRequired,
  totalAssets: PropTypes.number,
  totalValue: PropTypes.number.isRequired
};

InvestorOverview.defaultProps = {
  selectedAccountIds: [],
  totalAssets: null
};

InvestorOverview.contextTypes = {
  clientProvider: PropTypes.object.isRequired,
  householdProvider: PropTypes.object.isRequired,
  showTargetScoreWizard: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  userProvider: PropTypes.object.isRequired
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    location: { pathname }
  } = ownProps;
  const {
    investor,
    investorAccounts,
    investorsBreakdownCustomSecurities,
    prospect,
    prospectAccounts,
    prospectsBreakdownCustomSecurities
  } = stateProps;

  const isProspect = _.includes(pathname, 'advisor/prospects/');
  const accounts = isProspect ? prospectAccounts : investorAccounts;
  let totalAccounts = 0;
  let totalValue = 0;

  accounts.forEach(account => {
    if (account.excluded === false) {
      totalAccounts += 1;
      totalValue += account.value;
    }
  });

  return {
    ...stateProps,
    ...ownProps,
    ...dispatchProps,
    accounts,
    breakdownCustomSecurities: isProspect
      ? prospectsBreakdownCustomSecurities
      : investorsBreakdownCustomSecurities,
    investor: isProspect ? prospect : investor,
    totalAccounts,
    totalValue
  };
}

export default connect(
  state => ({
    combinedPortfolios: combinedPortfoliosSelector(state),
    households: state.households.list,
    investor: state.investors.view,
    investorAccounts: state.investors.viewAccounts || [],
    investorsBreakdownCustomSecurities: state.investors.breakdownCustomSecurities,
    prospect: state.prospects.view,
    prospectAccounts: state.prospects.viewAccounts || [],
    prospectsBreakdownCustomSecurities: state.prospects.breakdownCustomSecurities,
    selectedAccountIds: state.accounts.selectedAccounts || [],
    totalAssets: totalAssetsSelector(state)
  }),
  null,
  mergeProps
)(InvestorOverview);
