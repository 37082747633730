/* eslint-disable no-underscore-dangle */
import { Image, Text, View } from '@react-pdf/renderer';
import { ALLOCATIONS_TYPE } from 'components/form/breakdown-custom-securities-toggle-title/constants';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { Fragment, useContext } from 'react';
import {
  formatPercentage,
  getPositionsTaxonomy,
  getTaxonomyLevelIterator,
  withCurrencyFormat
} from 'utils/utils';
import { processTaxonomy } from '../utils';
import styles from './styles';

const Row = ({ color, depth, hiddenVal, label, level, showPercent, total }) => {
  const value = level.__value;
  return (
    <View style={[styles.row, styles.rowLevel, { borderLeftColor: color }]} wrap={false}>
      <View style={styles.cell}>
        <Text style={[styles[`cellTextLevel${depth}`], styles[`firstCellTextLevel${depth}`]]}>
          {label}
        </Text>
      </View>
      <View style={styles.cell}>
        <Text style={[styles.cellText, styles[`cellTextLevel${depth}`]]}>
          {hiddenVal || withCurrencyFormat(value, 'standard', 0)}
        </Text>
      </View>
      {showPercent && (
        <View style={[styles.cell, { marginRight: 0 }]}>
          <Text style={[styles.cellText, styles[`cellTextLevel${depth}`]]}>
            {formatPercentage(value / total, 100, 0)}
          </Text>
        </View>
      )}
    </View>
  );
};

Row.propTypes = {
  color: PropTypes.string.isRequired,
  depth: PropTypes.number.isRequired,
  hiddenVal: PropTypes.bool,
  label: PropTypes.string.isRequired,
  level: PropTypes.object.isRequired,
  showPercent: PropTypes.bool,
  total: PropTypes.number.isRequired
};

Row.defaultProps = {
  hiddenVal: false,
  showPercent: false
};

const PositionsAnalysis = ({
  chart,
  headingLabels,
  hiddenVal,
  portfolio,
  showHeadingRow,
  showPercent,
  showTotalRow,
  totalRowLabel
}) => {
  const {
    portfolioBreakdownCustomSecurities,
    taxonomies,
    user: {
      advisor: {
        company: {
          expand_asset_classification: expandAssetClassification,
          expand_asset_details: expandAssetDetails
        }
      }
    }
  } = useContext(AdvisorContext);

  const portfolioTaxonomy = getPositionsTaxonomy(
    portfolio,
    taxonomies,
    expandAssetClassification,
    expandAssetDetails,
    portfolioBreakdownCustomSecurities.includes(ALLOCATIONS_TYPE)
  );
  processTaxonomy(portfolioTaxonomy);
  const totalValue = portfolioTaxonomy.__value ?? portfolio.value;
  const rowProps = { total: totalValue, hiddenVal, showPercent };

  return (
    <View style={styles.container}>
      {chart && (
        <View style={styles.chartContainer}>
          <Image src={chart} style={styles.chart} />
        </View>
      )}

      <View style={styles.cell}>
        {showHeadingRow && (
          <View style={[styles.table, { marginTop: 0 }]} wrap={false}>
            <View style={[styles.row, styles.rowTotal]}>
              <View style={styles.cell}>
                <Text>{headingLabels.name}</Text>
              </View>
              <View style={styles.cell}>
                <Text style={styles.cellText}>{hiddenVal || headingLabels.value}</Text>
              </View>
              {showPercent && (
                <View style={[styles.cell, { marginRight: 0 }]}>
                  <Text style={styles.cellText}>{headingLabels.percent}</Text>
                </View>
              )}
            </View>
          </View>
        )}

        <View style={[styles.table, { marginTop: 0 }]}>
          {getTaxonomyLevelIterator(portfolioTaxonomy).map(([l1Key, l1], l1Idx) => (
            <Fragment key={`l1-${l1.__id ?? l1Idx}`}>
              <Row depth={1} label={l1Key} level={l1} color={l1.__color} {...rowProps} />

              {expandAssetClassification &&
                getTaxonomyLevelIterator(l1).map(([l2Key, l2], l2Idx) => (
                  <Fragment key={`l2-${l2.__id ?? `${l1Idx}-${l2Idx}`}`}>
                    <Row depth={2} label={l2Key} level={l2} color={l1.__color} {...rowProps} />

                    {expandAssetDetails &&
                      getTaxonomyLevelIterator(l2).map(([l3Key, l3], l3Idx) => (
                        <Fragment key={`l3-${l3.__id ?? `${l1Idx}-${l2Idx}-${l3Idx}`}`}>
                          <Row
                            depth={3}
                            label={l3Key}
                            level={l3}
                            color={l1.__color}
                            lastLevel
                            {...rowProps}
                          />
                        </Fragment>
                      ))}
                  </Fragment>
                ))}
            </Fragment>
          ))}
        </View>

        {showTotalRow && (
          <View style={styles.table} wrap={false}>
            <View style={[styles.row, styles.rowTotal]}>
              <View style={styles.cell}>
                <Text>{totalRowLabel}</Text>
              </View>
              <View style={styles.cell}>
                <Text style={styles.cellText}>
                  {hiddenVal || withCurrencyFormat(totalValue, 'standard', 0)}
                </Text>
              </View>
              {showPercent && (
                <View style={[styles.cell, { marginRight: 0 }]}>
                  <Text style={styles.cellText}>{formatPercentage(1, 100, 0)}</Text>
                </View>
              )}
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

PositionsAnalysis.propTypes = {
  chart: PropTypes.string,
  headingLabels: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    percent: PropTypes.string.isRequired
  }),
  hiddenVal: PropTypes.bool,
  portfolio: PropTypes.object.isRequired,
  showHeadingRow: PropTypes.bool,
  showPercent: PropTypes.bool,
  showTotalRow: PropTypes.bool,
  totalRowLabel: PropTypes.string
};

PositionsAnalysis.defaultProps = {
  chart: null,
  headingLabels: {
    name: '',
    value: 'Value',
    percent: '% of Portfolio'
  },
  hiddenVal: true,
  showHeadingRow: true,
  showPercent: false,
  showTotalRow: true,
  totalRowLabel: 'Total portfolio value'
};

export default PositionsAnalysis;
