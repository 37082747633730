import * as am5 from '@amcharts/amcharts5';
import { Exporting } from '@amcharts/amcharts5/plugins/exporting';
import * as am5xy from '@amcharts/amcharts5/xy';
import { COLORS } from 'components/advisor/proposal/securities/sector-exposure-distribution/options';
import { SECTOR_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/sector-exposure/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

const HorizontalBarChart = ({ id, data, multirows, onChartReady, width }) => {
  const chartId = id || SECTOR_EXPOSURE_CHART_ID;

  const chartRef = useRef(null);

  useEffect(() => {
    const root = am5.Root.new(chartRef.current);

    // disable root animations
    root.duration = 0;

    // eslint-disable-next-line no-underscore-dangle
    root._logo.dispose();

    // set root background to white
    root.container.set('background', am5.Rectangle.new(root, { fill: am5.color(0xffffff) }));

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, { layout: root.verticalLayout })
    );

    // set chart default state with no animation
    chart.states.create('default', { duration: 0 });
    chart.states.create('hidden', { duration: 0 });

    const xRenderer = am5xy.AxisRendererX.new(root, {});
    xRenderer.labels.template.set('visible', false);

    const yRenderer = am5xy.AxisRendererY.new(root, {});

    // reduce minimum grid distance to fit more labels
    yRenderer.setAll({ minGridDistance: 1 });

    // ensure all Y-axis labels are shown without wrapping or truncation
    yRenderer.labels.template.setAll({
      centerY: am5.p50,
      oversizedBehavior: 'none',
      truncate: false
    });

    const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, { renderer: xRenderer }));

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, { renderer: yRenderer, categoryField: 'title' })
    );

    if (multirows && multirows.length > 0) {
      const allData = [];
      const categories = new Set();

      multirows.reverse().forEach((rowKey, rowIndex) => {
        if (data[rowKey].length === 0) return;
        const color = COLORS[rowKey];

        const series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: `Series ${rowIndex + 1}`,
            xAxis,
            yAxis,
            valueXField: 'value',
            categoryYField: 'title',
            sequencedInterpolation: true,
            calculateAggregates: true
          })
        );

        // set series default state with no animation
        series.states.create('default', { duration: 0 });
        series.states.create('hidden', { duration: 0 });

        series.columns.template.set('tooltipText', '{categoryY}: [bold]{valueX}%[/]');
        series.columns.template.setAll({ fill: am5.color(color), stroke: am5.color(color) });

        const seriesData = data[rowKey].map(item => ({ ...item, color }));
        series.data.setAll(seriesData);

        seriesData.forEach(item => {
          categories.add(item.title);
        });

        allData.push(...seriesData);
      });

      yAxis.data.setAll([...categories].map(title => ({ title })));
    } else {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          calculateAggregates: true,
          categoryYField: 'title',
          name: 'Series 1',
          sequencedInterpolation: true,
          valueXField: 'value',
          xAxis,
          yAxis
        })
      );

      // set series default state with no animation
      series.states.create('default', { duration: 0 });
      series.states.create('hidden', { duration: 0 });

      series.columns.template.set('tooltipText', '{categoryY}: [bold]{valueX}%[/]');

      series.columns.template.adapters.add('fill', (fill, target) =>
        am5.color(target.dataItem.dataContext.color)
      );

      series.columns.template.adapters.add('stroke', (stroke, target) =>
        am5.color(target.dataItem.dataContext.color)
      );

      yAxis.data.setAll(data);
      series.data.setAll(data);
    }

    if (onChartReady) {
      const exporting = Exporting.new(root, {
        pngOptions: { quality: 1, minWidth: 1000, maxHeight: 500, maintainPixelRatio: false }
      });

      exporting.export('png').then(data => {
        onChartReady(chartId, data);
      });
    }

    return () => {
      if (root) root.dispose();
    };
  }, [JSON.stringify(data)]);

  const dataLength = Math.max(
    data?.length || 0,
    data?.target?.length || 0,
    data?.recommended?.length || 0,
    data?.benchmark?.length || 0
  );

  return (
    <div className="horizontal-bar-chart">
      <div
        id={chartId}
        ref={chartRef}
        style={{ minWidth: width, height: `${Math.min(dataLength * 80, 440).toString()}px` }}
      />
    </div>
  );
};

HorizontalBarChart.propTypes = {
  id: PropTypes.string,
  data: PropTypes.array.isRequired,
  multirows: PropTypes.array,
  onChartReady: PropTypes.func,
  width: PropTypes.string
};

HorizontalBarChart.defaultProps = {
  id: null,
  multirows: null,
  onChartReady: null,
  width: '100%'
};

export default HorizontalBarChart;
