import LoadingPrismDataMessage from 'components/advisor/dashboard/loading-prism-data-message';
import AccountOrModelPrismReportViewer from 'components/advisor/risk-analysis/report/account-or-model-prism/viewer';
import { ALLOCATIONS_TYPE } from 'components/form/breakdown-custom-securities-toggle-title/constants';
import SpinnerLoader from 'components/performance-spinner';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import RiskAnalysisContent from './risk-analysis-content';
import RiskAnalysisPositions from './risk-analysis-positions';
import './styles.scss';
import { accountOrModelMergeProps } from './utils';

class RiskAnalysisTarget extends Component {
  componentDidMount() {
    const { modelProvider } = this.context;
    modelProvider.getCommonBenchmarks();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state)) return true;
    return false;
  }

  render() {
    const {
      actions,
      benchmark,
      calculating,
      editing,
      portfolio,
      portfolioBreakdownCustomSecurities,
      riskAnalysis,
      type
    } = this.props;

    if (_.isEmpty(portfolio)) return <SpinnerLoader spinnerLoading />;

    return (
      <div className="risk-score-result-container">
        <div className="prism-rating-page-container">
          <div className="box-container heading">
            {riskAnalysis.custom && !editing && !calculating && (
              <AccountOrModelPrismReportViewer
                benchmarks={benchmark}
                portfolio={portfolio}
                riskAnalysis={riskAnalysis}
                type={type}
              />
            )}
            {actions}
          </div>

          {editing && (
            <div className="calculating-portfolio-risk">
              <LoadingPrismDataMessage message="Preparing risk report..." inset />
            </div>
          )}

          {!editing && (
            <div className="hide-from-report">
              <RiskAnalysisPositions
                breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
                  ALLOCATIONS_TYPE
                )}
                portfolio={portfolio}
                type={type}
                withTitle
              />
            </div>
          )}

          {!editing && calculating && (
            <div className="calculating-portfolio-risk">
              <LoadingPrismDataMessage message="Calculating Portfolio Risk..." inset />
            </div>
          )}

          {!editing && !calculating && (
            <RiskAnalysisContent
              benchmark={benchmark}
              portfolioBreakdownCustomSecurities={portfolioBreakdownCustomSecurities}
              portfolio={portfolio}
              riskAnalysis={riskAnalysis}
              type={type}
            />
          )}
        </div>
      </div>
    );
  }
}

RiskAnalysisTarget.contextTypes = {
  actionProvider: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

RiskAnalysisTarget.defaultProps = {
  benchmark: [],
  actions: null,
  calculating: false,
  editing: false
};

RiskAnalysisTarget.propTypes = {
  actions: PropTypes.node,
  benchmark: PropTypes.array,
  calculating: PropTypes.bool,
  editing: PropTypes.bool,
  portfolio: PropTypes.object.isRequired,
  portfolioBreakdownCustomSecurities: PropTypes.object.isRequired,
  riskAnalysis: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

export default connect(
  state => ({
    accountsBreakdownCustomSecurities: state.accounts.breakdownCustomSecurities,
    benchmark: state.models.benchmark,
    modelsBreakdownCustomSecurities: state.models.breakdownCustomSecurities
  }),
  null,
  accountOrModelMergeProps
)(RiskAnalysisTarget);
