import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { withCurrencyFormat } from 'utils/utils';
import PositionsFormsetRow from './row';
import './styles.scss';
import { reorder } from './utils';

const PositionsFormset = ({
  absoluteValues,
  activeExclude,
  marketSecurities,
  positions,
  totalError,
  withExcludePositionsFeature,
  isViewOnly
}) => {
  const { marketProvider } = useContext(AdvisorContext);

  const [classifications, setClassifications] = useState({ type: [], subtype: [], sector: [] });
  const [visibleRows, setVisibleRows] = useState({});
  const [requiresRefreshPositions, setRequiresRefreshPositions] = useState(false);

  const hasTouchedPositions = !!positions.filter(p => p.value.touched).length;
  const formsetRows = document.querySelectorAll('.positions-formset-row');
  const positionsTotalValue = positions
    .filter(position => !position.value.value || !position.deleted.value)
    .reduce((acc, position) => {
      let positionValue = 0;
      if (typeof position.value.value === 'string')
        positionValue = parseFloat(position.value.value);
      else positionValue = position.value.value;
      if (Number.isNaN(positionValue)) return acc;
      return acc + parseFloat(positionValue.toFixed(2));
    }, 0);
  const formattedPositionsTotalValue = absoluteValues
    ? withCurrencyFormat(positionsTotalValue.toFixed(2))
    : `${positionsTotalValue.toFixed(2)}%`;

  const addNewPositionHandler = () => {
    positions.addField({});
    setRequiresRefreshPositions(true);
  };

  const intersectionHandler = entries => {
    entries.forEach(entry => {
      const rowId = entry.target.getAttribute('data-row-id');
      if (rowId && visibleRows[rowId] !== entry.isIntersecting)
        setVisibleRows(prevVisibleRows => ({ ...prevVisibleRows, [rowId]: entry.isIntersecting }));
    });
  };

  useEffect(() => {
    marketProvider.getClassifications().then(({ data }) => {
      reorder(data.type);
      reorder(data.subtype);
      reorder(data.sector);
      setClassifications(data);
    });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(intersectionHandler, {
      trackVisibility: true, // track the actual visibility of the element
      delay: 100 // set a minimum delay between notifications
    });
    formsetRows.forEach(row => {
      observer.observe(row);
    });
  }, [formsetRows.length]);

  // this only takes care of triggering a re-render every time a new position is added,
  // preventing the row from being in idle state and loading correctly
  useEffect(() => {
    if (requiresRefreshPositions) setRequiresRefreshPositions(false);
  }, [requiresRefreshPositions]);

  return (
    <div className="positions-formset">
      {!isViewOnly && (
        <div className="new-row">
          <button type="button" onClick={addNewPositionHandler} className="link">
            <i className="icon-add" /> Add new position
          </button>
        </div>
      )}

      <table className="table table-bordered table-borderless-top">
        <thead className="thead-graphite">
          <tr>
            <th>PRISM</th>
            <th>Ticker</th>
            <th>Asset / Security Name</th>
            <th>Asset class</th>
            <th>Subtype</th>
            <th>Segment</th>
            <th>{absoluteValues ? 'Amount' : 'Weight %'}</th>
          </tr>
        </thead>
        <tbody>
          {positions
            .filter(position => !position.deleted.value)
            .map((position, idx) => ({ ...position, rowId: position.id.value || idx }))
            .sort((a, b) => {
              if (a.id.value) return 1;
              if (b.id.value) return -1;
              return b.rowId - a.rowId;
            })
            .map((position, idx, array) => (
              <PositionsFormsetRow
                activeExclude={activeExclude}
                classifications={classifications}
                isFirstPosition={idx === 0}
                isViewOnly={isViewOnly}
                key={position.rowId}
                marketSecurities={marketSecurities}
                position={position}
                removePositionDisabled={array.length === 1}
                visible={visibleRows[position.rowId]}
                withExcludePositionsFeature={withExcludePositionsFeature}
              />
            ))}
        </tbody>
      </table>

      <div className="total">
        <div className="text-right total-text">Total {absoluteValues ? 'Amount' : 'Weight'}</div>
        <h4 className="text-lighter text-right total-number">{formattedPositionsTotalValue}</h4>
        {totalError &&
          hasTouchedPositions && [
            <div className="total-error-line" />,
            <div className="text-danger error total-error-message">{totalError}</div>
          ]}
      </div>
    </div>
  );
};

PositionsFormset.propTypes = {
  absoluteValues: PropTypes.bool,
  activeExclude: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  marketSecurities: PropTypes.array.isRequired,
  positions: PropTypes.array.isRequired,
  totalError: PropTypes.string,
  withExcludePositionsFeature: PropTypes.bool
};

PositionsFormset.defaultProps = {
  absoluteValues: false,
  activeExclude: false,
  isViewOnly: false,
  totalError: '',
  withExcludePositionsFeature: false
};

export default React.memo(PositionsFormset);
