/* eslint-disable react/no-unstable-nested-components */
import Disclosure from 'components/disclosure';
import DynamicTable from 'containers/table/dynamic';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { toast } from 'react-toastify';
import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import './styles.scss';

const INITIAL_SCAN_HISTORY_ORDERING = ['-created'];

const ScanHistory = ({ list, meta }) => {
  const { aiAssistantProvider, routerActions } = useContext(AdvisorContext);

  const onFetchData = params => aiAssistantProvider.list(params);

  useEffect(() => {
    onFetchData();
  }, []);

  const filteredList = list.filter(file => file.status !== 'failed');

  const columns = [
    {
      accessorFn: row => row.original_filename,
      id: 'original_filename',
      name: 'File Name',
      cell: file => (
        <Link to={`/advisor/ai-assistant/scan-iq/${file.id}`}>{file.original_filename}</Link>
      ),
      meta: { style: () => ({ width: 200 }) }
    },
    {
      accessorFn: row => new Date(row.created).toLocaleDateString(),
      id: 'created',
      name: 'Date',
      meta: { style: () => ({ width: 150 }), className: () => 'text-center' }
    },
    {
      accessorFn: row => `${row.created_by.user.first_name} ${row.created_by.user.last_name}`,
      id: 'scanned_by',
      name: 'Scanned by',
      cell: file => (
        <span>
          {file.created_by.user.first_name} {file.created_by.user.last_name}
        </span>
      ),
      meta: { style: () => ({ width: 150 }), className: () => 'text-center' }
    },
    {
      accessorFn: row => row.status,
      id: 'status',
      name: 'Status',
      cell: file => (
        <span
          className={cn('status-background', 'text-capitalize', {
            'status-processing': file.status === 'processing',
            'status-completed': file.status === 'completed'
          })}
        >
          {file.status}
        </span>
      ),
      meta: { style: () => ({ width: 100 }), className: () => 'text-center' }
    },
    {
      accessorFn: () => null,
      id: 'actions',
      name: 'Actions',

      cell: file => (
        <div className="btn-actions__container">
          <button
            type="button"
            onClick={() => {
              if (file.status === 'failed')
                return toast.error('This file is not available. Please upload a new one.');
              return routerActions.push(`/advisor/ai-assistant/scan-iq/${file.id}`);
            }}
          >
            {file.status === 'failed' ? (
              <i className="fs-icon-eye-slash" />
            ) : (
              <i className="fs-icon-eye" />
            )}
          </button>
        </div>
      ),
      meta: { style: () => ({ width: 150 }), className: () => 'text-center' },
      enableSorting: false
    }
  ];

  return (
    <div id="scan-history" className="scan-history">
      <div className="header-container">
        <div>
          <h2>Scan History</h2>
          <h3>Access and manage your past results.</h3>
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => routerActions.push(`/advisor/ai-assistant/scan-iq`)}
        >
          Scan New File
        </button>
      </div>

      <DynamicTable
        columns={columns}
        data={filteredList}
        label="Scans"
        initialParams={{ ordering: INITIAL_SCAN_HISTORY_ORDERING }}
        meta={meta}
        onFetchData={onFetchData}
        withAdvisorFilter={false}
        withTeamFilter={false}
        hasFilter={false}
      />
      <Disclosure />
    </div>
  );
};

ScanHistory.propTypes = {
  list: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  list: state.aiAssistant.list,
  meta: state.aiAssistant.listMeta
});

export default connect(mapStateToProps)(ScanHistory);
