/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Choice from 'components/form/choice';
import { formatMoney } from 'utils/utils';
import { trackAmplitudeEvent } from 'utils/tracking';

import './styles.scss';

export class ClientSelectAccounts extends PureComponent {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
    this.submit = this.submit.bind(this);
    this.state = {
      accountIds: props.accounts.reduce((acum, account) => ({ ...acum, [account.id]: true }), {}),
      selectAllChecked: true
    };
  }

  toggle(accountId) {
    const { accountIds } = this.state;
    const updatedAccountIds = { ...accountIds, [accountId]: !accountIds[accountId] };
    const selectAllChecked = Object.values(updatedAccountIds).every(Boolean);

    this.setState({
      accountIds: updatedAccountIds,
      selectAllChecked
    });
  }

  toggleSelectAll() {
    const { accounts } = this.props;
    const { selectAllChecked } = this.state;
    const newSelectAllChecked = !selectAllChecked;
    const newAccountIds = accounts.reduce(
      (acum, account) => ({ ...acum, [account.id]: newSelectAllChecked }),
      {}
    );

    this.setState({
      accountIds: newAccountIds,
      selectAllChecked: newSelectAllChecked
    });
  }

  submit() {
    const { onSelectAccounts, accounts } = this.props;
    const { accountIds } = this.state;
    const selectedIds = Object.entries(accountIds)
      .filter(([_, selected]) => selected)
      .map(([id]) => parseInt(id, 10));
    onSelectAccounts(selectedIds);

    const totalAccounts = accounts?.length;
    const accountsSelected = Object.values(accountIds).filter(el => el).length;
    const rate = totalAccounts ? accountsSelected / totalAccounts : 0;

    trackAmplitudeEvent('tolerance_modal.selected_accounts', {
      totalAccounts,
      accountsSelected,
      rate
    });
  }

  render() {
    const { accounts } = this.props;
    const { accountIds, selectAllChecked } = this.state;

    return (
      <div id="ClientSelectAccounts">
        <h2>Please select accounts that should have the same risk tolerance.</h2>
        <table className="accounts">
          <thead>
            <tr className="accounts-header">
              <th>
                <Choice
                  checked={selectAllChecked}
                  disabled={false}
                  toggle={this.toggleSelectAll}
                  className="select-account"
                />
              </th>
              <th className="account-name">Account Name</th>
              <th className="account-value">
                <b>Value</b>
              </th>
            </tr>
          </thead>
          <tbody>
            {accounts.map(account => (
              <tr key={`selectable-account-${account.id}`}>
                <td>
                  <Choice
                    title=""
                    checked={accountIds[account.id]}
                    disabled={false}
                    toggle={() => this.toggle(account.id)}
                    className="select-account"
                  />
                </td>
                <td className="account-name">{account.display_name}</td>
                <td className="account-value">
                  <b>{formatMoney(account.value)}</b>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="actions">
          <button
            className="btn btn-primary"
            type="button"
            disabled={!Object.values(accountIds).some(x => x)}
            onClick={this.submit}
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}

ClientSelectAccounts.propTypes = {
  accounts: PropTypes.array.isRequired,
  onSelectAccounts: PropTypes.func.isRequired
};

export default ClientSelectAccounts;
