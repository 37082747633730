export const mapPositions = positions =>
  positions
    .filter(p => !p.deleted)
    .map(p => ({
      ticker: p.ticker,
      ticker_name: p.ticker_name,
      type: p.type,
      subtype: p.subtype,
      sector: p.sector,
      value: p.value,
      excluded: p.excluded
    }));

export const reorder = d =>
  d.length
    ? d.push(
        d.splice(
          d.findIndex(v => v.name === 'Others' || v.name === 'Other'),
          1
        )[0]
      )
    : 0;
