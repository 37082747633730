import SecurityTypeConcentration from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration';
import RiskOverall from 'components/advisor/risk-analysis/risk-overall';
import PositionsAnalysisTable from 'components/positions-analysis-table';
import CardOverview from 'components/utils/card-overview';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import './styles.scss';

const About = ({
  marketplace: {
    name: providerName,
    description: providerDescription,
    company: { name: companyName, logo: logoUrl, about },
    prism_score_summary: { overall: prismScore },
    type,
    inception_date: inceptionDate,
    positions
  }
}) => (
  <div className="marketplace-about">
    <div className="col-5">
      <CardOverview className="marketplace-about__provider" title="Model Provider">
        <h4>{companyName}</h4>
        <div className="marketplace-about__company__logo">
          <img src={logoUrl} alt={companyName} />
        </div>
        <p className="marketplace-about__company__about">{about}</p>
      </CardOverview>
    </div>
    <div className="col-7">
      <CardOverview className="marketplace-about__model w58" title="This Model">
        <div>
          <p className="marketplace-about__model__about">{providerDescription}</p>
          <div className="marketplace-about__model__type">{type}</div>
          {inceptionDate && (
            <div>
              Inception date:{' '}
              <span className="marketplace-about__model__inception-date">{inceptionDate}</span>
            </div>
          )}
        </div>
        <div>
          <div className="top-divider">
            <div className="col-6">
              <RiskOverall score={prismScore} smaller />
            </div>
            <div className="col-6">
              <PositionsAnalysisTable positions={positions} />
            </div>
          </div>
        </div>
        <SecurityTypeConcentration positions={positions} />
      </CardOverview>
    </div>
  </div>
);

About.defaultProps = {
  marketplace: {
    name: 'PROVIDER NAME',
    description: 'PROVIDER DESCRIPTION',
    company: {
      name: 'COMPANY NAME',
      logo: 'COMPANY LOGO URL',
      about: 'COMPANY ABOUT'
    },
    prism_score_summary: {
      overall: 0
    },
    type: 'PROVIDER TYPE',
    inception_date: 'PROVIDER INCEPTION DATE',
    positions: []
  }
};

About.propTypes = {
  marketplace: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    company: PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.string,
      about: PropTypes.string
    }),
    prism_score_summary: PropTypes.shape({
      overall: PropTypes.number
    }),
    type: PropTypes.string,
    inception_date: PropTypes.string,
    positions: PropTypes.array
  })
};

const AboutWithRouter = withRouter(About);

export default connect(state => ({
  marketplace: state.models.currentMarketplace
}))(AboutWithRouter);
