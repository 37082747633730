import React from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router';
import config from '../config';

const lookup = (input, maxmin, updown) => {
  const up = updown === 'up';
  const min = maxmin === 'min' ? 1 : 0;
  const rawScores = [8.1, 6.1, 4.1, 2.1];
  const percentages = [0.5, 0.4, 0.3, 0.2, 0.1, 0];
  const index = rawScores.findIndex(rawScore => rawScore < input);
  return percentages[index + min] / (up ? percentages[1] : percentages[0]);
};

export const downUpCaptures = (volatility, tail) => {
  const upCaptureMin = lookup(volatility, 'min', 'up');
  const upCaptureMax = lookup(volatility, 'max', 'up');
  const downCaptureMin = lookup(tail, 'min', 'down');
  const downCaptureMax = lookup(tail, 'max', 'down');

  const upCapture = Math.max(upCaptureMin, upCaptureMax);
  const downCapture = Math.max(downCaptureMin, downCaptureMax);
  return [upCapture || 0, downCapture || 0];
};

export const triggerAccountPrismScore = (
  id,
  accountProvider,
  updateStorePromises = () => [],
  reCalculate = false,
  event = null,
  showButton = false,
  account = null
) => {
  if (event && event.type === 'click') {
    event.preventDefault();
    event.stopPropagation();
  }

  if (!(id && accountProvider)) {
    toast.error(() => <div>Sorry, something went wrong.</div>);
    return;
  }

  setTimeout(() => {
    toast.info(() => <div>{reCalculate ? 'Re-Calculating' : 'Calculating'} PRISM Score.</div>);
  }, 1500);

  accountProvider
    .updatePrism(id)
    .then(() => {
      Promise.all(updateStorePromises()).then(() => {
        const text = account
          ? `PRISM score for ${account.display_name} was successfully calculated.`
          : 'PRISM score successfully calculated.';

        toast.success(() => (
          <div>
            {showButton ? (
              <>
                {text}
                <br />
                <Link
                  className="btn btn-primary btn-small"
                  to={`/advisor/${account?.investor.is_prospect ? 'prospects' : 'investors'}/${
                    account?.investor.id
                  }/account/${account.id}`}
                >
                  View
                </Link>
              </>
            ) : (
              text
            )}
          </div>
        ));
      });
    })
    .catch(error => {
      toast.error(() => (
        <div>
          {error.message === 'No valid positions'
            ? 'Please add prospective value to see the PRISM rating'
            : 'Error calculating the PRISM rating'}
        </div>
      ));
    });
};

export const prepareMedianPortfolioMove = (portfolio, market) =>
  portfolio.map((_, i) => ({
    title: portfolio[i][0],
    portfolio: portfolio[i][1] * 100,
    market: market[i][1] * 100
  }));

export const prepareCorrelation = (benchmarksMin, benchmarksMax) =>
  Object.keys(benchmarksMin).map(key => ({
    title: key,
    min: benchmarksMin[key],
    max: benchmarksMax[key]
  }));

export const prepareAssetDiversification = classification =>
  Object.keys(classification).map(key => ({
    title: config.asset.type.labels[key],
    value: classification[key] * 100
  }));

export const prepareVolDiversification = (short, long) => [
  {
    title: 'Negatively Correlated To Volatility',
    value: short * 100,
    color: config.chart.pie.volatilityDiversification.colors[0]
  },
  {
    title: 'Positively Correlated To Volatility',
    value: long * 100,
    color: config.chart.pie.volatilityDiversification.colors[1]
  }
];
