import SectionLocked from 'components/advisor/section-locked';
import { REGION_EXPOSURE, SECTION_LOCKED_MAP } from 'components/advisor/section-locked/utils';
import MapChart from 'components/charts/world-chart';
import CollapsibleTable from 'components/collapsible-table';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import DisclaimerNote from '../common/disclaimer-note';
import {
  getSecurityDetails,
  hasSecurityDetails,
  PORTFOLIO_WITHOUT_SECURITY_DETAILS,
  processSecurityDetails,
  SECURITY_REGIONS
} from '../common/utils';
import './styles.scss';
import { GEOGRAPHIC_EXPOSURE_CHART_ID } from './utils';

const GeographicExposure = ({
  breakdownCustomSecurities,
  hiddenVal,
  id,
  onChartReady,
  portfolio,
  width
}) => {
  const {
    user: {
      advisor: {
        company: {
          expand_asset_details: expandAssetDetails,
          region_exposure_enabled: regionExposureEnabled
        }
      }
    }
  } = useContext(AdvisorContext);

  const { name, portfolioRiskId } = SECTION_LOCKED_MAP[REGION_EXPOSURE];

  if (!regionExposureEnabled)
    return (
      <div id="geographic-exposure">
        <SectionLocked id={portfolioRiskId} name={name} />
      </div>
    );

  if (!hasSecurityDetails(portfolio, SECURITY_REGIONS, breakdownCustomSecurities))
    return (
      <div id="geographic-exposure">
        <p>{PORTFOLIO_WITHOUT_SECURITY_DETAILS}</p>
      </div>
    );

  const totalAssets = portfolio.value ?? 0;
  const securityRegions = getSecurityDetails(
    portfolio,
    SECURITY_REGIONS,
    totalAssets,
    breakdownCustomSecurities
  );

  const { data, total: totalSecurities } = processSecurityDetails(
    securityRegions,
    SECURITY_REGIONS,
    totalAssets,
    null,
    true
  );

  return (
    <div id="geographic-exposure">
      <div className="chart-container">
        <MapChart id={id} data={data.chart} onChartReady={onChartReady} width={width} />
      </div>
      <div className="collapsible-container">
        <CollapsibleTable
          bottomNameLabel="Total Regions"
          data={data.summary}
          expandAll
          expandAssetClassification={expandAssetDetails}
          headingLabels={{ name: '', value: 'Value', percent: '% of Portfolio' }}
          hiddenVal={hiddenVal}
          showBottomSection
          showHeadingRow
          showPercent
        />
        <DisclaimerNote totalAssets={totalAssets} totalSecurities={totalSecurities} />
      </div>
    </div>
  );
};

GeographicExposure.propTypes = {
  breakdownCustomSecurities: PropTypes.bool,
  hiddenVal: PropTypes.bool,
  id: PropTypes.string,
  onChartReady: PropTypes.func,
  portfolio: PropTypes.object.isRequired,
  width: PropTypes.string
};

GeographicExposure.defaultProps = {
  breakdownCustomSecurities: false,
  hiddenVal: false,
  id: GEOGRAPHIC_EXPOSURE_CHART_ID,
  onChartReady: null,
  width: '100%'
};

export default GeographicExposure;
