import {
  IndicatorsContainer,
  MenuList,
  Option,
  SingleValue
} from 'components/advisor/generic-selector';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import AsyncSelect from 'react-select/async';
import './styles.scss';

const normalizeInvestorOptions = (investors, targetInvestorId) => {
  if (!investors || !investors.length) return [];
  return investors
    .filter(investor => investor.id !== targetInvestorId)
    .sort((a, b) => a.name.localeCompare(b.name)) // sorting by name
    .map(investor => ({
      value: investor.id,
      label: investor.name,
      score: investor.prism_overall ? investor.prism_overall.toFixed(1) : null,
      id: investor.id,
      full_name: investor.name,
      accounts: investor.total_accounts,
      household: investor.household_name ?? '-',
      origin: investor.origin ?? '-',
      is_prospect: investor.is_prospect
    }));
};

const ProspectInvestorAsyncSelect = ({ filteredInvestorId, onChange }) => {
  const { investorProvider, prospectProvider } = useContext(AdvisorContext);

  const getClientData = provider => q =>
    provider.es
      .list({ search: q })
      .then(({ results }) => normalizeInvestorOptions(results, filteredInvestorId));

  const getInvestors = getClientData(investorProvider);
  const getProspects = getClientData(prospectProvider);

  const loadOptions = (inputValue, callback) => {
    Promise.all([getInvestors(inputValue), getProspects(inputValue)]).then(
      ([investors, prospects]) => {
        callback([
          { label: 'Clients', options: investors },
          { label: 'Prospects', options: prospects }
        ]);
      }
    );
  };

  return (
    <AsyncSelect
      className="investor-select"
      components={{ MenuList, Option, SingleValue, IndicatorsContainer }}
      isSearchable
      loadOptions={loadOptions}
      onChange={onChange}
      placeholder="Select an investor"
    />
  );
};

ProspectInvestorAsyncSelect.propTypes = {
  filteredInvestorId: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

ProspectInvestorAsyncSelect.defaultProps = {
  filteredInvestorId: 0
};

export default ProspectInvestorAsyncSelect;
