import SectorExposureDistribution from 'components/advisor/proposal/securities/sector-exposure-distribution/pdf';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import BaseSection from 'reports/base/sections/base';
import { IPSPropTypes, MetadataPropTypes } from '../../types';

const IPSSectorExposure = ({ breakSection, ips: { proposal }, metadata }) => {
  const {
    user: {
      advisor: {
        company: { sector_exposure_enabled: sectorExposureEnabled }
      }
    }
  } = useContext(AdvisorContext);

  if (!sectorExposureEnabled) return null;

  const breakdownCustomSecurities =
    metadata.customActions?.breakdownCustomSecurities?.value ?? true;
  const distributionProps = { target: proposal.target, targetName: proposal.target_label };
  const hasRecommended = !!proposal.recommended;

  if (hasRecommended) {
    distributionProps.recommendedName = proposal.recommended_label;
    distributionProps.recommended = proposal.recommended;
  }

  return (
    <BaseSection body={metadata.body} title={metadata.title} breakSection={breakSection} wrap>
      <SectorExposureDistribution
        breakdownCustomSecurities={breakdownCustomSecurities}
        proposal={proposal}
        {...distributionProps}
      />
    </BaseSection>
  );
};

IPSSectorExposure.propTypes = {
  breakSection: PropTypes.bool,
  ips: PropTypes.shape(IPSPropTypes).isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired
};

IPSSectorExposure.defaultProps = {
  breakSection: false
};

export default IPSSectorExposure;
