import ModelPortfolioBenchmark from 'components/advisor/model-portfolio-benchmark';
import PrismRiskAnalysis from 'components/advisor/risk-analysis/prism-risk-analysis';
import SecurityTypeConcentration from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration';
import { SECURITY_TYPE_CONCENTRATION_CHART_ID } from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration/constants';
import TopRiskAttribution from 'components/advisor/risk-analysis/risk-analysis-target/top-risk-attribution';
import ScenariosScores from 'components/advisor/risk-analysis/scenarios-scores';
import GeographicExposure from 'components/advisor/risk-analysis/securities/geographic-exposure';
import { GEOGRAPHIC_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/geographic-exposure/utils';
import SectorExposure from 'components/advisor/risk-analysis/securities/sector-exposure';
import { SECTOR_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/sector-exposure/utils';
import FocusedPrismFactorsChart from 'components/charts/focused-prism-factors-chart';
import {
  GEOGRAPHIC_EXPOSURE_TYPE,
  SECTOR_EXPOSURE_TYPE
} from 'components/form/breakdown-custom-securities-toggle-title/constants';
import PrismRating from 'components/utils/prism-rating';
import PrismRatingUpsideDownside from 'components/utils/prism-rating-upside-downside';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { ACCOUNT_TARGET_TYPE, MODEL_TARGET_TYPE } from '../../constants';
import { getScenarios, getScoreFactors } from './utils';

const RiskAnalysisContent = ({
  benchmark,
  portfolio,
  portfolioBreakdownCustomSecurities,
  riskAnalysis,
  type
}) => {
  const {
    accountProvider,
    modelProvider,
    user: {
      advisor: {
        company,
        company: { hide_positions: hidePositions }
      }
    }
  } = useContext(AdvisorContext);

  const { investor, most_risky_positions: riskyPositions } = portfolio;
  const { scenarios, scenariosPerformance } = getScenarios(riskAnalysis);
  const {
    concentrated,
    volatility,
    correlation,
    tail,
    overall: prismOverall
  } = getScoreFactors(riskAnalysis);

  let targetSummary = null;
  let targetOverall = null;
  if (riskAnalysis.custom) {
    targetSummary = portfolio.target_score_summary;
    targetOverall = targetSummary ? targetSummary.overall : null;
  }

  const onChartReady = useCallback(
    (id, data) => {
      if (type === MODEL_TARGET_TYPE) modelProvider.saveChartImage({ [id]: data });
      else accountProvider.saveChartImage({ [id]: data });
    },
    [JSON.stringify(portfolio), type]
  );

  const sectorExposureChartId =
    type === MODEL_TARGET_TYPE
      ? SECTOR_EXPOSURE_CHART_ID
      : `${SECTOR_EXPOSURE_CHART_ID}-${portfolio.id}`;
  const geographicExposureChartId =
    type === MODEL_TARGET_TYPE
      ? GEOGRAPHIC_EXPOSURE_CHART_ID
      : `${GEOGRAPHIC_EXPOSURE_CHART_ID}-${portfolio.id}`;
  const securityTypeConcentrationChartId =
    type === MODEL_TARGET_TYPE
      ? SECURITY_TYPE_CONCENTRATION_CHART_ID
      : `${SECURITY_TYPE_CONCENTRATION_CHART_ID}-${portfolio.id}`;

  return (
    <div className="risk-analysis-content">
      {riskAnalysis.custom && (
        <div className="result-container prism-container">
          <div className="result-heading">
            Portfolio Risk <span className="sub-text">Powered By</span> PRISM Rating &trade;
          </div>
          {type === ACCOUNT_TARGET_TYPE && (
            <PrismRating
              prismSummary={{
                concentrated: concentrated.value,
                volatility: volatility.value,
                correlation: correlation.value,
                tail: tail.value,
                overall: prismOverall
              }}
              targetSummary={targetSummary}
              value={portfolio.value}
            />
          )}
          {type === MODEL_TARGET_TYPE && portfolio.prism_score_summary && (
            <PrismRatingUpsideDownside
              prismSummary={portfolio.prism_score_summary}
              targetSummary={targetSummary}
              value={portfolio.value}
              market={{
                up: company.market_upside_performance,
                down: company.market_downside_performance
              }}
            />
          )}
        </div>
      )}

      {riskAnalysis.custom &&
        benchmark &&
        !!benchmark.length &&
        _.some(
          benchmark,
          b => !_.isEmpty(b.prism_score_summary) && !!b.prism_score_summary.overall
        ) && (
          <div>
            <ModelPortfolioBenchmark
              benchmark={benchmark}
              prismOverall={prismOverall}
              targetOverall={targetOverall}
            />
          </div>
        )}

      {/* Used only to take the image of the chart that is needed for @react-pdf/renderer */}
      {!_.isEmpty(portfolio.positions) && (
        <div
          className="security-type-concentration-section hide-from-report"
          style={{ width: 1000, height: 200 }}
        >
          <SecurityTypeConcentration
            id={securityTypeConcentrationChartId}
            onChartReady={onChartReady}
            positions={portfolio.positions}
            totalAssets={portfolio.value}
          />
        </div>
      )}

      {!_.isEmpty(riskyPositions) && !hidePositions && (
        <div className="top-risk-attribution-section">
          <h3>Top Risk Attributions</h3>
          <TopRiskAttribution
            investor={investor}
            riskyPositions={riskyPositions}
            positions={portfolio.positions}
            type={type}
          />
        </div>
      )}

      {riskAnalysis.custom && (
        <div className="scores-container">
          <h2>PRISM Risk Analysis</h2>
          <PrismRiskAnalysis
            portfolio={portfolio}
            prismSummary={{ concentrated, correlation, tail, volatility }}
            targetSummary={targetSummary}
            type={type}
          />
        </div>
      )}

      {type === MODEL_TARGET_TYPE && portfolio.prism_score_summary && (
        <div className="focused-prism-factors-container box-container">
          <div className="box-content">
            <FocusedPrismFactorsChart targetSummary={portfolio.prism_score_summary} />
          </div>
        </div>
      )}

      {riskAnalysis.custom && (
        <div className="score-market-container">
          <div className="heading">
            <h2>PRISM Analysis for Market Scenarios</h2>
          </div>
          <ScenariosScores
            performance={scenariosPerformance}
            scenarios={scenarios}
            value={portfolio.value}
          />
        </div>
      )}

      {/* Used only to take the image of the chart that is needed for @react-pdf/renderer */}
      <div className="hide-from-report">
        <SectorExposure
          breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
            SECTOR_EXPOSURE_TYPE
          )}
          id={sectorExposureChartId}
          onChartReady={onChartReady}
          portfolio={portfolio}
          width="1000px"
        />
      </div>

      {/* Used only to take the image of the chart that is needed for @react-pdf/renderer */}
      <div className="hide-from-report">
        <GeographicExposure
          breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
            GEOGRAPHIC_EXPOSURE_TYPE
          )}
          id={geographicExposureChartId}
          onChartReady={onChartReady}
          portfolio={portfolio}
          width="1000px"
        />
      </div>
    </div>
  );
};

RiskAnalysisContent.defaultProps = {
  benchmark: [],
  portfolioBreakdownCustomSecurities: []
};

RiskAnalysisContent.propTypes = {
  benchmark: PropTypes.array,
  portfolio: PropTypes.object.isRequired,
  portfolioBreakdownCustomSecurities: PropTypes.array,
  riskAnalysis: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

export default React.memo(RiskAnalysisContent);
