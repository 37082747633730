/* eslint-disable react/no-array-index-key */
import cn from 'classnames';
import { POSITIONS_BY_ACCOUNT } from 'containers/advisor/ai-assistant/constants';
import { ExpandCollapseButton, renderFormattedValue } from 'containers/advisor/ai-assistant/utils';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DataToAccountTransform from '../../transform-data-to-create-account';
import DataToModelTransform from '../../transform-data-to-create-model';
import { MAX_COLLAPSED_TABLE_ROWS, MAX_ROWS_THRESHOLD } from '../constants';
import '../styles.scss';

const formatKey = key => key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());

const validElement = element =>
  element !== null && element !== 0 && element !== 'None' && element !== '';

const TableWithHeader = ({ item, extraData, scannedDataKey, marketStore }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Calculate the dynamic total value
  const totalValue = item.positions.reduce(
    (acc, position) => acc + (position[extraData.fields_mapping.amount] ?? 0),
    0
  );

  const itemWithTotalValue = {
    ...item,
    total_value: totalValue
  };

  const headerData = Object.entries(itemWithTotalValue).filter(
    ([, val]) => !Array.isArray(val) && validElement(val)
  );

  const nestedArray = Object.entries(itemWithTotalValue).find(([, val]) => Array.isArray(val));
  const nestedArrayData = nestedArray ? nestedArray[1] : [];
  const validColumns = Object.keys(nestedArrayData[0] || {}).filter(key =>
    nestedArrayData.some(item => validElement(item[key]))
  );

  const customFormatting = extraData?.custom_formatting || {};
  const fieldsMapping = extraData?.fields_mapping || {};
  const hasTenOrMoreRows = nestedArrayData.length >= MAX_ROWS_THRESHOLD;
  const rowsToDisplay = isExpanded
    ? nestedArrayData
    : nestedArrayData.slice(0, MAX_COLLAPSED_TABLE_ROWS);

  return (
    <div>
      <div className="card ai-assistant-default-card">
        <div className="table-with-header">
          <div className="header-section">
            {headerData.map(([key, val]) => {
              const { formattedValue } = renderFormattedValue(key, val, customFormatting);
              return (
                <div key={key}>
                  {formatKey(key)}:
                  <span className="scan-iq__default-table__span"> {formattedValue}</span>{' '}
                </div>
              );
            })}
          </div>

          {nestedArrayData.length > 0 && (
            <table border="1" cellPadding="7" cellSpacing="0" className="scan-default__table">
              <thead>
                <tr>
                  {validColumns.map(key => {
                    const { align } = renderFormattedValue(key, null, customFormatting);
                    return (
                      <th
                        key={key}
                        className={cn({
                          'align-right': align === 'right',
                          'align-left': align === 'left',
                          'align-center': align === 'center'
                        })}
                      >
                        {formatKey(key)}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {rowsToDisplay.map((nestedItem, nestedIndex) => (
                  <tr key={nestedIndex}>
                    {validColumns.map(key => {
                      const { formattedValue, align } = renderFormattedValue(
                        key,
                        nestedItem[key],
                        customFormatting
                      );
                      return (
                        <td
                          key={key}
                          className={cn({
                            'align-right': align === 'right',
                            'align-left': align === 'left',
                            'align-center': align === 'center'
                          })}
                        >
                          {validElement(nestedItem[key]) ? formattedValue : '-'}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <div className="ai-assistant-scan__btn-view-container">
        <div className="ai-assistant-scan-table-with-header__separete-btn">
          <ExpandCollapseButton
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            hasTenOrMoreRows={hasTenOrMoreRows}
          />
          {scannedDataKey === POSITIONS_BY_ACCOUNT && (
            <div className="ai-assistant-scan__btn-container">
              <DataToAccountTransform
                fieldsMapping={fieldsMapping}
                marketStore={marketStore}
                portfolio={itemWithTotalValue}
              />
              <DataToModelTransform
                fieldsMapping={fieldsMapping}
                marketStore={marketStore}
                portfolio={itemWithTotalValue}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

TableWithHeader.propTypes = {
  item: PropTypes.object.isRequired,
  extraData: PropTypes.object.isRequired,
  scannedDataKey: PropTypes.string.isRequired,
  marketStore: PropTypes.object.isRequired
};

export default TableWithHeader;
