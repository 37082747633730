import cn from 'classnames';
import CreateNewClientForm from 'components/advisor/investors/create-form';
import AccountEdit, { modes } from 'components/advisor/investors/edit/index';
import ProspectInvestorAsyncSelect from 'components/advisor/prospect-investor-async-select';
import Choice from 'components/form/choice';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import { PROCESSING_POSITIONS_STEP } from 'components/utils/csv-wizard/constants';
import { AdvisorContext } from 'containers/advisor';
import { SCAN_IQ_SCOPE } from 'containers/advisor/ai-assistant/constants';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { triggerAccountPrismScore } from 'utils/prism';
import './styles.scss';

const INITIAL_STEP = 1;
const SELECT_PROSPECT_OR_INVESTOR_STEP = 2;
const CREATE_PROSPECT_OR_INVESTOR_STEP = 3;
const CREATE_ACCOUNT_STEP = 4;

const DataToAccountTransform = ({
  advisors,
  fieldsMapping,
  households,
  marketStore,
  portfolio
}) => {
  const { accountProvider, errorsProvider, routerActions, user } = useContext(AdvisorContext);

  const [investor, setInvestor] = useState(null);
  const [isProspectCreation, setIsProspectCreation] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [step, setStep] = useState(INITIAL_STEP);

  if (!portfolio || !portfolio?.positions?.length) return null;

  const positions = portfolio.positions.map(position => ({
    ticker: position[fieldsMapping.ticker],
    amount: position[fieldsMapping.amount]
  }));

  const hide = () => {
    setStep(INITIAL_STEP);
    setInvestor(null);
    setIsShown(false);
  };
  const show = () => setIsShown(true);

  const changeStep = step => () => {
    setStep(step);
  };

  const getPrismScore = (accountId, value, account) => {
    triggerAccountPrismScore(accountId, accountProvider, () => [], false, null, true, account);
  };

  const onCreateInvestor = investor => {
    setInvestor(investor);
    setStep(CREATE_ACCOUNT_STEP);
  };

  const onToggleProspectCreation = () => {
    setIsProspectCreation(prevCreateProspect => !prevCreateProspect);
  };

  useEffect(() => {
    if (step === INITIAL_STEP) setInvestor(null);
  }, [step]);

  return (
    <>
      <button type="button" className="btn btn-secondary btn-small" onClick={show}>
        Create an Account
      </button>

      <Modal
        className={cn('modal-lg', { 'modal-xl': step === CREATE_ACCOUNT_STEP })}
        id="data-to-account-transform-modal"
        onHidden={hide}
        show={isShown}
      >
        {step === CREATE_ACCOUNT_STEP && <ModalHeader />}

        <ModalBody>
          {step === INITIAL_STEP && (
            <div className="initial-step">
              <h2 className="modal-title">Who is the account for?</h2>
              <div className="modal-actions">
                <button
                  className="btn btn-primary font-weight-normal"
                  onClick={changeStep(SELECT_PROSPECT_OR_INVESTOR_STEP)}
                  type="button"
                >
                  An existing client or prospect
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={changeStep(CREATE_PROSPECT_OR_INVESTOR_STEP)}
                >
                  A new client or prospect
                </button>
              </div>
            </div>
          )}

          {step === SELECT_PROSPECT_OR_INVESTOR_STEP && (
            <div className="select-prospect-or-investor-step">
              <h2 className="modal-title">
                Select the prospect or investor to whom the account will be assigned
              </h2>
              <ProspectInvestorAsyncSelect onChange={setInvestor} />
              <div className="modal-actions">
                <button
                  className="btn btn-link text-dark font-weight-normal px-0"
                  onClick={changeStep(INITIAL_STEP)}
                  type="button"
                >
                  Back
                </button>
                <button
                  className="btn btn-primary font-weight-normal"
                  disabled={!investor}
                  onClick={changeStep(CREATE_ACCOUNT_STEP)}
                  type="button"
                >
                  Continue
                </button>
              </div>
            </div>
          )}

          {step === CREATE_PROSPECT_OR_INVESTOR_STEP && (
            <div id="create-new-client" className="create-prospect-or-investor-step">
              <h2 className="modal-title">Who is the account for?</h2>
              <Choice
                checked={isProspectCreation}
                title="Is a prospect?"
                toggle={onToggleProspectCreation}
              />
              <CreateNewClientForm
                advisors={advisors}
                errorsProvider={errorsProvider}
                households={households}
                onBack={changeStep(INITIAL_STEP)}
                onCreate={onCreateInvestor}
                routerActions={routerActions}
                scope={SCAN_IQ_SCOPE}
                type={isProspectCreation ? 'prospect' : 'investor'}
                user={user}
              />
            </div>
          )}

          {step === CREATE_ACCOUNT_STEP && (
            <AccountEdit
              account={{}} // avoids problems with the existing logic in the component when using the creation mode
              hideEditModel={hide}
              initialValues={{ name: portfolio.account_name || '' }}
              investor={investor}
              marketStore={marketStore}
              mode={modes.CREATE}
              positions={marketStore.securities.positions}
              reCalculatePrism={getPrismScore}
              scope={SCAN_IQ_SCOPE}
              wizardCSV={{
                data: positions,
                headerRows: ['ticker', 'amount'],
                show: true,
                step: PROCESSING_POSITIONS_STEP
              }}
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

DataToAccountTransform.propTypes = {
  advisors: PropTypes.array,
  fieldsMapping: PropTypes.object,
  households: PropTypes.array,
  marketStore: PropTypes.object.isRequired,
  portfolio: PropTypes.object
};

DataToAccountTransform.defaultProps = {
  advisors: [],
  fieldsMapping: {},
  households: [],
  portfolio: {}
};

export default connect(state => ({
  advisors: state.advisors.esList,
  households: state.households.list
}))(DataToAccountTransform);
