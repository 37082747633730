import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { getIconComponent } from '../../../utils';

const DynamicScrollCardIndex = ({ extractionFunctions, jsonData }) => {
  // Filter out extraction functions that do not have data in jsonData
  const availableFunctions = extractionFunctions.filter(func =>
    Object.prototype.hasOwnProperty.call(jsonData, func.slug)
  );

  const scrollToComponent = id => {
    const element = document.getElementById(id);
    if (element) {
      const headerOffset = 70;
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerOffset;

      const elementRect = element.getBoundingClientRect();
      const elementHeight = elementRect.height;
      const isLargeElement = elementHeight > 800;

      window.history.replaceState(null, null, `#${id}`);
      if (isLargeElement) window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
      else element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  return (
    <div className="dynamic-scroll-card-index">
      {availableFunctions.map(extractionFunction => (
        <button
          key={extractionFunction.slug}
          type="button"
          className="btn card extractioed-index-btn"
          onClick={() => scrollToComponent(extractionFunction.slug)}
        >
          {extractionFunction.extra_data?.icon && (
            <span className="icon-wrapper">
              {getIconComponent(extractionFunction.extra_data.icon)}
            </span>
          )}
          <span className="button-text">{extractionFunction.name}</span>
        </button>
      ))}
    </div>
  );
};

DynamicScrollCardIndex.propTypes = {
  extractionFunctions: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      extra_data: PropTypes.shape({
        icon: PropTypes.string
      })
    })
  ).isRequired,
  jsonData: PropTypes.object.isRequired
};

export default DynamicScrollCardIndex;
