/* eslint-disable no-underscore-dangle */
import cn from 'classnames';
import { SECTION_LOCKED_MAP, TOP_HOLDINGS } from 'components/advisor/section-locked/utils';
import DateValue from 'components/advisor/proposal/body/commons/date-value-label';
import { SECURITY_UNDERLYING_MODEL } from 'components/advisor/risk-analysis/securities/common/utils';
import SectionLocked from 'components/advisor/section-locked';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { formatPercentage, getTaxonomyLevelIterator } from 'utils/utils';
import Row from '../common/row';
import '../common/styles.scss';
import {
  buildCollapsibleMap,
  getCollapsedCategories,
  getDistributionData,
  getLevelPercent
} from '../common/utils';

const TopHoldingsDistribution = ({
  benchmark,
  benchmarkName,
  breakdownCustomSecurities,
  isDraft,
  recommended,
  recommendedName,
  setCollapsedTopHoldings,
  start,
  startingValue,
  target,
  targetName
}) => {
  const {
    user: {
      advisor: {
        company: {
          expand_asset_details: expandAssetDetails,
          top_holdings_enabled: topHoldingsEnabled
        }
      }
    }
  } = useContext(AdvisorContext);

  const [collapsibleMap, setCollapsibleMap] = useState({});

  const { name, proposalIpsId } = SECTION_LOCKED_MAP[TOP_HOLDINGS];

  if (!topHoldingsEnabled)
    return (
      <div id="top-holdings-distribution">
        <SectionLocked id={proposalIpsId} name={name} />
      </div>
    );

  const {
    distributionTable,
    target: { value: targetValue, total: targetProcessedTotal },
    recommended: { value: recommendedValue, total: recommendedProcessedTotal },
    benchmark: { value: benchmarkValue, total: benchmarkProcessedTotal }
  } = getDistributionData(
    target,
    recommended,
    benchmark,
    SECURITY_UNDERLYING_MODEL,
    breakdownCustomSecurities
  );

  const toggleRow = id => {
    setCollapsibleMap(prevCollapsibleMap => ({
      ...prevCollapsibleMap,
      [id]: !prevCollapsibleMap[id]
    }));
  };

  useEffect(() => {
    const collapsibleMap = buildCollapsibleMap(distributionTable, true);
    setCollapsibleMap(collapsibleMap);
  }, []);

  useEffect(() => {
    setCollapsedTopHoldings(getCollapsedCategories(collapsibleMap));
  }, [JSON.stringify(collapsibleMap)]);

  return (
    <div id="top-holdings-distribution">
      <DateValue proposal={{ start, starting_value: startingValue }} />

      <div
        className={cn('fancy-grid', 'holdings', {
          'no-recommended': !recommended,
          'no-benchmark': !benchmark
        })}
      >
        <div className="transparent" />
        {target && <div className="header">{targetName || 'Current Portfolio'}</div>}
        {recommended && <div className="header">{recommendedName}</div>}
        {benchmark && <div className="header">{benchmarkName}</div>}
      </div>

      <div className="top-holdings-table">
        {getTaxonomyLevelIterator(distributionTable).map(([l1Key, l1], l1Idx) => (
          <Fragment key={`l1-${l1.__id ?? l1Idx}`}>
            <Row
              className="row-title depth-1"
              collapsed={expandAssetDetails && collapsibleMap[l1Key]}
              color={l1.__color}
              isDraft={isDraft}
              label={l1Key}
              toggleRow={expandAssetDetails ? toggleRow : null}
              target={getLevelPercent(l1.__target_value, targetValue)}
              recommended={
                recommended ? getLevelPercent(l1.__recommended_value, recommendedValue) : null
              }
              benchmark={benchmark ? getLevelPercent(l1.__benchmark_value, benchmarkValue) : null}
            />

            {expandAssetDetails &&
              !collapsibleMap[l1Key] &&
              getTaxonomyLevelIterator(l1).map(([l2Key, l2], l2Idx) => (
                <Fragment key={`l2-${l2.__id ?? `${l1Idx}-${l2Idx}`}`}>
                  <Row
                    className="depth-2"
                    collapsed={expandAssetDetails && collapsibleMap[l2Key]}
                    isDraft={isDraft}
                    label={l2Key}
                    target={getLevelPercent(l2.__target_value, targetValue)}
                    recommended={
                      recommended ? getLevelPercent(l2.__recommended_value, recommendedValue) : null
                    }
                    benchmark={
                      benchmark ? getLevelPercent(l2.__benchmark_value, benchmarkValue) : null
                    }
                  />
                </Fragment>
              ))}
          </Fragment>
        ))}

        <div className="row depth-1 row-total">
          <div className="col category">Total Portfolio</div>
          <div className="col">
            {isDraft && <div className="subcol diff" />}
            <div className="subcol">{formatPercentage(targetProcessedTotal / targetValue)}</div>
          </div>
          {recommended && (
            <div className="col">
              {!isDraft && <div className="subcol diff" />}
              <div className="subcol">
                {formatPercentage(
                  recommendedValue ? recommendedProcessedTotal / recommendedValue : 0
                )}
              </div>
            </div>
          )}
          {benchmark && (
            <div className="col">
              {formatPercentage(benchmarkValue ? benchmarkProcessedTotal / benchmarkValue : 0)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

TopHoldingsDistribution.propTypes = {
  benchmark: PropTypes.object,
  benchmarkName: PropTypes.string,
  breakdownCustomSecurities: PropTypes.bool,
  isDraft: PropTypes.bool,
  recommended: PropTypes.object,
  recommendedName: PropTypes.string,
  setCollapsedTopHoldings: PropTypes.func,
  start: PropTypes.string,
  startingValue: PropTypes.number,
  target: PropTypes.object,
  targetName: PropTypes.string
};

TopHoldingsDistribution.defaultProps = {
  benchmark: null,
  benchmarkName: '',
  breakdownCustomSecurities: true,
  isDraft: false,
  recommended: null,
  recommendedName: '',
  setCollapsedTopHoldings: () => {},
  start: null,
  startingValue: null,
  target: null,
  targetName: ''
};

export default TopHoldingsDistribution;
