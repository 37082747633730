export const MODEL_TYPES = [
  'Aggressive',
  'Moderate Aggressive',
  'Moderate',
  'Moderate Conservative',
  'Conservative'
];

export const STRATEGY_MESSAGE =
  "When set as a strategy and matched to an account, the score of this model will be used for the account's drift calculation and proposal generation, and not the score from the holdings in it";

export const COMPANY_SHARING_OPTION = { label: 'Company', value: 0 };

export const PRIVATE_VISIBILITY = 'private';
export const PUBLIC_VISIBILITY = 'public';

export const EXCLUDED_MESSAGE = {
  TOOLTIP_MESSAGE: 'This position is excluded from risk monitoring.',
  TOOLTIP_HAS_PERMISSIONS: 'Click the + icon at the end of the row to include it.',
  TOOLTIP_NO_PERMISSIONS: 'Contact your company administrator for details.',
  ACTION_EXCLUDE_TOOLTIP: 'Exclude this position from the risk monitoring for this account.',
  ACTION_INCLUDE_TOOLTIP: 'Include this position in the risk monitoring for this account.'
};

export const MODEL_COPY_SUFFIX = '(Copy)';

export const MODEL_PORTFOLIO_CREATION_MESAGGE = name =>
  `🎊 The model portfolio ${name} was created successfully.`;

export const MODEL_PORTFOLIO_CALCULATING_RISK_MESAGGE =
  'Calculating Risk for the new model portfolio.';

export const MODEL_PORTFOLIO_CREATION_ERROR_MESAGGE =
  'Something went wrong. Please try again or contact your administrator.';

export const INVALID_POSITIONS = 'No valid positions';
export const RISK_ANALYSIS_URL_REGEX = /\/advisor\/models\/\d+\/risk-analysis/;
