import AddressForm, { validateAddress } from 'components/advisor/address-form';
import CompanyCcEmails from 'components/advisor/me-company-cc-emails';
import ColorPicker from 'components/color-picker';
import Disclosure from 'components/disclosure';
import Dropzone from 'components/dropzone';
import { VerboseErrorInput as Input } from 'components/form';
import Choice from 'components/form/choice';
import { US_CODE } from 'components/form/country-drop-down';
import PhoneField from 'components/form/phone-field';
import { AdvisorContext } from 'containers/advisor';
import { RequestAction } from 'components/advisor/section-locked';
import { BackendValidation, propTypesCheck } from 'hocs/backend-validation';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect } from 'react';
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';
import './styles.scss';

const DEFAULT_PRIMARY_COLOR = '#4ea9f2';
const DEFAULT_SECONDARY_COLOR = '#000000';

const validate = values => {
  const errors = {};
  errors.name = errors.name || validation.required(values.name);
  errors.phone =
    errors.phone ||
    validation.required(values.phone) ||
    validation.internationalPhone(values.phone);

  errors.website = errors.website || validation.isWebsite(values.website);

  errors.brand_primary_color =
    errors.brand_primary_color ||
    (values.brand_primary_color ? validation.hexColor(values.brand_primary_color) : null);

  errors.brand_secondary_color =
    errors.brand_secondary_color ||
    (values.brand_secondary_color ? validation.hexColor(values.brand_secondary_color) : null);

  errors.address = validateAddress(values.address);
  return errors;
};

const CompanyProfile = ({ errors, fields, handleSubmit, initializeForm, registerError }) => {
  const { authProvider, companyProvider, user, userProvider } = useContext(AdvisorContext);
  const disabledBrandColor = !userProvider.isBusinessOrAbove(user);

  useEffect(() => {
    const values = {
      address: {
        address1: user.advisor.company.address?.address1,
        city: user.advisor.company.address?.city,
        country: user.advisor.company.address?.country || US_CODE,
        postcode: user.advisor.company.address?.postcode,
        state: user.advisor.company.address?.state
      },
      brand_primary_color: user.advisor.company?.brand_primary_color,
      brand_secondary_color: user.advisor.company?.brand_secondary_color,
      exceptions_enabled: user.advisor.company.exceptions_enabled,
      exceptions_summary_emails_enabled: user.advisor.company.exceptions_summary_emails_enabled,
      name: user.advisor.company.name,
      phone: user.advisor.company.phone,
      website: user.advisor.company.website
    };
    initializeForm(values);
  }, [JSON.stringify(user?.advisor?.company)]);

  const onSubmit = message =>
    useCallback(
      async values => {
        if (disabledBrandColor) {
          delete values.brand_primary_color;
          delete values.brand_secondary_color;
        }

        const response = await companyProvider.update(values);
        if (response.error) registerError(response);
        else {
          toast.success(message);
          authProvider.getUser();
        }
      },
      [message]
    );

  const handleSubmitBranding = () => {
    const brandPrimaryColor = fields.brand_primary_color.value;
    const brandSecondaryColor = fields.brand_secondary_color.value;

    const brandingValues = {
      brand_primary_color: brandPrimaryColor,
      brand_secondary_color: brandSecondaryColor
    };

    if (brandingValues.brand_primary_color || brandingValues.brand_secondary_color)
      companyProvider.update(brandingValues).then(response => {
        if (response.error) registerError(response);
        else {
          toast.success('Branding colors updated successfully!');
          authProvider.getUser();
        }
      });
    else toast.error('Please provide branding colors before submitting.');
  };

  const onSubmitImage = useCallback(async files => {
    const file = Array.isArray(files) && files.length ? files[0] : null;
    const response = await companyProvider.updateImage(file);
    if (response.error) toast.error('There was a problem saving this file.');
    else toast.success('Profile saved successfully');
  }, []);

  const getSelectedColor = fieldName => color => {
    const { [fieldName]: field } = fields;
    field.onChange(color);
  };

  const onSubmitProfile = onSubmit('Business profile saved successfully');
  const onSubmitEmails = onSubmit('BCC for company email saved successfully');

  const onChangeChoice = field => () => {
    onSubmitProfile({ [field.name]: !field.value });
    field.onChange(!field.value);
  };

  return (
    <div id="company-profile">
      <h1>Business Profile</h1>
      <form onSubmit={handleSubmit(onSubmitProfile)} autoComplete="off">
        <div className="content">
          <div className="row row-name">
            <div className="col">
              <span className="label">Company Name</span>
              <Input type="text" className="form-control" {...fields.name} />
            </div>
            <div className="col">
              <span className="label">Website</span>
              <Input
                type="text"
                placeholder="https://companydomain.com"
                className="form-control"
                {...fields.website}
              />
            </div>
          </div>
          <div className="row row-contact">
            <div className="col">
              <span className="label">Phone</span>
              <PhoneField {...fields.phone} />
            </div>
          </div>
          <AddressForm fields={fields.address} />
        </div>

        <div className="actions">
          <button type="submit" className="btn btn-primary" disabled={errors && !_.isEmpty(errors)}>
            Save
          </button>
        </div>

        <h1>Branding</h1>
        <div className="content">
          <div className="row row-brand-color">
            <div className="row row-logo">
              <div className="col col-logo">
                <span className="label">Company logo</span>
                <Dropzone onDrop={onSubmitImage}>
                  <div className="logo-box">
                    {user.advisor.company.logo ? (
                      <>
                        <img src={user.advisor.company.logo} alt="logo" />
                        <div className="dropzone-area overlay">Change logo</div>
                      </>
                    ) : (
                      <div className="dropzone-area empty">
                        Click to upload
                        <small>Or drag file here</small>
                      </div>
                    )}
                  </div>
                </Dropzone>
                <div className="specs">
                  <span>Recommended width: 900px or higher.</span>
                  <span>File type: jpg, png</span>
                </div>
              </div>
            </div>
            <div className="col-brand-colors">
              <span className="label pl-3">Brand colors</span>
              <div className="col">
                <ColorPicker
                  defaultColor={user.advisor.company.brand_primary_color || DEFAULT_PRIMARY_COLOR}
                  disabled={disabledBrandColor}
                  getSelectedColor={getSelectedColor('brand_primary_color')}
                  helpText="Used for color accents in proposals"
                  inputClassName="form-control"
                  label="Primary color"
                  labelClassName="color-picker-label"
                  error={fields.brand_primary_color.error}
                  touched={fields.brand_primary_color.touched}
                />
                <ColorPicker
                  defaultColor={
                    user.advisor.company.brand_secondary_color || DEFAULT_SECONDARY_COLOR
                  }
                  disabled={disabledBrandColor}
                  getSelectedColor={getSelectedColor('brand_secondary_color')}
                  helpText="Used for section titles in proposals"
                  inputClassName="form-control"
                  label="Secondary color"
                  labelClassName="color-picker-label"
                  error={fields.brand_secondary_color.error}
                  touched={fields.brand_secondary_color.touched}
                />
              </div>
            </div>
            {disabledBrandColor && (
              <div className="request-action__container">
                <RequestAction
                  className="btn-link request-space branding__request-link"
                  name="Upgrade plan"
                  btnText="Upgrade your plan "
                />
                <span> to unlock full branding settings and customize your PDF reports!</span>
              </div>
            )}
          </div>
        </div>

        <div className="actions">
          <button
            type="button"
            onClick={handleSubmitBranding}
            className="btn btn-primary"
            disabled={disabledBrandColor}
          >
            Save
          </button>
        </div>
      </form>

      <CompanyCcEmails
        onSubmitEmails={onSubmitEmails}
        savedEmailAddresses={user ? user.advisor.company.cc_email_addresses : ''}
      />

      <Disclosure />
    </div>
  );
};

CompanyProfile.propTypes = {
  errors: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initializeForm: PropTypes.func.isRequired,
  ...propTypesCheck
};

export default reduxForm({
  form: 'editCompany',
  fields: [
    'address.address1',
    'address.city',
    'address.country',
    'address.postcode',
    'address.state',
    'brand_primary_color',
    'brand_secondary_color',
    'exceptions_enabled',
    'exceptions_summary_emails_enabled',
    'name',
    'phone',
    'website'
  ],
  initialValues: {},
  validate
})(BackendValidation(CompanyProfile));
