/* eslint-disable no-underscore-dangle */
import {
  CHANGE_CLASS_NAME,
  CONNECT_INVITATION_SENT,
  FETCHING,
  FETCHING_FAILURE,
  GET_INDIVIDUAL_PROSPECT,
  GET_PROSPECT_ACCOUNTS,
  GET_PROSPECT_ACCOUNTS_WITH_HOUSEHOLD,
  HOUSEHOLD_LINK,
  HOUSEHOLD_UNLINK,
  PROSPECTS_LIST,
  PROSPECT_CLEAR_LIST_META,
  PROSPECT_CONVERT,
  PROSPECT_CREATE,
  PROSPECT_DELETE,
  PROSPECT_NOTE_LIST,
  PROSPECT_SET_BREAKDOWN_CUSTOM_SECURITIES,
  PROSPECT_UPDATE_SELECTED_IDS
} from 'constants/actions';
import moment from 'moment';
import config from '../config';
import DataProvider from './data';
import ProspectElasticProvider from './prospects.es';

class ProspectProvider extends DataProvider {
  get es() {
    if (!this._es) this._es = new ProspectElasticProvider({ dispatch: this._dispatch });
    return this._es;
  }

  validateProspectStructure = prospect => {
    const { household } = prospect;
    if (household && !household.id) delete prospect.household;
    return prospect;
  };

  list(params, query = {}) {
    return this.provider
      .get(`${config.apiBase}prospects/`, { ...params, ...query })
      .then(({ data, error }) => {
        if (!error) {
          this._params = params;
          this.dispatch(PROSPECTS_LIST, {
            data: {
              data: data.results || [],
              meta: { params, query, count: data.count, totalPages: data.total_pages }
            }
          });
        } else this.dispatch(PROSPECTS_LIST, { data: { data: [], meta: {} } });
        return data;
      });
  }

  clearListMeta() {
    return this.dispatch(PROSPECT_CLEAR_LIST_META);
  }

  changeBtnClass(className) {
    this.dispatch(CHANGE_CLASS_NAME, {
      data: {
        buttonClass: className
      }
    });
  }

  delete(prospectId) {
    this.dispatch(FETCHING);
    return this.provider.delete(`${config.apiBase}prospects/${prospectId}/`).then(data => {
      if (!data.error) {
        this.dispatch(PROSPECT_DELETE);
        return data;
      }
    });
  }

  create(prospect) {
    prospect = this.validateProspectStructure(prospect);
    const data = { ...(prospect || this.props.data.data.edit) };
    delete prospect.accountName;

    const promise = this.provider.post(`${config.apiBase}prospects/`, data).then(data => {
      if (!data.error)
        this.dispatch(PROSPECT_CREATE, {
          data: {
            prospect: data
          }
        });
      return data;
    });

    return promise;
  }

  deleteAccount(accountId) {
    return this.provider.delete(`${config.apiBase}accounts/${accountId}/`);
  }

  convert(data) {
    return this.provider
      .post(`${config.apiBase}prospects/convert_to_investors/`, data)
      .then(data => {
        if (!data.error)
          this.dispatch(PROSPECT_CONVERT, {
            data: {
              prospect: data
            }
          });
        return data;
      });
  }

  updateSelectedId(selectedProspectIds) {
    this.dispatch(PROSPECT_UPDATE_SELECTED_IDS, { data: { selectedProspectIds } });
  }

  get(prospectId) {
    return this.provider.get(`${config.apiBase}prospects/${prospectId}/`).then(data => {
      if (!data.error)
        this.dispatch(GET_INDIVIDUAL_PROSPECT, {
          data: data.data
        });
      return data;
    });
  }

  getAccounts(prospectId, params = {}, action = GET_PROSPECT_ACCOUNTS) {
    return this.provider
      .get(`${config.apiBase}prospects/${prospectId}/accounts/`, params)
      .then(data => {
        if (!data.error)
          this.dispatch(action, {
            accounts: data.data
          });
        return data;
      });
  }

  getAccountsWithHousehold(id) {
    return this.getAccounts(id, { with_household: true }, GET_PROSPECT_ACCOUNTS_WITH_HOUSEHOLD);
  }

  getAccount(accountId) {
    return this.provider.get(`${config.apiBase}accounts/${accountId}/`);
  }

  updateAccount(accountId, data) {
    this.provider.put(`${config.apiBase}accounts/${accountId}/`, data);
  }

  update(prospect) {
    return this.provider.put(`${config.apiBase}prospects/${prospect.id}/`, prospect).then(data => {
      if (!data.error) return this.get(prospect.id);
    });
  }

  moveAccounts(prospectId, accounts) {
    return this.provider.post(`${config.apiBase}prospects/${prospectId}/move_accounts/`, {
      accounts
    });
  }

  addManualRiskScore(prospectId, data) {
    return this.provider.post(`${config.apiBase}prospects/${prospectId}/manual_tolerance_rating/`, {
      data
    });
  }

  sendConnectInvitation(prospectId, { cc, bcc, ...mail }) {
    return this.provider
      .post(`${config.apiBase}prospects/${prospectId}/send_connect_invitation/`, {
        cc: cc.split(','),
        bcc: bcc ? bcc.split(',') : [],
        ...mail
      })
      .then(data => {
        if (!data.error) this.dispatch(CONNECT_INVITATION_SENT);
        return data;
      });
  }

  listNotes(prospect) {
    return this.provider
      .get(`${config.apiBase}prospects/${prospect.id}/notes/`)
      .then(({ data }) => {
        this.dispatch(PROSPECT_NOTE_LIST, { data: data.results, target: prospect.id });
        return data;
      });
  }

  lastNote(prospect) {
    return this.provider.get(`${config.apiBase}prospects/${prospect.id}/notes/last/`);
  }

  addNote(prospect, note) {
    return this.provider.post(`${config.apiBase}prospects/${prospect.id}/notes/`, note).then(() => {
      this.listNotes(prospect);
    });
  }

  editNote(prospect, id, message) {
    return this.provider
      .put(`${config.apiBase}prospects/${prospect.id}/notes/${id}/`, { message })
      .then(() => {
        this.listNotes(prospect);
      });
  }

  removeNote(prospect, id) {
    return this.provider
      .delete(`${config.apiBase}prospects/${prospect.id}/notes/${id}/`)
      .then(() => {
        this.listNotes(prospect);
      });
  }

  linkHousehold(prospectId, data) {
    this.dispatch(FETCHING);
    return this.provider
      .patch(`${config.apiBase}prospects/${prospectId}/link_household/`, data)
      .then(response => {
        if (!response.error) this.dispatch(HOUSEHOLD_LINK);
        else this.dispatch(FETCHING_FAILURE);
        return response;
      });
  }

  unlinkHousehold(prospectId) {
    this.dispatch(FETCHING);
    return this.provider
      .patch(`${config.apiBase}prospects/${prospectId}/unlink_household/`)
      .then(response => {
        if (!response.error) this.dispatch(HOUSEHOLD_UNLINK);
        else this.dispatch(FETCHING_FAILURE);
        return response;
      });
  }

  getReportCoverData(prospectId, params) {
    return this.provider
      .get(`${config.apiBase}prospects/${prospectId}/report/`, params)
      .then(({ data }) => data);
  }

  getManagers(prospectId) {
    return this.provider
      .get(`${config.apiBase}prospects/${prospectId}/managers/`)
      .then(({ data }) => data);
  }

  sendQuestionnaireMail(prospectId, { cc, ...mail }) {
    return this.provider.post(`${config.apiBase}prospects/${prospectId}/send_questionnaire_mail/`, {
      cc: cc ? cc.split(',') : null,
      ...mail
    });
  }

  downloadPortfoliosCSV(prospect, params = {}) {
    return this.provider
      .requestNoJSON(
        `${config.apiBase}prospects/${prospect.id}/download_accounts_csv/`,
        null,
        params,
        true
      )
      .then(response => response.blob())
      .then(blob => {
        const objectURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = objectURL;
        link.download = `StratiFi ${prospect.full_name} Accounts ${moment().format('ll')}.csv`;
        link.click();

        setTimeout(() => {
          window.URL.revokeObjectURL(objectURL);
        }, 250);
      });
  }

  uploadPortfoliosCSV(prospect, files) {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });
    return this.provider.postFormData(
      `${config.apiBase}prospects/${prospect.id}/bulk_upload_accounts/`,
      formData
    );
  }

  setBreakdownCustomSecurities(id, data) {
    this.dispatch(PROSPECT_SET_BREAKDOWN_CUSTOM_SECURITIES, { id, data });
  }
}

export default ProspectProvider;
