import config from 'app/config';
import ModelPortfolioBenchmark from 'components/advisor/model-portfolio-benchmark/pdf';
import AnalysisScores from 'components/advisor/risk-analysis/analysis-scores/pdf';
import PrismRiskAnalysis from 'components/advisor/risk-analysis/prism-risk-analysis/pdf';
import AccountHeader from 'components/advisor/risk-analysis/report/sections/account-header';
import PortfolioOverallAllocation from 'components/advisor/risk-analysis/report/sections/portfolio-overall-allocation';
import RiskFactorsDescription from 'components/advisor/risk-analysis/report/sections/risk-factors-description';
import { PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID } from 'components/advisor/risk-analysis/risk-analysis-target/positions-analysis/constants';
import { SECURITY_TYPE_CONCENTRATION_CHART_ID } from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration/constants';
import SecurityTypeConcentration from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration/pdf';
import TopRiskAttribution from 'components/advisor/risk-analysis/risk-analysis-target/top-risk-attribution/pdf';
import ScenariosScores from 'components/advisor/risk-analysis/scenarios-scores/pdf';
import GeographicExposure from 'components/advisor/risk-analysis/securities/geographic-exposure/pdf';
import { GEOGRAPHIC_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/geographic-exposure/utils';
import InvestmentStyle from 'components/advisor/risk-analysis/securities/investment-style/pdf';
import SectorExposure from 'components/advisor/risk-analysis/securities/sector-exposure/pdf';
import { SECTOR_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/sector-exposure/utils';
import TopHoldings from 'components/advisor/risk-analysis/securities/top-holdings/pdf';
import PrismRating from 'components/utils/prism-rating/pdf';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { getScoreDataByScoreName } from 'providers/model';
import React, { Fragment, useMemo } from 'react';
import { AdvisorBaseCover } from 'reports/base/cover';
import { BaseCoverDataPropTypes } from 'reports/base/cover/types';
import BaseDocument from 'reports/base/document';
import BasePage from 'reports/base/page';
import CustomSection from 'reports/base/sections/custom';
import { LANDSCAPE_ORIENTATION, PORTRAIT_ORIENTATION, SIZE_A4 } from 'reports/constants';
import { combinePortfolios } from 'utils/utils';
import { ACCOUNT_TARGET_TYPE, INVESTOR_TARGET_TYPE } from '../../constants';

const InvestorPrismReport = ({
  accounts,
  benchmarks,
  coverData,
  investor,
  orientation,
  portfolioBreakdownCustomSecurities,
  scenarios,
  size,
  subtitle,
  taxonomies,
  title,
  totalAssets,
  user,
  withCustomBranding
}) => {
  const accountsWithPositions = accounts.filter(account => !_.isEmpty(account.positions));
  const context = useMemo(
    () => ({
      accountsWithPositions,
      benchmarks,
      investor,
      orientation,
      portfolioBreakdownCustomSecurities,
      scenarios,
      taxonomies,
      user,
      withCustomBranding
    }),
    [
      JSON.stringify(accountsWithPositions),
      JSON.stringify(benchmarks),
      JSON.stringify(investor),
      JSON.stringify(portfolioBreakdownCustomSecurities),
      JSON.stringify(scenarios),
      JSON.stringify(taxonomies),
      JSON.stringify(user),
      orientation,
      withCustomBranding
    ]
  );

  const {
    prism: {
      scores: { values: scores }
    }
  } = config;
  const { aggregated_prism_scores: aggrPrism, aggregated_target_scores: aggrTarget } = investor;

  const advisor = { ...investor.advisor, company: user.advisor.company };

  const portfolio = {
    ...combinePortfolios(accountsWithPositions),
    [PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID]: investor[PORTFOLIO_POSITIONS_ANALYSIS_CHART_ID],
    [SECURITY_TYPE_CONCENTRATION_CHART_ID]: investor[SECURITY_TYPE_CONCENTRATION_CHART_ID],
    [SECTOR_EXPOSURE_CHART_ID]: investor[SECTOR_EXPOSURE_CHART_ID],
    [GEOGRAPHIC_EXPOSURE_CHART_ID]: investor[GEOGRAPHIC_EXPOSURE_CHART_ID]
  };

  return (
    <BaseDocument title={title && subtitle ? `${title} for ${subtitle}` : title}>
      {/**
       * It's important to note that the context must be passed at this point to ensure that
       * the entire report has access to it. It's not possible to use react-redux connect on
       * child components that will be rendered by @react-pdf/renderer.
       */}
      <AdvisorContext.Provider value={context}>
        <AdvisorBaseCover
          advisor={advisor}
          data={coverData}
          orientation={orientation}
          size={size}
          subtitle={subtitle}
          title={title}
          withCustomBranding={withCustomBranding}
        />
        <BasePage
          advisor={advisor}
          orientation={orientation}
          size={size}
          withCustomBranding={withCustomBranding}
        >
          <PrismRating
            prismSummary={aggrPrism}
            targetSummary={aggrTarget}
            title="Overall Portfolio Risk"
          />

          <ScenariosScores
            performance={investor.aggregated_scenarios}
            scenarios={scenarios}
            value={totalAssets}
          />

          <ModelPortfolioBenchmark
            benchmarks={benchmarks}
            prismOverall={aggrPrism.overall}
            targetOverall={aggrTarget.overall}
          />

          <PortfolioOverallAllocation
            portfolio={portfolio}
            type={INVESTOR_TARGET_TYPE}
            body={`Total of ${accountsWithPositions.length} accounts`}
            breakSection
          />

          <InvestmentStyle
            breakSection={orientation === LANDSCAPE_ORIENTATION}
            portfolio={portfolio}
          />

          <SectorExposure
            breakSection={orientation === LANDSCAPE_ORIENTATION}
            portfolio={portfolio}
          />

          <TopHoldings breakSection={orientation === LANDSCAPE_ORIENTATION} portfolio={portfolio} />

          <GeographicExposure
            breakSection={orientation === LANDSCAPE_ORIENTATION}
            portfolio={portfolio}
          />

          <SecurityTypeConcentration portfolio={portfolio} type={INVESTOR_TARGET_TYPE} />

          <AnalysisScores prismSummary={aggrPrism} targetSummary={aggrTarget} />

          {accountsWithPositions.map((account, idx) => {
            const riskScores = account.prism_output?.custom?.risk_scores?.[0];
            return (
              <Fragment key={account.id}>
                <AccountHeader account={account} position={idx + 1} />

                {account.prism_score_summary && (
                  <PrismRating
                    key={`prism-${account.id}`}
                    prismSummary={account.prism_score_summary}
                    targetSummary={account.target_score_summary}
                  />
                )}

                {account.prism_score_summary && (
                  <ModelPortfolioBenchmark
                    key={`benchmark-${account.id}`}
                    benchmarks={benchmarks}
                    prismOverall={account.prism_score_summary?.overall}
                    targetOverall={account.target_score_summary?.overall}
                  />
                )}

                {account.prism_score_summary && (
                  <PrismRiskAnalysis
                    portfolio={account}
                    prismSummary={{
                      concentrated: getScoreDataByScoreName(riskScores, scores.CONCENTRATED_SCORE),
                      correlation: getScoreDataByScoreName(riskScores, scores.CORRELATION_SCORE),
                      tail: getScoreDataByScoreName(riskScores, scores.TAIL_SCORE),
                      volatility: getScoreDataByScoreName(riskScores, scores.VOLATILITY_SCORE)
                    }}
                    targetSummary={account?.target_score_summary}
                    type={ACCOUNT_TARGET_TYPE}
                  />
                )}

                {!_.isEmpty(account.positions) && (
                  <PortfolioOverallAllocation
                    portfolio={account}
                    type={ACCOUNT_TARGET_TYPE}
                    title="Holdings"
                    breakSection={!!account.prism_score_summary}
                  />
                )}

                {!_.isEmpty(account.positions) && (
                  <SecurityTypeConcentration portfolio={account} type={ACCOUNT_TARGET_TYPE} />
                )}

                {account.prism_score_summary && (
                  <TopRiskAttribution
                    positions={account.positions}
                    riskyPositions={account.most_risky_positions}
                    type={ACCOUNT_TARGET_TYPE}
                  />
                )}
              </Fragment>
            );
          })}

          <RiskFactorsDescription />

          {!!user?.advisor?.company?.report_disclosure && (
            <CustomSection
              breakSection
              title="Disclosure"
              body={user.advisor.company.report_disclosure}
            />
          )}
        </BasePage>
      </AdvisorContext.Provider>
    </BaseDocument>
  );
};

InvestorPrismReport.propTypes = {
  accounts: PropTypes.array.isRequired,
  benchmarks: PropTypes.array.isRequired,
  coverData: PropTypes.shape(BaseCoverDataPropTypes),
  investor: PropTypes.object.isRequired,
  orientation: PropTypes.string,
  portfolioBreakdownCustomSecurities: PropTypes.array.isRequired,
  scenarios: PropTypes.array.isRequired,
  size: PropTypes.string,
  subtitle: PropTypes.string,
  taxonomies: PropTypes.array,
  title: PropTypes.string,
  totalAssets: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  withCustomBranding: PropTypes.bool
};

InvestorPrismReport.defaultProps = {
  coverData: {},
  orientation: PORTRAIT_ORIENTATION,
  size: SIZE_A4,
  subtitle: '',
  taxonomies: [],
  title: 'PRISM Report',
  withCustomBranding: false
};

export default InvestorPrismReport;
