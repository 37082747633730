import Disclosure from 'components/disclosure';
import DropzoneComponent from 'components/dropzone/dropzone-component';
import SpinnerLoader from 'components/performance-spinner';
import stratifiAiIconWhite from 'assets/img/icons/stratifi-ai-icon-alt-white.svg';
import scanHistoryIcon from 'assets/img/scan-ai/list-scan-history.svg';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import { SCAN_FILE_LIMIT_SIZE } from '../constants';
import ScanFileSteps from './steps';
import ScanLoadingCard from './loading-card';
import './styles.scss';

const MAX_FILES = 1;
const DEFAULT_NOTIFICATION_TIME = 60000; // one minute

const ScanIQ = ({ fields, handleSubmit, tasks }) => {
  const { aiAssistantProvider, routerActions } = useContext(AdvisorContext);

  const [fileCSV, setFileCSV] = useState([]);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [fileId, setfileId] = useState('');
  const [taskID, setTaskId] = useState(null);
  const [disabledScan, setDisabledScan] = useState(false);
  const [extractionFunctions, setExtractionFunctions] = useState({});
  const [extractedFile, setExtractedFile] = useState(false);
  const [updatingFile, setUpdatingFile] = useState(false);
  const [isFileSizeExceeded, setIsFileSizeExceeded] = useState(false);

  const onRemoveFile = file => () => {
    const currentFiles = [...fileCSV];
    currentFiles.splice(currentFiles.indexOf(file), 1);
    setFileCSV(currentFiles);
  };

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length <= 0) return toast.error('Please add one file in the dropzone.');

      const file = acceptedFiles[0];

      // Check if the file size exceeds 10 MB
      if (file.size > 10 * 1024 * 1024) setIsFileSizeExceeded(true);
      else setIsFileSizeExceeded(false);

      fields.file.onChange({ ...acceptedFiles });
      return setFileCSV([...acceptedFiles]);
    },
    [fileCSV]
  );

  const handleScan = () => {
    setLoadingRequest(true);
    setDisabledScan(true);

    const formData = new FormData();
    formData.append('file', fileCSV[0]);
    aiAssistantProvider.scanFile(formData).then(response => {
      if (response.error || !response.data) {
        if (response.error?.errors?.file?.message === 'File has too many pages')
          toast.error(
            `The file has too many mages. The maximum allowed size is ${response.error.errors.file.max_page_count} pages.`
          );
        else if (response.error?.errors?.file?.message === 'File is too large')
          toast.error(
            `The file is too large. The maximum allowed size is ${response.error.errors.file.max_size}MB.`
          );
        else toast.error('Something went wrong uploading the file. Try again.');
        setFileCSV([]);
        setDisabledScan(false);
        return setLoadingRequest(false);
      }
      setUpdatingFile(true);
      setfileId(response.data.id);
      setTaskId(response.data.task_id);
      setExtractionFunctions(response.data.extraction_functions);
      setDisabledScan(false);
      toast.success(
        <div className="loading-action-toast">
          <SpinnerLoader spinnerLoading />
          <span>
            This may take a few minutes. You can continue navigating, and we will notify you when
            it&apos;s ready.
          </span>
        </div>,
        {
          toastId: response.data.task_id,
          closeButton: true,
          autoClose: DEFAULT_NOTIFICATION_TIME,
          closeOnClick: false
        }
      );
      routerActions.push(`/advisor/ai-assistant/scan-iq/${response.data.id}`);
      return setFileCSV([]);
    });
  };

  useEffect(() => {
    if (
      taskID &&
      tasks[taskID] &&
      (tasks[taskID].scan_iq_extracted || tasks[taskID].scan_iq_update)
    )
      setExtractedFile(true);

    if (taskID && tasks[taskID] && tasks[taskID]?.scan_iq_completed) {
      setLoadingRequest(false);
      return routerActions.push(`/advisor/ai-assistant/scan-iq/${fileId}`);
    }

    if (taskID && tasks[taskID] && tasks[taskID]?.scan_iq_failed) {
      toast.error('Something went wrong uploading the file. Try again.');
      setFileCSV([]);
      return setLoadingRequest(false);
    }
  }, [tasks]);

  return (
    <form id="scan-iq" autoComplete="off" onSubmit={handleSubmit(handleScan)}>
      <div className="btn-header__container">
        <button
          type="button"
          className="btn btn-transparent btn-go-back-tools"
          onClick={() => routerActions.push(`/advisor/ai-assistant/`)}
        >
          <i className="icon-up icon-arrow-rotate-Left" /> Back
        </button>
        <button
          className="btn btn-outline-secondary btn-redirect-scan-iq"
          type="button"
          onClick={() => routerActions.push(`/advisor/ai-assistant/scan-iq/history`)}
        >
          <img src={scanHistoryIcon} alt="List" className="scan-iq__list-icon" /> View Scan History
        </button>
      </div>
      <div className="card scan-iq-wrapper">
        <div className="update-file-container">
          <h2 className="scan-file__title">Scan IQ</h2>
          <h3>Select the files you would like to scan</h3>
          <div className="security-returns-btn-container space-btn">
            <div>
              {loadingRequest ? (
                <ScanLoadingCard
                  updatingFile={updatingFile}
                  tasks={tasks[taskID]}
                  extractedFile={extractedFile}
                  extractedFunctions={extractionFunctions}
                />
              ) : (
                <DropzoneComponent
                  files={fileCSV}
                  setFiles={setFileCSV}
                  fields={fields}
                  maxFiles={MAX_FILES}
                  uploadMessage="Click to Upload"
                  fileFormatMessage={SCAN_FILE_LIMIT_SIZE}
                  onDrop={onDrop}
                  isFileSizeExceeded={isFileSizeExceeded}
                />
              )}
              {!_.isEmpty(fileCSV) && !loadingRequest && (
                <div className="ai-assistant-scan__btn-scan-file-container">
                  <button
                    type="submit"
                    className="btn btn-primary btn-scan-iq"
                    disabled={_.isEmpty(fileCSV) || disabledScan || isFileSizeExceeded}
                  >
                    <img src={stratifiAiIconWhite} alt="ai scan icon" />
                    Scan
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-scan-iq"
                    onClick={onRemoveFile(fileCSV)}
                    disabled={_.isEmpty(fileCSV) || disabledScan}
                  >
                    Upload new file
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <ScanFileSteps />
      </div>
      <Disclosure />
    </form>
  );
};

ScanIQ.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  tasks: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  tasks: state.aiAssistant.tasks,

  fields: ['file'],
  initialValues: {
    file: ''
  }
});

const ScanIQWithForm = reduxForm({
  form: 'editSecurity',
  enableReinitialize: true
})(ScanIQ);

export default connect(mapStateToProps)(ScanIQWithForm);
