import React, { useEffect } from 'react';
import { useLottie } from 'lottie-react';
import scannerAnimation from 'assets/animations/scan-animation-3.json';

const ScanAnimation = () => {
  const options = {
    animationData: scannerAnimation,
    loop: true
  };

  const { View, animation } = useLottie(options);

  useEffect(() => {
    if (animation) animation.setSpeed(0.5); // Slow down the animation speed (0.5x)
  }, [animation]);

  return <div className="scan-animation">{View}</div>;
};

export default ScanAnimation;
