import cn from 'classnames';
import { MODEL_COPY_SUFFIX } from 'components/advisor/models/constants';
import {
  modelPortfolioCalculatingRiskToast,
  saveAsNewModelPortfolio
} from 'components/advisor/models/utils';
import { MODEL_PROPOSAL_TYPE } from 'components/advisor/proposal/constants';
import InlineEditPortfolio from 'components/advisor/proposal/inline-edit-portfolio';
import ProposalReportViewer from 'components/advisor/proposal/report/viewer';
import { FormGroup, VerboseErrorInput } from 'components/form';
import ReactDateRangePicker from 'components/form/date-range-picker';
import ManagementFeeField from 'components/form/management-fee-field';
import { BackendValidation } from 'hocs/backend-validation';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ModelProvider from 'providers/model';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import { DateValidator, validation } from 'utils/form';
import { trackAmplitudeEvent } from 'utils/tracking';
import { setPrecision } from 'utils/utils';
import AsyncPortfolioSelector from '../../../portfolio-selector/async';
import SinglePortfolioSelector from '../../../portfolio-selector/single';
import '../../common/form/styles.scss';
import { PROPOSAL_SELECT_STYLES } from '../../common/form/utils';
import {
  MAX_START_DATE,
  MIN_START_DATE,
  handleStartingValueChange,
  handleYearlyWithdrawalAmountChange,
  handleYearlyWithdrawalRateChange,
  normalizeProposalPercentageValues
} from '../../utils';

const validate = values => {
  const errors = {};

  errors.template = errors.template || validation.required(values.template);

  errors.targetManagementFee =
    errors.targetManagementFee ||
    validation.required(values.targetManagementFee) ||
    validation.isFloatPercentage(values.targetManagementFee);

  errors.recommendedManagementFee =
    errors.recommendedManagementFee ||
    validation.required(values.recommendedManagementFee) ||
    validation.isFloatPercentage(values.recommendedManagementFee);

  errors.benchmarkManagementFee =
    errors.benchmarkManagementFee ||
    validation.required(values.benchmarkManagementFee) ||
    validation.isFloatPercentage(values.benchmarkManagementFee);

  errors.yearlyWithdrawalRate =
    errors.yearlyWithdrawalRate ||
    validation.required(values.yearlyWithdrawalRate) ||
    validation.isFloatPercentage(values.yearlyWithdrawalRate);

  errors.period =
    errors.period ||
    new DateValidator(values.period?.[0])
      .required()
      .required()
      .valid()
      .min(MIN_START_DATE)
      .max(MAX_START_DATE)
      .monthDifference(values.period?.[1], 9)?.error;

  errors.yearlyWithdrawalAmount =
    errors.yearlyWithdrawalAmount || validation.floatPositive(values.yearlyWithdrawalAmount);

  errors.startingValue =
    errors.startingValue ||
    validation.required(values.startingValue) ||
    validation.floatPositive(values.startingValue) ||
    validation.nonZero(values.startingValue);

  return errors;
};

export class ProposalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      benchmarkName: '',
      benchmarkType: null,
      positions: [],
      recommendedName: '',
      recommendedType: 'model_portfolio',
      recommendedValue: 100,
      saving: false,
      unsavedDraft: false
    };
  }

  handleRecommendedChange = portfolios => {
    const { fields } = this.props;
    const recommendedValue = setPrecision(
      portfolios.reduce((acum, b) => acum + b.weight, 0),
      2
    );
    const recommendedName = portfolios.map(r => r.label).join(', ');
    this.setState({ recommendedName, recommendedValue });
    fields.recommended.onChange(portfolios);
  };

  handleRecommendedTypeChange = ({ value: recommendedType }) => {
    const { onRecommendedTypeChange } = this.props;
    this.setState({ recommendedType });
    if (onRecommendedTypeChange) onRecommendedTypeChange(recommendedType);
    this.handleRecommendedChange([]);
  };

  handleBenchmarkChange = portfolios => {
    const { fields } = this.props;
    const benchmarkName = portfolios.map(r => r.label).join(', ');
    const benchmarkType = portfolios[0].type;
    this.setState({ benchmarkName, benchmarkType });
    fields.benchmark.onChange(portfolios);
  };

  onSavePortfolio = () => {
    const { fields } = this.props;
    const { positions } = this.state;
    const {
      modelProvider,
      scope: { display_name: displayName, id, name, type }
    } = this.props;
    const modelName = displayName || name;

    trackAmplitudeEvent('model_analysis.saved', {
      id,
      model: modelName,
      with_recommended: !!fields.recommended.active,
      with_benchmark: !!fields.benchmark.active
    });

    const savePortfolioNotification = modelPortfolioCalculatingRiskToast('Saving Changes');

    const onSavePortfolioError = message => {
      toast.update(savePortfolioNotification, {
        type: toast.TYPE.ERROR,
        render: message || 'Something went wrong while saving changes',
        closeOnClick: true
      });
    };

    this.setState({ saving: true });
    modelProvider
      .update({ id, name: modelName, type, positions: positions.filter(x => x.value > 0) })
      .then(({ error }) => {
        if (error) onSavePortfolioError(error.message);
        else
          modelProvider
            .updatePrism(id)
            .then(() => {
              Promise.all([modelProvider.listAll(), modelProvider.get(id)])
                .then(() => {
                  toast.update(savePortfolioNotification, {
                    autoClose: 5000,
                    render: (
                      <div>
                        <span role="img" aria-label="tada">
                          🎉
                        </span>{' '}
                        The changes have been saved to {modelName}
                      </div>
                    ),
                    type: toast.TYPE.SUCCESS
                  });
                  this.setState({ unsavedDraft: false });
                })
                .finally(() => {
                  this.setState({ saving: false });
                });
            })
            .catch(onSavePortfolioError);
      })
      .catch(onSavePortfolioError);
  };

  saveDraftPortfolio = () => {
    const { positions } = this.state;
    const {
      modelProvider,
      scope: { display_name: displayName, id, name }
    } = this.props;
    const modelName = displayName || name;

    return new Promise(resolve => {
      modelProvider
        .createDraft({ id, name: modelName, positions: positions.filter(x => x.value > 0) })
        .then(({ data }) => {
          modelProvider.updatePrism(data.id).then(() => resolve(data.id));
        });
    });
  };

  onSubmit = async values => {
    const {
      onGenerate,
      setLoading,
      scope: { display_name: displayName, id, name }
    } = this.props;

    const { unsavedDraft } = this.state;

    const modelName = displayName || name;

    normalizeProposalPercentageValues(values);

    if (unsavedDraft) {
      setLoading();
      const draftId = await this.saveDraftPortfolio();
      if (draftId) values.draftId = draftId;
    }

    trackAmplitudeEvent('model_analysis.generated', {
      id,
      model: modelName,
      with_recommended: !!values.recommended,
      with_benchmark: !!values.benchmark
    });
    return onGenerate(values);
  };

  handleMakeModelCopy = async () => {
    const { positions } = this.state;
    const { portfolio: sourcePortfolio, fields } = this.props;
    const { modelProvider, routerActions } = this.context;

    const portfolio = {
      name: `${sourcePortfolio.name} ${MODEL_COPY_SUFFIX}`,
      origin: sourcePortfolio.id,
      positions: positions.filter(position => position.value > 0),
      type: sourcePortfolio.type
    };

    trackAmplitudeEvent('model_analysis.duplicated', {
      id: sourcePortfolio.id,
      model: portfolio.name,
      with_recommended: !!fields.recommended.active,
      with_benchmark: !!fields.benchmark.active
    });

    return saveAsNewModelPortfolio(portfolio, modelProvider, routerActions);
  };

  saveButton = disableAction => {
    const { portfolio, reportBuilderEnabled } = this.props;
    const { user, authProvider } = this.context;
    const userIsOwner = portfolio?.advisor === user?.advisor?.id;
    const userIsComplienceOrAbove = authProvider.hasCompliancePermissionsOrAbove(user);

    return (
      reportBuilderEnabled &&
      (userIsComplienceOrAbove || userIsOwner) && (
        <button
          type="button"
          disabled={disableAction}
          onClick={this.onSavePortfolio}
          className="btn btn-secondary"
        >
          Save Current Model
        </button>
      )
    );
  };

  render() {
    const {
      allowPrint,
      benchmarkSuggestions,
      error,
      fields: {
        benchmarkLabel,
        benchmarkManagementFee,
        benchmark,
        recommendedLabel,
        recommendedManagementFee,
        recommended,
        period,
        startingValue,
        targetLabel,
        targetManagementFee,
        template,
        yearlyWithdrawalAmount,
        yearlyWithdrawalRate
      },
      handleSubmit,
      invalid,
      loading,
      onLoadAsyncRecommendedSuggestions,
      portfolio,
      submitting,
      scope,
      scope: { name: displayName },
      recommendedSuggestions,
      recommendedTypeSuggestions,
      reportBuilderEnabled,
      templateOptions,
      templateSuggestions
    } = this.props;

    const {
      benchmarkName,
      benchmarkType,
      positions,
      recommendedName,
      recommendedType,
      recommendedValue,
      saving,
      unsavedDraft
    } = this.state;

    const totalPositions = reportBuilderEnabled
      ? setPrecision(
          positions.reduce((value, p) => value + p.value, 0),
          2
        )
      : 100;

    let modelBenchmark = 0;
    let modelRecommended = 0;

    // only one recommended/benchmark for models
    modelRecommended =
      recommended.value && !_.isEmpty(recommended.value) ? Number(recommended.value[0].value) : 0;

    modelBenchmark =
      benchmark.value && !_.isEmpty(benchmark.value) ? Number(benchmark.value[0].value) : 0; // Number(benchmarkId.value)

    const isInvalidTemplate = templateOptions.find(el => !el.approved && el.id === template.value);
    const somePositionsAreInvalid = positions.some(p => p.value <= 0);
    const disableActions =
      submitting || saving || totalPositions !== 100 || somePositionsAreInvalid || !unsavedDraft;

    return (
      <form
        onSubmit={handleSubmit(this.onSubmit)}
        autoComplete="off"
        className={cn('proposal-form', { 'less-opacity': loading })}
      >
        <div className="steps">
          <div className="step form-setup-values">
            <div className="step__container step__container--inline">
              <div className="step__model-name">
                <label htmlFor="target">Current target name</label>
                <VerboseErrorInput
                  className="form-control"
                  disabled
                  name="target"
                  type="text"
                  value={portfolio.name}
                />
              </div>

              <ManagementFeeField field={targetManagementFee} />

              <FormGroup>
                <label htmlFor="targe-label">Customize label (optional)</label>
                <VerboseErrorInput
                  name="targe-label"
                  type="text"
                  {...targetLabel}
                  placeholder="Target"
                  className="form-control"
                />
              </FormGroup>
            </div>
          </div>

          <div className="step">
            <div className="step__container step__container--inline">
              <div className="recommended">
                <label htmlFor="recommended">
                  Search a model or an account to compare (optional)
                </label>
                <div className="recommended-portfolio">
                  <Select
                    className="type-portfolio-select"
                    isSearchable
                    onChange={this.handleRecommendedTypeChange}
                    options={recommendedTypeSuggestions}
                    styles={PROPOSAL_SELECT_STYLES}
                    value={
                      recommendedType
                        ? recommendedTypeSuggestions.find(t => t.value === recommendedType)
                        : null
                    }
                  />
                  {recommendedType && recommendedType.endsWith('account') ? (
                    <AsyncPortfolioSelector
                      defaultValue={recommended.initialValue}
                      key={`async-${recommendedType}`}
                      loadSuggestions={onLoadAsyncRecommendedSuggestions}
                      onChange={this.handleRecommendedChange}
                      suggestions={recommendedSuggestions}
                      value={modelRecommended}
                    />
                  ) : (
                    <SinglePortfolioSelector
                      defaultValue={recommended.initialValue}
                      onChange={this.handleRecommendedChange}
                      suggestions={recommendedSuggestions}
                    />
                  )}
                </div>
              </div>

              <ManagementFeeField field={recommendedManagementFee} />

              <div className="recommended-label">
                <label htmlFor="recommended-label">Customize label (optional)</label>
                <VerboseErrorInput
                  name="model-label"
                  type="text"
                  {...recommendedLabel}
                  placeholder="Model"
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <div className="step">
            <div className="step__container step__container--inline" data-compare-analyze="true">
              <div className="benchmark">
                <label htmlFor="benchmark">Select a benchmark (optional)</label>
                <SinglePortfolioSelector
                  placeholder="Select a benchmark"
                  suggestions={benchmarkSuggestions}
                  defaultValue={benchmark.initialValue}
                  onChange={this.handleBenchmarkChange}
                />
              </div>

              <ManagementFeeField field={benchmarkManagementFee} />

              <div className="benchmark-label">
                <label htmlFor="benchmark-label">Customize label (optional)</label>
                <VerboseErrorInput
                  name="benchmark-label"
                  type="text"
                  {...benchmarkLabel}
                  placeholder="Benchmark"
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <div className="step">
            <div className="step__last-container">
              <div className="template">
                <label htmlFor="template">Template *</label>
                <Select
                  className="options"
                  onChange={template.onChange}
                  options={templateSuggestions}
                  styles={PROPOSAL_SELECT_STYLES}
                  value={templateSuggestions.find(t => t.value === template.value)}
                />
                {!!(template.touched && template.error) && (
                  <div className="text-danger" style={{ marginTop: 10, fontSize: 12 }}>
                    {template.error}
                  </div>
                )}
              </div>
              <FormGroup {...startingValue} className="starting-value form-group">
                <VerboseErrorInput
                  {...startingValue}
                  className="form-control"
                  fieldsetClassName="form-group-money"
                  label="Starting value *"
                  placeholder="Starting value"
                  type="text"
                  onChange={handleStartingValueChange(
                    startingValue,
                    yearlyWithdrawalAmount,
                    yearlyWithdrawalRate
                  )}
                />
              </FormGroup>

              <FormGroup {...period} className="start form-group">
                <VerboseErrorInput {...period} label="Period *">
                  <ReactDateRangePicker
                    className="form-control"
                    onChange={period.onChange}
                    value={period.value}
                    error={period.error}
                    feedbackMessage="Minimum 9 months required"
                  />
                </VerboseErrorInput>
              </FormGroup>
              <FormGroup {...yearlyWithdrawalRate} className="yearly-withdrawal form-group">
                <label htmlFor="yearly-withdrawal">Yearly withdrawal *</label>
                <div>
                  <VerboseErrorInput
                    {...yearlyWithdrawalRate}
                    className="form-control yearly-withdrawal__percent"
                    fieldsetClassName="form-group-percentage"
                    type="text"
                    onChange={handleYearlyWithdrawalRateChange(
                      startingValue,
                      yearlyWithdrawalAmount,
                      yearlyWithdrawalRate
                    )}
                  />
                  <VerboseErrorInput
                    {...yearlyWithdrawalAmount}
                    className="form-control yearly-withdrawal__amount"
                    fieldsetClassName="form-group-money"
                    type="text"
                    onChange={handleYearlyWithdrawalAmountChange(
                      startingValue,
                      yearlyWithdrawalAmount,
                      yearlyWithdrawalRate
                    )}
                  />
                </div>
              </FormGroup>
            </div>
          </div>
        </div>

        {error && <p className="text-danger">{error}</p>}

        {reportBuilderEnabled && (
          <InlineEditPortfolio
            model={scope}
            benchmarkId={modelBenchmark}
            benchmarkType={benchmarkType}
            targetLabel={targetLabel.value}
            benchmarkName={benchmarkLabel.value || benchmarkName}
            recommendedId={modelRecommended}
            recommendedType={recommendedType}
            recommendedName={recommendedLabel.value || recommendedName || displayName}
            setPositions={(positions, unsavedChanges) => {
              this.setState({ positions, unsavedDraft: unsavedChanges });
            }}
          />
        )}

        <div className="form-actions form-actions--model">
          <button
            type="submit"
            disabled={
              submitting || invalid || saving || totalPositions !== 100 || recommendedValue !== 100
            }
            className="btn btn-primary"
          >
            Run Analysis
          </button>
          {this.saveButton(disableActions)}
          <button
            type="button"
            style={{ marginRight: 2 }}
            disabled={disableActions}
            onClick={this.handleMakeModelCopy}
            className="btn btn-outline-secondary"
          >
            Save as New Model
          </button>
          {allowPrint && (
            <ProposalReportViewer
              isInvalidTemplate={isInvalidTemplate}
              subtitle={scope.name}
              proposalType={MODEL_PROPOSAL_TYPE}
            />
          )}
        </div>
      </form>
    );
  }
}

ProposalForm.defaultProps = {
  allowPrint: false,
  benchmarkSuggestions: [],
  error: null,
  onGenerate: () => {},
  portfolio: {},
  recommendedSuggestions: [],
  setLoading: () => {},
  templateSuggestions: []
};

ProposalForm.propTypes = {
  allowPrint: PropTypes.bool,
  benchmarkSuggestions: PropTypes.array,
  error: PropTypes.string,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  modelProvider: PropTypes.object.isRequired,
  onGenerate: PropTypes.func,
  onLoadAsyncRecommendedSuggestions: PropTypes.func.isRequired,
  onRecommendedTypeChange: PropTypes.func.isRequired,
  portfolio: PropTypes.object,
  recommendedSuggestions: PropTypes.array,
  recommendedTypeSuggestions: PropTypes.arrayOf(PropTypes.object).isRequired,
  reportBuilderEnabled: PropTypes.bool.isRequired,
  scope: PropTypes.object.isRequired,
  setLoading: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  templateOptions: PropTypes.array.isRequired,
  templateSuggestions: PropTypes.array
};

ProposalForm.contextTypes = {
  user: PropTypes.object.isRequired,
  authProvider: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired,
  routerActions: PropTypes.object.isRequired
};

const ProposalFormWithReduxForm = reduxForm({
  form: 'generateProposal',
  touchOnChange: true,
  fields: [
    'benchmarkLabel',
    'benchmarkManagementFee',
    'benchmark',
    'recommendedLabel',
    'recommendedManagementFee',
    'recommended',
    'period',
    'startingValue',
    'targetLabel',
    'targetManagementFee',
    'template',
    'yearlyWithdrawalAmount',
    'yearlyWithdrawalRate'
  ],
  validate,
  overwriteOnInitialValuesChange: false
})(BackendValidation(ProposalForm));

export default connect(
  state => ({
    portfolio: state.models.view
  }),
  dispatch => ({
    modelProvider: new ModelProvider({ dispatch })
  })
)(ProposalFormWithReduxForm);
