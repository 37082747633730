import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import { formatPercentage, withCurrencyFormat } from 'utils/utils';

const DEFAULT_PORTFOLIO_PERCENTAGE = 1;

const TotalRow = ({
  hiddenVal,
  notAvailableRowLabel,
  styles,
  totalRowLabel,
  totalValue,
  totalValuePercentage
}) => {
  const isPortfolioRelative = Math.floor(totalValuePercentage) !== DEFAULT_PORTFOLIO_PERCENTAGE;
  return (
    <>
      <View style={styles.table} wrap={false}>
        <View style={[styles.row, styles.rowTotal]}>
          <View style={styles.cell}>
            <Text>{totalRowLabel}</Text>
          </View>
          <View style={styles.cell}>
            {hiddenVal || (
              <Text style={styles.cellText}>
                {withCurrencyFormat(
                  isPortfolioRelative ? totalValue * totalValuePercentage : totalValue,
                  'standard',
                  0
                )}
              </Text>
            )}
          </View>
          <View style={[styles.cell, { marginRight: 0 }]}>
            <Text style={styles.cellText}>{formatPercentage(totalValuePercentage, 100, 2)}</Text>
          </View>
        </View>
      </View>

      {isPortfolioRelative && (
        <View style={styles.table} wrap={false}>
          <View style={[styles.row, styles.rowTotal]}>
            <View style={styles.cell}>
              <Text>{notAvailableRowLabel}</Text>
            </View>
            <View style={styles.cell}>
              {hiddenVal || (
                <Text style={styles.cellText}>
                  {withCurrencyFormat(totalValue * (1 - totalValuePercentage), 'standard', 0)}
                </Text>
              )}
            </View>
            <View style={[styles.cell, { marginRight: 0 }]}>
              <Text style={styles.cellText}>
                {formatPercentage(1 - totalValuePercentage, 100, 2)}
              </Text>
            </View>
          </View>
        </View>
      )}
    </>
  );
};

TotalRow.propTypes = {
  hiddenVal: PropTypes.bool,
  notAvailableRowLabel: PropTypes.string,
  styles: PropTypes.object.isRequired,
  totalRowLabel: PropTypes.string.isRequired,
  totalValue: PropTypes.number.isRequired,
  totalValuePercentage: PropTypes.number
};

TotalRow.defaultProps = {
  hiddenVal: false,
  notAvailableRowLabel: 'Not Reported / Not Available',
  totalValuePercentage: DEFAULT_PORTFOLIO_PERCENTAGE
};

export default TotalRow;
