import SimpleSpinner from 'components/utils/simple-spinner';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ScanAnimation from './scan-animation';
import './styles.scss';

const ScanLoadingCard = ({ updatingFile, extractedFile, tasks, extractedFunctions }) => {
  const [sortedFunctions, setSortedFunctions] = useState({});
  useEffect(() => {
    if (!_.isEmpty(extractedFunctions)) {
      const sortedExtractedFunctions = extractedFunctions.sort((a, b) => a.order - b.order);
      setSortedFunctions(sortedExtractedFunctions);
    }
  }, [JSON.stringify(extractedFunctions)]);

  return (
    <div className="scan-loading-card loading-card-message__container">
      <div className="loading-card__container">
        {!updatingFile && (
          <div className="loading-item">
            <SimpleSpinner size="25" />
            <h5>Uploading file</h5>
          </div>
        )}

        {updatingFile && !extractedFile && (
          <div className="loading-item">
            <ScanAnimation />
            <h5>Scanning text</h5>
          </div>
        )}

        {updatingFile && extractedFile && !_.isEmpty(sortedFunctions) && (
          <div className="extract-file__container">
            <div>
              {sortedFunctions.map(func => (
                <div key={func.name} className="loading-item  loading-item__extracted-item ">
                  {tasks?.[func.name] === 'complete' ? (
                    <i className="icon-checkmark-circle ai-assistant--icon-checkmark" />
                  ) : (
                    <div>
                      <SimpleSpinner size="25" />
                    </div>
                  )}
                  <h5>Extracting {func.name} </h5>
                </div>
              ))}
            </div>
          </div>
        )}
        <span>It might take up to 1 min</span>
      </div>
    </div>
  );
};

ScanLoadingCard.propTypes = {
  updatingFile: PropTypes.bool.isRequired,
  extractedFile: PropTypes.bool.isRequired,
  extractedFunctions: PropTypes.object.isRequired,
  tasks: PropTypes.object.isRequired
};

export default ScanLoadingCard;
