import SectorExposureDistribution from 'components/advisor/proposal/securities/sector-exposure-distribution';
import { SECTOR_EXPOSURE_CHART_ID } from 'components/advisor/risk-analysis/securities/sector-exposure/utils';
import EditBox from 'components/advisor/utils/edit-box';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useCallback } from 'react';
import { IPSPropTypes } from '../types';

const IPSSectorExposure = ({ ipsCharts, ips: { proposal }, ...rest }) => {
  const { ipsProvider } = useContext(AdvisorContext);

  const breakdownCustomSecurities = rest.customActions?.breakdownCustomSecurities?.value ?? true;
  const distributionProps = { target: proposal.target, targetName: proposal.target_label };
  const hasRecommended = !!proposal.recommended;

  if (hasRecommended) {
    distributionProps.recommendedName = proposal.recommended_label;
    distributionProps.recommended = proposal.recommended;
  }

  const setCollapsedSectors = data => {
    ipsProvider.setCollapsedSectors(data);
  };

  const onChartReady = useCallback(
    (id, data) => {
      ipsProvider.saveChartImage({ [id]: data });
    },
    [ipsCharts[SECTOR_EXPOSURE_CHART_ID]]
  );

  return (
    <EditBox {...rest}>
      <SectorExposureDistribution
        breakdownCustomSecurities={breakdownCustomSecurities}
        setCollapsedSectors={setCollapsedSectors}
        onChartReady={onChartReady}
        {...distributionProps}
      />
    </EditBox>
  );
};

IPSSectorExposure.propTypes = {
  ipsCharts: PropTypes.object.isRequired,
  ips: PropTypes.shape(IPSPropTypes).isRequired
};

export default IPSSectorExposure;
