/* eslint-disable no-underscore-dangle */
import { Image, Text, View } from '@react-pdf/renderer';
import {
  ACCOUNT_TARGET_TYPE,
  INVESTOR_TARGET_TYPE
} from 'components/advisor/risk-analysis/constants';
import { GEOGRAPHIC_EXPOSURE_TYPE } from 'components/form/breakdown-custom-securities-toggle-title/constants';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { Fragment, useContext } from 'react';
import BaseSection from 'reports/base/sections/base';
import { getTaxonomyLevelIterator } from 'utils/utils';
import Row from '../../common/pdf/row';
import styles from '../../common/pdf/styles';
import TotalRow from '../../common/pdf/total-row';
import {
  SECURITY_REGIONS,
  getSecurityDetails,
  hasSecurityDetails,
  processSecurityDetails
} from '../../common/utils';
import { GEOGRAPHIC_EXPOSURE_CHART_ID, PDF_IMAGE_STYLES } from '../utils';

const GeographicExposure = ({
  body,
  breakSection,
  headingLabels,
  hiddenVal,
  portfolio,
  title,
  totalRowLabel,
  type
}) => {
  const {
    portfolioBreakdownCustomSecurities,
    user: {
      advisor: {
        company: {
          expand_asset_details: expandAssetDetails,
          region_exposure_enabled: regionExposureEnabled
        }
      }
    }
  } = useContext(AdvisorContext);

  const breakdownCustomSecurities =
    portfolioBreakdownCustomSecurities.includes(GEOGRAPHIC_EXPOSURE_TYPE);

  if (
    !regionExposureEnabled ||
    !hasSecurityDetails(portfolio, SECURITY_REGIONS, breakdownCustomSecurities)
  )
    return null;

  const totalAssets = portfolio.value ?? 0;
  const securityRegions = getSecurityDetails(
    portfolio,
    SECURITY_REGIONS,
    totalAssets,
    breakdownCustomSecurities
  );

  const { data } = processSecurityDetails(
    securityRegions,
    SECURITY_REGIONS,
    totalAssets,
    null,
    true
  );

  const chartId =
    type === ACCOUNT_TARGET_TYPE
      ? portfolio[`${GEOGRAPHIC_EXPOSURE_CHART_ID}-${portfolio.id}`]
      : portfolio[GEOGRAPHIC_EXPOSURE_CHART_ID];
  const rowProps = { hiddenVal, total: totalAssets };

  return (
    <BaseSection title={title} body={body} breakSection={breakSection}>
      <View>
        <Image src={chartId} style={PDF_IMAGE_STYLES} />
      </View>
      <View style={[styles.table, { marginTop: 0, maxHeight: 30 }]} wrap={false}>
        <View style={[styles.row, styles.rowTotal]}>
          <View style={styles.cell}>
            <Text>{headingLabels.name}</Text>
          </View>
          <View style={styles.cell}>
            {hiddenVal || <Text style={styles.cellText}>{headingLabels.value}</Text>}
          </View>
          <View style={[styles.cell, { marginRight: 0 }]}>
            <Text style={styles.cellText}>{headingLabels.percent}</Text>
          </View>
        </View>
      </View>

      <View style={[styles.table, { marginTop: 0 }]}>
        {getTaxonomyLevelIterator(data.summary).map(([l1Key, l1], l1Idx) => (
          <Fragment key={`l1-${l1.__id ?? l1Idx}`}>
            <Row depth={1} label={l1Key} level={l1} color={l1.__color} {...rowProps} />

            {expandAssetDetails &&
              getTaxonomyLevelIterator(l1).map(([l2Key, l2], l2Idx) => (
                <Fragment key={`l2-${l2.__id ?? `${l1Idx}-${l2Idx}`}`}>
                  <Row depth={2} label={l2Key} level={l2} color={l1.__color} {...rowProps} />
                </Fragment>
              ))}
          </Fragment>
        ))}
      </View>

      <TotalRow
        hiddenVal={hiddenVal}
        styles={styles}
        totalRowLabel={totalRowLabel}
        totalValue={data.summary.__value}
        totalValuePercentage={data.summary.__value_percentage ?? 1}
      />
    </BaseSection>
  );
};

GeographicExposure.propTypes = {
  body: PropTypes.string,
  breakSection: PropTypes.bool,
  headingLabels: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    percent: PropTypes.string.isRequired
  }),
  hiddenVal: PropTypes.bool,
  portfolio: PropTypes.array.isRequired,
  title: PropTypes.string,
  totalRowLabel: PropTypes.string,
  type: PropTypes.string
};

GeographicExposure.defaultProps = {
  body: '',
  breakSection: false,
  headingLabels: { name: '', value: 'Value', percent: '% of Portfolio' },
  hiddenVal: false,
  title: 'Geographic Exposure',
  totalRowLabel: 'Total Regions',
  type: INVESTOR_TARGET_TYPE
};

export default GeographicExposure;
